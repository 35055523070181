import React from 'react';
import { Popup, Icon } from 'semantic-ui-react';
import { FlexboxGrid as Div, Button } from 'baroo';
import Shortlists from '../components/Shortlists';
import {
  ImageStyled,
  PlayerName,
  ClubName,
  HeaderStyled,
  LinkStyled,
  Actions
} from '../assets/themes/styles/playerProfile/ProfileHeader';

const showShareButton = false;
const ProfileHeader = ({ player, onShortlistClick, dark, onAddNoteBtnClick, hideAddNoteBtn, standalone }) => (
  <HeaderStyled dark={dark.toString()} standalone={standalone ? standalone.toString() : false}>
    <Div dFlexXs middleXs>
      <ImageStyled circular src={player.profile_picture} width={80} height={80} />
      <Div>
        <Div style={{ display: 'flex' }}>
          <PlayerName dark={dark.toString()}>{player.firstname} {player.lastname}</PlayerName>
          {showShareButton && !standalone && <Div style={{ paddingLeft: '2px' }}> <LinkStyled to={`/player/${player.slug}`} target="_blank" title="Open shareable link"><Icon name="share square" size='small' /></LinkStyled></Div>}
        </Div>
        <ClubName dark={dark.toString()}>
          {player.current_club}
        </ClubName>
      </Div>
    </Div>
    <Actions>
      <Popup
        trigger={
          <Button btnSm onClick={() => { }}>Shortlists</Button>
        }

        on="click"
        position={!standalone ? "bottom left" : "bottom right"}
        style={{ height: '400px', overflowY: 'auto' }}
      >
        <Shortlists player={player} />
      </Popup>
      <Button btnSm onClick={onAddNoteBtnClick} style={{ marginTop: '16px' }} disabled={hideAddNoteBtn}>Add note</Button>
    </Actions>
  </HeaderStyled>
);

export default ProfileHeader;
