import React, { Component, Fragment } from 'react';
import { TransitionablePortal, Message, Step, Breadcrumb, Icon, Confirm } from 'semantic-ui-react';
import { FlexboxGrid as Div } from 'baroo';
import moment from 'moment';
import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Api from '../services/api';

import { getSeasonsFromApi } from '../services/utils';

import { ButtonLight, VerticalDivider } from '../assets/themes/styles/components/Styled/Styled';
import { Loading } from '../components/Styled';
import EventCardDetail from './EventCardDetail';
import PlayerTable from '../components/PlayerTable/PlayerTable';
import { StyledSteps } from '../assets/themes/styles/components/Steps/Steps';
import Modal from '../components/Modal';
import InviteFooter from './InviteFooter';
import FilterModal from '../components/PlayerTable/FilterModal';
import PlayerProfileContainer from '../playerProfile/PlayerProfileContainer';
import InivteModal from './InviteModal/InviteModal';
import {
  Container,
  Item,
  ItemNoMargin,
  MessageStyled,
  FullHeight
} from '../assets/themes/styles/events/EventDetailPage';

const DATE_FORMAT = null;
const INITIAL_FILTERS = { seasons: [4040, 4039] }

class EventDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: null,
      error: null,
      loading: false,
      stepActive: 'applicant',
      playerSelected: 0,
      playerInvited: 0,
      playerToInvite: [],
      filterModalOpen: false,
      selectedFilterModalOpen: false,
      invitesModalOpen: false,
      hideDetail: true,
      filters: { ...INITIAL_FILTERS },
      selectedFilters: { ...INITIAL_FILTERS },
      rightPanelOpen: false,
      confirmInviteOpen: false,
      currentSeasons: [],
    };
    this.rightPanelRef = React.createRef()

  }
  async componentDidMount() {
    this.setState({ loading: true }, () => {
      this.initComponent();
      this.setState({ filters: { ...INITIAL_FILTERS } })
    });

    await Api.getEvent(this.props.match.params.id)
      .then((data) => {
        if (data.response) {
          const event = data.response;
          this.setState({
            loading: false,
            event: event,
            playerInvited: event.selected_count,
          });
          window.amplitude.getInstance().logEvent('Event viewed', {
            id: event.id,
            name: event.name,
            applied_count: event.applied_count,
            invited_count: event.invited_count,
            accepted_count: event.accepted_count,
            declined_count: event.declined_count,
            selected_count: event.selected_count
          });

        } else {
          this.setState({
            loading: false,
            error: 'Event not found',
          });
        }
      }).catch(() => {
        this.setState({
          loading: false,
          error: 'Event not found',
        });
      });
  }

  initComponent = async () => {
    const currentSeasons = await getSeasonsFromApi();
    this.setState({
      currentSeasons
    });
  }

  activeStep = (step) => {
    if (step !== this.state.stepActive) {
      this.setState({ stepActive: step })
    }
  }

  onPlayerSelect = ({ slug }) => {
    this.setState((prevState) => {
      return { playerToInvite: [...prevState.playerToInvite, slug] }
    })
  }

  onPlayerDeselect = ({ slug }) => {
    this.setState((prevState) => {
      return { playerToInvite: prevState.playerToInvite.filter(e => e !== slug) }
    })
  }
  onInviteClick = async () => {
    this.setState({ confirmInviteOpen: true })
  }
  closeConfirmInvite = () => {
    this.setState({ confirmInviteOpen: false })
  }
  invitePlayers = async () => {
    const { playerToInvite, event, playerInvited } = this.state
    this.closeConfirmInvite()
    let nextPlayerInvited = 0;
    if (playerToInvite.length) {
      nextPlayerInvited = playerInvited + playerToInvite.length;
      await Api.selectPlayers(event.id, playerToInvite)
      window.amplitude.getInstance().logEvent('Events: Send invite clicked', { event_id: event.id, event_name: event.name, players: playerToInvite });
    } else {
      nextPlayerInvited = event.applied_count + event.invited_count;
      await Api.selectAll(event.id)
      window.amplitude.getInstance().logEvent('Events: Send invite clicked', { event_id: event.id, event_name: event.name, invite_all: true });
    }
    this.applicantTable.setFilters()
    this.selectedTable.setFilters()
    this.setState({ playerToInvite: [], playerInvited: nextPlayerInvited })
  }

  getInivteProps = () => {
    const dateDisplay = 'MMM Do';
    const now = moment();
    const { event, playerToInvite, playerInvited } = this.state;
    const defaultProps = {
      limit: event.selection_limit,
      selected: playerInvited + playerToInvite.length,
      onInviteClick: this.onInviteClick,
    }
    if (!event.selection_limit && !playerToInvite.length) {
      return {
        ...defaultProps,
        selected: 0,
        disabled: false,
        message: 'This is a not capped event, you can invite everyone'
      }
    }
    if (playerInvited === 0 && playerToInvite.length === 0) {
      return {
        ...defaultProps,
        disabled: true,
        message: 'You need to pick players to send them an invite'
      }
    }
    if (event.selection_limit && playerInvited + playerToInvite.length > event.selection_limit) {
      return {
        ...defaultProps,
        disabled: true,
        message: `Not able to invite. You need to deselect ${defaultProps.selected - defaultProps.limit} players`
      }
    }
    if (playerInvited > 0 && playerToInvite.length === 0) {
      return {
        ...defaultProps,
        disabled: true,
        message: `You have invited ${defaultProps.selected} players. ${defaultProps.limit - defaultProps.selected} invites are still available`
      }
    }
    if (playerToInvite.length > 0) {
      return {
        ...defaultProps,
        disabled: false,
        message: `You have picked ${playerToInvite.length} players. Push "Select" to confirm`
      }
    }
  }

  openInvitesModal = () => {
    this.setState({ invitesModalOpen: true })
  }

  closeInvitesModal = ({ refreshApplicant, playerDeclined = 0 }) => {
    this.setState({ invitesModalOpen: false, playerInvited: this.state.playerInvited - playerDeclined })
    if (refreshApplicant && this.selectedTable) {
      this.selectedTable.setFilters()
    }
  }

  openFilterModal = () => {
    window.amplitude.getInstance().logEvent('Events: Open Filters', { event_id: this.state.event.id, event_name: this.state.event.name });

    this.setState({ filterModalOpen: true })
  }

  openSelectedFilterModal = () => {
    window.amplitude.getInstance().logEvent('Events: Open Filters', { event_id: this.state.event.id, event_name: this.state.event.name });

    this.setState({ selectedFilterModalOpen: true })
  }

  closeFilterModal = () => {
    this.setState({ filterModalOpen: false })
  }

  closeSelectedFilterModal = () => {
    this.setState({ selectedFilterModalOpen: false })
  }

  applyFilters = (filters) => {
    if (this.applicantTable) {
      this.applicantTable.setFilters(filters)
      this.setState({ filters })
      window.amplitude.getInstance().logEvent('Events: Apply Filters', {
        ...filters,
        event_id: this.state.event.id, event_name: this.state.event.name
      });
    }
    this.closeFilterModal();
  }

  applySelectedFilters = (filters) => {
    if (this.selectedTable) {
      this.selectedTable.setFilters(filters)
      this.setState({ selectedFilters: filters })
      window.amplitude.getInstance().logEvent('Events: Apply Filters', {
        ...filters,
        event_id: this.state.event.id, event_name: this.state.event.name
      });
    }
    this.closeSelectedFilterModal();
  }

  resetFilters = () => {
    if (this.applicantTable) {
      this.applicantTable.setFilters({ ...INITIAL_FILTERS }, true)
      this.setState({ filters: { ...INITIAL_FILTERS } })
    }
  }

  resetSelectedFilters = () => {
    if (this.selectedTable) {
      this.selectedTable.setFilters({ ...INITIAL_FILTERS }, true)
      this.setState({ selectedFilters: { ...INITIAL_FILTERS } })
    }
  }

  toggleDetail = () => {
    window.amplitude.getInstance().logEvent(this.state.hideDetail ? 'Events: Show Detail' : 'Events: Hide Detail', { event_id: this.state.event.id, event_name: this.state.event.name });

    this.setState({ hideDetail: !this.state.hideDetail })
  }

  onPlayerClick = (player) => {
    if (!this.state.rightPanelOpen) {
      window.amplitude.getInstance().logEvent('Player profile opened', { slug: player.slug });
    }
    if (this.rightPanelRef) {
      //this.rightPanelRef.current.scrollTop = 0;
    }
    this.setState({ rightPanelOpen: true, seletectedPlayer: player })
  }

  closeRightPanel = () => {
    if (this.state.rightPanelOpen) {
      this.setState({ rightPanelOpen: false })
      window.amplitude.getInstance().logEvent('Player profile closed');
    }
  }


  render() {
    const { event, error, loading, stepActive, playerInvited, filters, filterModalOpen, selectedFilters, selectedFilterModalOpen, invitesModalOpen, hideDetail, rightPanelOpen, seletectedPlayer, playerToInvite, confirmInviteOpen, currentSeasons } = this.state;
    const now = moment()
    let canSelectPlayer = false;
    if (event && event.selection_limit) {
      canSelectPlayer = !event.selection_limit || playerInvited < event.selection_limit;
    }

    return (
      <FullHeight>

        <Fragment>
          <Header user={this.props.user} />
          {!!event &&
            <Container>
              <Item>
                <Breadcrumb>
                  <Breadcrumb.Section><Link to="/events">Events</Link></Breadcrumb.Section>
                  <Breadcrumb.Divider />
                  <Breadcrumb.Section active>{event.name}</Breadcrumb.Section>
                </Breadcrumb>
                <ButtonLight btnXs onClick={this.toggleDetail}>
                  {hideDetail && <Fragment><Icon className="icon-caret-down" /><span>Show detail</span></Fragment>}
                  {!hideDetail && <Fragment><Icon className="icon-caret-up" /><span>Hide detail</span></Fragment>}
                </ButtonLight>
              </Item>
              {!hideDetail && <Item>
                <EventCardDetail event={event} />
              </Item>}
              <ItemNoMargin>
                <StyledSteps unstackable attached="top">
                  <Step active={stepActive === 'applicant'} onClick={() => this.activeStep('applicant')}>
                    <Step.Content>
                      <Step.Title>Applicants ({event.applied_count})</Step.Title>
                    </Step.Content>
                  </Step>
                  <Step active={stepActive === 'selected'} onClick={() => this.activeStep('selected')}>
                    <Step.Content>
                      <Step.Title>Selected ({playerInvited || 0}{event.selection_limit && ' / '}{event.selection_limit})</Step.Title>
                    </Step.Content>
                  </Step>
                </StyledSteps>
                {stepActive === 'applicant' && <ButtonLight onClick={this.openFilterModal}><Icon className="icon-filter" size='large' /><span>Filters </span></ButtonLight>}
                {stepActive === 'selected' && <ButtonLight onClick={this.openSelectedFilterModal}><Icon className="icon-filter" size='large' /><span>Filters </span></ButtonLight>}
                {stepActive === 'selected' && <VerticalDivider />}
                {stepActive === 'selected' && <ButtonLight onClick={this.openInvitesModal}><Icon className="icon-invites" size='large' /><span>Manage Invites </span></ButtonLight>}
              </ItemNoMargin>
              <PlayerTable
                ref={el => this.selectedTable = el}
                dNoneXs={stepActive !== 'selected'}
                fetchMethod={(params, options) => Api.getSelected(event.id, { ...params, page_size: playerInvited }, options)}
                onRowClick={this.onPlayerClick}
                openInvitesModal={this.openInvitesModal}
                showStatusCol paging={false} />
              <PlayerTable
                ref={el => this.applicantTable = el}
                dNoneXs={stepActive !== 'applicant'}
                fetchMethod={(params, options) => Api.getApplicants(event.id, { ...params, ...filters }, options)}
                showSelectCol
                onRowSelect={this.onPlayerSelect}
                onRowDeselect={this.onPlayerDeselect}
                selectionDisabled={!canSelectPlayer}
                onRowClick={this.onPlayerClick}
                openInvitesModal={this.openInvitesModal}
                paging />
              {stepActive === 'applicant' && <InviteFooter {...this.getInivteProps()} />}
              <FilterModal
                data={{ filters }}
                audience={event.audience}
                modalOpen={filterModalOpen}
                handleClose={this.closeFilterModal}
                onSuccess={this.applyFilters}
                onFail={() => { this.resetFilters(); this.closeFilterModal(); }}
                dark
                currentSeasons={currentSeasons}
              />
              <FilterModal
                data={{ filters: selectedFilters }}
                audience={event.audience}
                modalOpen={selectedFilterModalOpen}
                handleClose={this.closeSelectedFilterModal}
                onSuccess={this.applySelectedFilters}
                onFail={() => { this.resetSelectedFilters(); this.closeSelectedFilterModal(); }}
                dark
                currentSeasons={currentSeasons}
              />
              <InivteModal
                data={{ players: this.selectedTable && this.selectedTable.getData(), event }}
                modalOpen={invitesModalOpen}
                handleClose={this.closeInvitesModal}
                dark
              />
            </Container>
          }
          {loading && <Loading text="Loading events..." />}
          {error &&
            <TransitionablePortal open={!!error}
              transition={{
                animation: 'fade',
                duration: 300,
              }}
            >
              <MessageStyled negative
                style={{
                  left: '40%',
                  position: 'fixed',
                  top: '50%',
                  zIndex: 1000,
                }}
              >
                <Message.Header>The event you are looking for doesn't exist</Message.Header>
              </MessageStyled>

            </TransitionablePortal>
          }
        </Fragment>
        <Modal
          modalOpen={rightPanelOpen}
          handleClose={this.closeRightPanel}
          size='large'
          hideactions
          dark
        >
          <PlayerProfileContainer player={seletectedPlayer} dark inModal />
        </Modal>
        <Confirm
          open={confirmInviteOpen}
          onConfirm={this.invitePlayers}
          onCancel={this.closeConfirmInvite}
          content={() => (
            <Div className='content'>
              You are going to invite <strong>{playerToInvite.length ? playerToInvite.length : 'all'}</strong> players. Are you sure?
            </Div>)}
        />
      </FullHeight>

    );
  }
}

export default EventDetailPage;
