import styled, { css } from 'styled-components';
import { Modal, Icon } from 'semantic-ui-react';
import { Button, Themes} from 'baroo';

const CloseIcon = styled(Icon)`
  &.icon {    
    margin: 0;
    margin-top: 6px;
    font-size: 16px;
    color: ${props => props.dark ? 'white': 'black'};
    cursor: pointer;
  }
`;

const ModalStyled = styled(Modal)`
  &.ui.modal {
    margin-top: 80px !important;
    border-radius: 8px;
    .header {
      padding: 0px 16px 0 16px !important;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      ${Themes.Media.sm`
        padding: 0px 32px 0 32px !important;
      `}
    }
    >.header {
      height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: inherit;
      font-size: 24px;
      font-weight: bold;
      text-transform: uppercase;
      color: #000;
      border: none;
      padding: 0px 16px 0 16px !important;
      ${Themes.Media.sm`
        height: 80px;
        font-size: 28px;
        padding: 0px 32px 0 32px !important;
      `}
    }
    >.actions {
      background: none;
      padding: 0 16px !important;
      height: 56px;
      align-items: center;
      display: flex;
      justify-content: flex-end;
      ${Themes.Media.sm`
      height: 80px;
        padding: 0 32px  !important;
      `}
      border: none;
      a:not(:first-child){
        margin-left: 16px;
      }
      > div {
        justify-content: flex-end;
        align-items: center;
        a {
          width: 133px;
        }
      }
    }
    >.content {
      padding: 0 !important;
      
      ${Themes.Media.sm`
        padding: 0 !important;
      `}
      >.steps {
        padding: 0 16px;
        ${Themes.Media.sm`
          padding: 0 32px;
        `}
      }
      >.segment {
        height: calc(100vh - 260px);
        min-height: 310px;
        padding: 16px 16px 0 16px;
        border-bottom: solid 1px #dbdbdb;
        overflow: auto;
        -webkit-overflow-scrolling:touch;
        border-top: solid 1px #dbdbdb !important;
        width: 100%;
        margin: 0;
        background: #f0f2f3;
        ${props => props.hideactions && css`
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        `}
        ${Themes.Media.sm`
          padding: 32px 32px 0 32px;
          height: calc(100vh - 340px);
          min-height: 420px;
        `}
        .ui.segment {
          margin-bottom: 24px;
          margin-top: 0;
          border: none;
          box-shadow: none;
          border-radius: 8px;
          padding: 32px;
        }
      }
    }
    >:first-child:not(.icon) {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    >:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
  .ui.attached.segment {
    border: none;
  }
  ${props => props.dark && css`
    &.ui.modal {
      >.header {
        background: #000;
        color: white;
      }
      >.content {
        >.segment {
          border-top: none;
          border-bottom: none !important
        }
      }
      >.actions {
        background: black;
      }
    }
  `}
`;

const FailButton = Button.extend`
  border: none;
  font-weight: normal;
  width: auto !important;
  color: ${props => props.dark ? 'white': 'black'};
  &:hover {
    color: ${props => props.dark ? 'white': 'black'};
  }
`;

const CancelButton = Button.extend`
    color: ${props => props.dark ? 'white': 'black'};
    border-color: ${props => props.dark ? 'white': 'black'};
    &:hover {
      color: ${props => props.dark ? 'white': 'black'};
      border-color: ${props => props.dark ? 'white': 'black'};
    }
`;

export {
  CloseIcon,
  ModalStyled,
  FailButton,
  CancelButton,
};