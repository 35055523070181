import { SHORTLIST_PLAYER, UNSHORTLIST_PLAYER, UPDATE_SHORTLIST_PLAYER } from '../actionTypes';

const initialState = {
  addedPlayers: [],
  removedPlayers: [],
  players: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SHORTLIST_PLAYER: {
      const { playerId } = action.payload;
      return {
        ...state,
        addedPlayers: state.addedPlayers.includes(playerId) ?
          [...state.addedPlayers] :
          [...state.addedPlayers, playerId],
        removedPlayers: state.removedPlayers.includes(playerId) ?
          [...state.removedPlayers.filter((id) => id !== playerId)] :
          [...state.removedPlayers],
      };
    }
    case UNSHORTLIST_PLAYER: {
      const { playerId } = action.payload;
      return {
        ...state,
        removedPlayers: state.removedPlayers.includes(playerId) ?
          [...state.removedPlayers] :
          [...state.removedPlayers, playerId],
        addedPlayers: state.addedPlayers.includes(playerId) ?
          [...state.addedPlayers.filter((id) => id !== playerId)] :
          [...state.addedPlayers],
      };
    }
    case UPDATE_SHORTLIST_PLAYER: {
      const { playerId, shortlistIds } = action.payload;
      const newPlayers = { ...state.players };
      newPlayers[playerId] = [...shortlistIds];
      return {
        ...state,
        players: newPlayers,
      };
    }
    default:
      return state;
  }
}
