import styled from 'styled-components';
import { FlexboxGrid as Div } from 'baroo';
import { Link } from 'react-router-dom';
import Panel from '../../../../components/Panel';

const PanelStyled = Panel.withComponent(Link).extend`
  height: 100%;
  display: block;
  color: unset;
  &:hover {
    color: unset;
    text-decoration: none;
  }
`;
const CategoryCol = Div.extend`
`;
const Label = styled.div`
  font-weight: bold;
  color: #242c39;
`;
const Description = styled.div`
  color: #7f7f7f;
`;
const PanelHeaderStyled = styled(Panel.Header)`
  height: 48px;
`;
const PanelBodyStyled = styled(Panel.Body)`
    display: flex;
    flex-direction: column;
    height: calc(100% - 48px);
    justify-content: space-between;
    clear: both;
`;

export {
  PanelStyled,
  CategoryCol,
  Label,
  Description,
  PanelHeaderStyled,
  PanelBodyStyled
};
