import styled from 'styled-components';
import { FlexboxGrid as Div, Button, Themes } from 'baroo';
import { Divider } from 'semantic-ui-react';
import background from '../../../images/background.jpg';

const FullHeight = Div.extend`
  height: 100%;
`;
const Body = FullHeight.extend`
  position: relative;
  width: 100%;
`;

const LeftPanel = Div.extend`
  background: black;
  height: 100vh;
  overflow: visible;
`;

const Logo = styled.div`
  padding: 24px 16px;
  position: absolute;
  top: 0;
  > img {
    width: 121px;
  }
  ${Themes.Media.sm`
    padding: 32px;
    > img {
      width: 168px;
    }
  `}
`;

const LoginPanel = Div.extend`
  background: white;
  padding: 24px 14px;
  width: 344px;
  top: 50%;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  ${Themes.Media.sm`
    padding: 40px;
    width: 560px;
  `}
  ${Themes.Media.md`
    left: 25%;
  `}
`;

const H1 = styled.h1`
  font-family: "Open Sans";
  font-weight: bold;
  margin-bottom: 24px;
  font-size: 28px;
  text-transform: uppercase;
  ${Themes.Media.sm`
    margin-bottom: 32px;
  `}
  > div {
    overflow: visible !important;
    vertical-align: unset !important;
  }
`;

const BackgroundPicture = Div.extend`
  background: url(${background});
  height: 100vh;
  background-size: cover;
  background-position: center;
`;

const DividerStyled = styled(Divider)`
  &.ui.divider{
    max-width: 240px;
    margin: 24px auto !important;
    font-size: 28px;
    color: black;
    &:before {
      background-position: right 8px top 50%;
    }
    &:after {
      background-position: left 8px top 50%;
    }
  }
`;

const ButtonStyled = Button.extend`
  -webkit-appearance: none;
  width: 100%;
  background: black;
  color: white;
  font-size: 16px;
  border: none;
  padding: 14px 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    text-decoration: none;
    color: white;
  }
  > img {
    margin-right: 16px;
    width: 32px;
    height: 32px;
  }
`;

const Terms = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 4px !important;
  font-size: 10px;
   > a {
     color: black;
   }
`;

export {
  FullHeight,
  Body,
  LeftPanel,
  Logo,
  LoginPanel,
  H1,
  BackgroundPicture,
  DividerStyled,
  ButtonStyled,
  Terms,
};
