import React, { Component } from 'react';
import logo from '../../assets/images/noimage-placeholder.png';
import { Grid } from 'semantic-ui-react';
import { isNullOrUndefined } from '../../services/utils';

import {
  MatchScore,
  TeamName,
  TeamColour,
  TeamImage,
  MatchScoreDate,
  MatchScoreDigits
} from '../../assets/themes/styles/components/SlideshowMatch/SlideshowMatch';

class ScoreHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data } = this.props;
    const homeScore = !isNullOrUndefined(data.match.score.home) || data.match.score.home === 0 ? data.match.score.home : ' ';
    const awayScore = !isNullOrUndefined(data.match.score.away) || data.match.score.away === 0 ? data.match.score.away : ' ';

    return (<Grid stackable style={{
      padding: '8px', height: '110px', justifyContent: 'center',
      margin: 'auto 1px'
    }}>
      <Grid.Column width={6} style={{ display: 'flex', justifyContent: 'flex-end', maxWidth: '330px' }}>
        <Grid stackable style={{ alignItems: 'center' }}>
          <Grid.Column width={8} style={{ display: 'flex', justifyContent: 'center' }}>
            <TeamName textAlign={'right'}>{data.homeTeam.name}</TeamName>
          </Grid.Column>
          <Grid.Column width={6} style={{ display: 'flex', justifyContent: 'center' }}>
            <TeamImage src={data.homeTeam.logo ? data.homeTeam.logo : logo} alt={data.homeTeam.name}></TeamImage>
          </Grid.Column>
          <Grid.Column width={2}>
            <TeamColour teamColor={data.homeTeam.color}></TeamColour>
          </Grid.Column>
        </Grid>
      </Grid.Column>
      <Grid.Column width={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <MatchScore>
          <MatchScoreDate>{data.match.date}</MatchScoreDate>
          <MatchScoreDigits>
            {`${homeScore} - ${awayScore}`}
          </MatchScoreDigits>
        </MatchScore>
      </Grid.Column>
      <Grid.Column width={6} style={{ display: 'flex', justifyContent: 'flex-start', maxWidth: '330px' }}>
        <Grid stackable style={{ alignItems: 'center' }}>
          <Grid.Column width={2}>
            <TeamColour teamColor={data.awayTeam.color}></TeamColour>
          </Grid.Column>
          <Grid.Column width={6} style={{ display: 'flex', justifyContent: 'center' }}>
            <TeamImage src={data.awayTeam.logo ? data.awayTeam.logo : logo} alt={data.awayTeam.name}></TeamImage>
          </Grid.Column>
          <Grid.Column width={8} style={{ display: 'flex', justifyContent: 'center' }}>
            <TeamName textAlign={'left'}>{data.awayTeam.name}</TeamName>
          </Grid.Column>
        </Grid>
      </Grid.Column>
    </Grid>);
  }
}

export default ScoreHeader;
