import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import TableCells from '../../../../../components/DataTable/TableCells';

const TableStyled = styled.table`
  position: relative;
 tr {
  ${props => props.hoveredRowIndex !== null && `
    &:nth-of-type(${props.hoveredRowIndex + 1}) {
      > td {
        background: #efeff4;
      }
    }
  `}
 }
`;

const LightTableStyled = styled.table`
  position: relative;
  width: 100%;
  tr:hover {    
    > td {
      background: #efeff4;
    }
  }
`;

const LeftPane = styled.div`
  overflow: auto;
  overflow-x: scroll;
  -webkit-overflow-scrolling:touch;
  min-width: ${props => props.width + 'px'};
  padding-right: 30px;
  box-sizing: content-box;
  > table {
    box-shadow: 2px 0 4px 2px rgba(0,0,0,0.05);
  }
`;

const TopLeftPane = LeftPane.extend`
  overflow-x: hidden;
`;

const RightPane = LeftPane.extend`
 background: white;
 min-width: ${props => props.width + 'px'};
 margin-left: -33px;
 box-shadow: -2px 0 4px 2px rgba(0,0,0,0.05);
 > table {
   background: white;
 }
`;

const TopRightPane = RightPane.extend`
  overflow-x: hidden;
`;

const CenterPane = styled.div`
  overflow: auto;
  -webkit-overflow-scrolling:touch;
  background: white;
  width: ${props => 'calc(100vw - ' + props.width + 'px)'};
  margin-left: ${props => props.hasLeftPane ? '-29px': '0'}; 
  box-sizing: content-box;
  padding-right: ${props => props.hasRightPane ? '30px': '0'}; 
`;

const TopCenterPane = CenterPane.extend`
  margin-top: 0;
  height: 80px;
  overflow-y:scroll;
`;

const Grid = styled.div`
  display: grid;
  grid-template-rows: 49px auto; 
`;

const Cell = styled(TableCells)`
  padding: 0 16px;
  font-size: 13px;
  min-width: 0;
  color: #555;
  border-right: solid 1px #f2f2f2;
  border-bottom: solid 1px #cdcdcd;
  position: relative;
  white-space: nowrap;
  overflow: ${props => props.overflowvisible ? 'visible' : 'hidden'};
  text-overflow: ellipsis;
  vertical-align: middle;
  height: ${props => props.rowheight + 'px'};
  max-height: ${props => props.rowheight + 'px'};
  width: ${props => props.width + 'px'};
  min-width: ${props => props.width + 'px'};
  max-width: ${props => props.width + 'px'};
`;

const CellHeader = Cell.withComponent('th').extend`
  font-weight: ${props => props.active ? 'bold': 'normal'};
  text-transform: uppercase;
  font-size: 12px;
  color: ${props => props.active ? '#242c39': '#555'};
  border-right: solid 1px #f2f2f2;
  border-top: solid 1px #cdcdcd;
  height: 48px;
  ${props => props.sortable && `
    cursor: pointer;
    &:hover {
      font-weight: bold;
    } 
  `}
`;

const SortIcon = styled(Icon)`
  &.icon {
    position: absolute;
    margin: 0;
    right: 4px;
    top: 17px;
    opacity: ${props => props.active ? '1': '0.3'}
  }
`;

export {
  TableStyled,
  LightTableStyled,
  LeftPane,
  TopLeftPane,
  RightPane,
  TopRightPane,
  CenterPane,
  TopCenterPane,
  Grid,
  Cell,
  CellHeader,
  SortIcon,
};