import React, { Component, Fragment } from 'react';
import {
  TrStyled
} from '../../assets/themes/styles/components/DataTable/TableRows';

class TableRows extends Component {
  shouldComponentUpdate(nextProps) {
    const { rows, columns } = this.props;

    if (nextProps.rows.length !== rows.length) {
      return true;
    }
    if (nextProps.columns.length !== columns.length) {
      return true;
    }
    if ((new Set([...nextProps.columns.map((c) => c.key), ...columns.map((c) => c.key)])).size !== columns.length) {
      return true;
    }
    return false;
  }
  render() {
    const { rows, onRowHover, columns, cellRenderer, onRowClick } = this.props;
    return (
      <Fragment>
        {rows.map((d, i) => (
          <TrStyled
            key={`row-${i}`}
            onMouseEnter={() => onRowHover && onRowHover(i)}
            rowClickable={!!onRowClick}
            onClick={() => onRowClick && onRowClick(d)}
          >
            {columns.map((col) => cellRenderer({
              col,
              d,
              rowIndex: i,
            }))}
          </TrStyled>
        ))}
      </Fragment>
    );
  }
}

export default TableRows;
