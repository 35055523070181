import React from 'react';
import { Segment, Icon } from 'semantic-ui-react';
import { FlexboxGrid as Div } from 'baroo';
import dayjs from 'dayjs';
import _ from 'lodash';
import { POSITIONS, titleCaseEveryWordInString, calculateHeight, isNullOrUndefined } from '../services/utils';
import {
  CategoryName,
  Label,
  Content,
  DataBox,
  BoxDiv,
  ExpectedIcon,
  ExpectedLabel
} from '../assets/themes/styles/playerProfile/ProfileStep';

function getItem(player, item, defaultReturn = null) {
  if (!isNullOrUndefined(player[item])) {
    return player[item];
  }
  return defaultReturn;
}
function buildParents(player) {
  if (player.parents_email || player.parents_phone_number) {
    return (<Div>
      {player.parents_email && <Div>{player.parents_email}</Div>}
      {player.parents_phone_number && <Div>{player.parents_phone_number}</Div>}
    </Div>);
  }
  return '-';
}
function buildAgent(player) {
  if (isNullOrUndefined(player.agent)) {
    return null;
  } else if (!player.agent) {
    return 'No';
  } else if (player.agent_name || player.agent_email || player.agent_phone_number) {
    return (<Div>
      {player.agent_name && <Div>{player.agent_name}</Div>}
      {player.agent_email && <Div>{player.agent_email}</Div>}
      {player.agent_phone_number && <Div>{player.agent_phone_number}</Div>}
    </Div>);
  }
  return null;
}
function buildLanguages(player) {
  let playerLanguages = getItem(player, 'languages');
  if (playerLanguages) {
    let string = '';
    if (typeof playerLanguages === 'string') {
      string = playerLanguages;
    } else {
      playerLanguages.forEach((value, index) => {
        string += `${index !== 0 ? `, ` : ``}${value}`;
      });
    }
    return string;
  }
  return '-';
}
function buildCitizenship(player) {
  let playerNationality = getItem(player, 'nationality');
  let playerSecondNationality = getItem(player, 'second_nationality', '');

  if (playerNationality || playerSecondNationality) {
    let string = playerNationality;
    if (playerSecondNationality) {
      playerSecondNationality.forEach((value) => {
        string += `, ${value}`;
      });
    }
    return string;
  }
  return '-';
}
function buildTransfer(player) {
  let playerTransfers = getItem(player, 'preferred_transfer_type');
  if (playerTransfers) {
    let string = '';
    playerTransfers.forEach((value, index) => {
      string += `${index !== 0 ? `, ` : ``}${value}`;
    });
    return string;
  }
  return '-';
}
function calcExpectedHeight(player, height, weight) {
  const { gender, detailedData } = player;
  let response = null;
  // Check that gender and detailsData exists
  if (gender && height && weight && detailedData) {
    const { father_height_in_cms, mothers_height_in_cms, dob } = detailedData;
    // Check that all required fields exists to complete calculation
    if (father_height_in_cms && mothers_height_in_cms && dob) {
      // Get age based on dob
      const age = dayjs().diff(dayjs(dob), 'years');
      const calculatedHeight = calculateHeight(gender, age, height, weight, mothers_height_in_cms, father_height_in_cms);
      // Calculate Expected Height
      if (calculatedHeight) {
        response =
          <Div>
            <ExpectedIcon><Icon className="icon-plus-minus" size='normal' /></ExpectedIcon>
            <ExpectedLabel>{calculatedHeight}</ExpectedLabel>
          </Div>
          ;
      }
    }
  }
  return response;
};

// UK specific rules
function playerCountriesHasUK(player) {
  // Check that the user has countries filled
  if (player.countries) {
    // If the country is a string then check if it includes lower case United Kingdon
    if (typeof player.countries === 'string' && player.countries.toLowerCase().includes('united kingdom')) {
      // Dont show English Proficiency for United Kingdom
      return false;
    }
  }
  return true;
}

function buildContract(detailedData) {
  if (isNullOrUndefined(detailedData.contract)) {
    return null;
  }
  if (!detailedData.contract) {
    return 'No';
  } else {
    const contractExpiry = getItem(detailedData, 'contract_expiration');
    if (isNullOrUndefined(contractExpiry)) {
      return 'Yes';
    } else {
      return contractExpiry;
    }
  }
  return null;
}

const ProfileStep = ({ player, standalone }) => {
  // Return null if the player doesnt exist
  if (isNullOrUndefined(player)) {
    return null;
  }
  // Bio 
  let position = getItem(player, 'primary_position');
  let birthday = getItem(player, 'birthday');
  let shirt = getItem(player, 'shirt_number');
  // Metrics
  let height = player.height;
  let weight = player.weight;
  let primaryFoot = player.primary_foot;

  // Default Detailed Bio
  let englishSkill = null;
  let yearOfGrad = null;
  let contract = null;
  let citizenship = null;
  let languages = null;
  let transferPreference = null;
  let agent = null;
  let parents = null;

  // Default Detailed Metrics
  let weakFoot = null;
  let expectedHeight = null;

  // Check if detailed bio exists
  if (player.detailedData) {
    const { detailedData } = player;
    // DETAILED BIO
    birthday = getItem(detailedData, 'dob');
    englishSkill = getItem(detailedData, 'english_skill');
    yearOfGrad = getItem(detailedData, 'year_of_graduation');
    contract = buildContract(detailedData);
    citizenship = buildCitizenship(detailedData);
    languages = buildLanguages(detailedData);
    transferPreference = buildTransfer(detailedData);
    agent = buildAgent(detailedData);
    parents = buildParents(detailedData);

    // DETAILED METRICS
    height = getItem(detailedData, 'height');
    weight = getItem(detailedData, 'weight');
    weight = weight !== null && weight !== undefined && typeof weight === 'string' ? parseInt(weight, 10) : null;
    primaryFoot = getItem(detailedData, 'primary_foot');
    expectedHeight = calcExpectedHeight(player, height, weight);
    weakFoot = detailedData.weak_foot_skill;
  }

  // Show English Proficiency or not
  let showEnglishProficiency = playerCountriesHasUK(player);

  const bio = [
    {
      label: 'Position',
      value: position && POSITIONS.find((p) => p.id === position).abbreviation,
      show: true,
    },
    {
      label: 'Birthday',
      value: birthday && `${birthday} (${dayjs().diff(dayjs(birthday), 'years')})`,
      show: true,
    },
    {
      label: 'Shirt #',
      value: shirt,
      show: true,
    },
    {
      label: 'Citizenship',
      value: citizenship,
      show: true,
    },
    {
      label: 'Languages',
      value: languages,
      show: true,
    },
    {
      label: 'English Proficiency',
      value: englishSkill && `${englishSkill}/10`,
      show: showEnglishProficiency, // Only show if not United Kingdom
    },
    {
      label: 'Year of Graduation',
      value: yearOfGrad,
      show: true,
    },
    {
      label: 'Transfer Preference',
      value: transferPreference,
      show: true,
    },
    {
      label: 'Contract',
      value: contract,
      show: true,
    },
    {
      label: 'Agent',
      value: agent,
      show: true,
    },
    {
      label: 'Parents',
      value: parents,
      show: true,
    },
  ];

  const metrics = [
    {
      label: 'Height',
      value: height,
    },
    {
      label: 'Weight',
      value: weight,
    },
    {
      label: 'Expected Height',
      value: expectedHeight,
    },
    {
      label: 'Preferred Foot',
      value: titleCaseEveryWordInString(primaryFoot),
    },
    {
      label: 'Assessment Weak Foot',
      value: weakFoot && `${weakFoot}/10`,
    },
  ];

  const individualPerf = [
    {
      label: 'Matches',
      value: player.matches_played,
    },
    {
      label: 'Goals',
      value: player.goals,
    },
    {
      label: 'Assists',
      value: player.assists,
    },
    {
      label: 'Clean Sheets',
      value: player.clean_sheets,
    },
    {
      label: 'Votes',
      value: player.motm_votes,
    },
    {
      label: 'Votes %',
      value: player.team_motm_votes_ratio && `${(player.team_motm_votes_ratio * 100).toFixed(2)}`,
    },
    {
      label: 'Player Performance',
      value: player.player_performance,
    },
    {
      label: 'Match rating',
      value: player.average_rating && player.average_rating.toFixed(2),
    },
    {
      label: 'Top 3 skills',
      value: player.top_3_skills && player.top_3_skills,
    },
  ];

  const teamPerf = [
    {
      label: 'League Level',
      value: player.leagues_level && `Level ${_.isArray(player.leagues_level) ? player.leagues_level.join(', ') : player.leagues_level}`,
    },
    {
      label: 'Win/Tie/Loss',
      value: `${player.wins}/${player.ties}/${player.losses}`,
    },
    {
      label: 'Next Match Date',
      value: player.next_match_date && dayjs(player.next_match_date).format('YYYY-MM-DD HH:mm'),
    },
    {
      label: 'Next Opponent',
      value: player.next_match_opponent,
    },

    {
      label: 'Goals / Goals Against',
      value: `${player.team_goals} / ${player.team_goals_against}`,
    },
    {
      label: 'Team Performance',
      value: player.team_performance,
    },

  ];

  return (
    <Div row>
      <BoxDiv colXs={12} colSm={9} dInlineFlex standalone={standalone}>
        <Segment>
          <CategoryName>Biography</CategoryName>
          <Div row>
            {bio.filter(x => x.show).map((d, i) => (
              <DataBox key={`Biography_${i}`} colXs={4}>
                <Label>{d.label}</Label>
                <Content>{d.value || '-'}</Content>
              </DataBox>))}
          </Div>
        </Segment>
      </BoxDiv>

      <BoxDiv colXs={12} colSm={3} dInlineFlex standalone={standalone}>
        <Segment>
          <CategoryName>Metrics</CategoryName>
          <Div row>
            {metrics.map((d, i) => (
              <DataBox key={`Metrics_${i}`} colXs={12}>
                <Label>{d.label}</Label>
                <Content>{d.value || '-'}</Content>
              </DataBox>))}
          </Div>
        </Segment>
      </BoxDiv>

      <Div colXs={12} colSm={6} dInlineFlex>
        <Segment>
          <CategoryName>Individual Performance</CategoryName>
          <Div row>
            {individualPerf.map((d, i) => (
              <DataBox key={`Individual_Performance_${i}`} colXs={6}>
                <Label>{d.label}</Label>
                <Content>{d.value || '-'}</Content>
              </DataBox>))}
          </Div>
        </Segment>
      </Div>

      <Div colXs={12} colSm={6} dInlineFlex>
        <Segment>
          <CategoryName>Team Performance</CategoryName>
          <Div row>
            {teamPerf.map((d, i) => (
              <DataBox key={`Team_Performance_${i}`} colXs={6}>
                <Label>{d.label}</Label>
                <Content>{d.value || '-'}</Content>
              </DataBox>))}
          </Div>
        </Segment>
      </Div>

    </Div>
  );
};

export default ProfileStep;
