import styled from 'styled-components';
import { FlexboxGrid as Div, Themes } from 'baroo';
import { Message } from 'semantic-ui-react';

const Container = Div.extend`
  padding-top: 136px;
`;

const Item = Div.extend`
  display: flex;
  padding: 0 16px;
  margin-bottom: 12px;
  justify-content: space-between;
  ${Themes.Media.sm`
    padding: 0 32px;
  `}
`;

const MessageStyled = styled(Message)`
  > .content {
    margin-top: 12px;
    text-align: center;
  }
`;

export {
  Container,
  Item,
  MessageStyled
};