import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FlexboxGrid as Div, List } from 'baroo';
import { Dropdown, Icon, Search, Ref } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logoplayerdata_white.png';
import greenArrow from '../../assets/images/circled-green-arrow.svg';
import Api from '../../services/api';
import Modal from '../Modal';
import PlayerProfileContainer from '../../playerProfile/PlayerProfileContainer';
import UserAvatar from '../UserAvatar';

import {
  HeaderContainer,
  Logo,
  ListStyled,
  ListItemStyled,
  ListHeaderStyled,
  ListDescriptionStyled,
  ImageStyled,
  SubheaderPopup,
  Subheader,
  MenuLink,
  DivGrow,
  Players,
} from '../../assets/themes/styles/components/Header/Header';

const UserMenu = (user) => (
  <ListStyled>
    <ListItemStyled>
      {user.profile_picture && <ImageStyled avatar src={user.profile_picture} />}
      <List.Content>
        <ListHeaderStyled>{user.firstname || user.lastname ? `${user.firstname} ${user.lastname}` : user.email} <Icon name="caret down" /></ListHeaderStyled>
        {user.club && <ListDescriptionStyled>{user.club.name}</ListDescriptionStyled>}
      </List.Content>
    </ListItemStyled>
  </ListStyled>
);

const isActive = (pathname) => window.location.pathname === pathname;

const resultRenderer = (d) => {
  //const user = {profile_picture: d.owner.profile_picture, firstname: d.title_text, current_club: d.subtitle_text}
  return (<UserAvatar key={d.slug} user={d} />)
}
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchLoading: false,
      results: [],
      seletectedPlayer: null,
      modalOpen: false
    };
  }
  handleResultSelect = (e, { result }) => {
    window.amplitude.getInstance().logEvent('Search player selected', { slug: result.slug, name: result.name, club: result.current_club });
    this.setState({ modalOpen: true, seletectedPlayer: result.slug }, () => {
      window.history.pushState(null, document.title, `/player/${result.slug}`);
    })
  }
  handleSearchChange = async (e, { value }) => {
    this.setState({ searchLoading: true })
    window.amplitude.getInstance().logEvent('Search player', { query: value });
    try {
      const res = await Api.searchPlayer(value);
      const results = _.take(res.response, 5);
      this.setState({ searchLoading: false, results })
      if (results.length === 0) {
        window.amplitude.getInstance().logEvent('Search player not found', { query: value });
      }
    } catch (error) {
      this.setState({ searchLoading: false })
    }
  }

  closeModal = () => {
    this.setState({ seletectedPlayer: null, modalOpen: false }, () => {
      window.history.pushState(null, document.title, `/`);
    })
  }
  onSearchChange = _.debounce(this.handleSearchChange, 500)

  handleHeaderRef = headerRef => this.setState({ headerRef })
  render() {
    const { user } = this.props;
    const { searchLoading, results, modalOpen, seletectedPlayer, headerRef } = this.state;

    return (
      <Fragment>
        <Ref innerRef={this.handleHeaderRef}>
          <HeaderContainer>
            <Div row middleXs>
              <Div>
                <Link to="/"><Logo alt="logo" src={logo} /></Link>
              </Div>
              <DivGrow>
                <Div row middleXs noGutters>
                  {user.access_to_player_search &&
                    <SubheaderPopup trigger={
                      <MenuLink active={isActive('/') || isActive('/shortlist')}>
                        <Players>Players</Players>
                      </MenuLink>
                    }
                      context={headerRef}
                      hoverable
                      flowing
                      on='click'
                      position='bottom left'
                    >
                      <Subheader>
                        <Subheader.Item to="/">
                          <Subheader.Icon><img src={greenArrow} alt={'Arrow'} /></Subheader.Icon>
                          <Subheader.Content>
                            <Subheader.Header>Player Overview</Subheader.Header>
                            <Subheader.Description>Overview of all players available</Subheader.Description>
                          </Subheader.Content>
                        </Subheader.Item>
                        <Subheader.Divider />
                        <Subheader.Item to="/shortlist">
                          <Subheader.Icon><img src={greenArrow} alt={'Arrow'} /></Subheader.Icon>
                          <Subheader.Content>
                            <Subheader.Header>Following</Subheader.Header>
                            <Subheader.Description>These are the players that you follow inside the Tonsser mobile application</Subheader.Description>
                          </Subheader.Content>
                        </Subheader.Item>
                      </Subheader>
                    </SubheaderPopup>
                  }
                  {user.access_to_player_search &&
                    <MenuLink active={isActive('/shortlists')}>
                      <Link to="/shortlists">Shortlists</Link>
                    </MenuLink>
                  }
                  {user.access_to_events && user.access_to_player_search &&
                    <MenuLink active={isActive('/events')}>
                      <Link to="/events">Events</Link>
                    </MenuLink>
                  }
                </Div>
              </DivGrow>
              {user.access_to_player_search &&
                <Div>
                  <Search placeholder="Search for a player"
                    loading={searchLoading}
                    onResultSelect={this.handleResultSelect}
                    onSearchChange={this.onSearchChange}
                    resultRenderer={resultRenderer}
                    results={results}
                  />
                </Div>
              }
              <Div>
                <Dropdown trigger={UserMenu(user)} pointing="top right" icon={null}>
                  <Dropdown.Menu>
                    {user.role === 'admin' &&
                      <Dropdown.Item><Link to="/admin"><Icon name="lock" />Admin</Link></Dropdown.Item>
                    }
                    <Dropdown.Item><Link to="/logout"><Icon name="sign out" />Sign out</Link></Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Div>
            </Div>
            <Modal
              modalOpen={modalOpen && seletectedPlayer && !searchLoading}
              handleClose={this.closeModal}
              size='large'
              hideactions
              dark
            >
              <PlayerProfileContainer playerId={seletectedPlayer} dark inModal />
            </Modal>
          </HeaderContainer>
        </Ref>
      </Fragment>
    );
  }
}

Header.propTypes = { user: PropTypes.object };

Header.defaultProps = { user: {} };
export default Header;
