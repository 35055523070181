import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Icon } from 'semantic-ui-react';
import { POSITIONS, LEAGUE_LEVEL, MIN_BIRTHDAY_YEAR, MONTHS } from '../../services/utils';

import {
  Container,
  FilterButton
} from '../../assets/themes/styles/components/PlayerTable/FiltersRecap';

const FiltersRecap = ({
  shortlisted,
  matches_played_min,
  matches_played_max,
  positions,
  seasons,
  countries,
  agegroups,
  leagues,
  onCountryClick,
  onAgegroupClick,
  onLeagueClick,
  onFiltersRemove,
  birthday_min,
  birthday_max,
  height_min,
  height_max,
  primary_foot,
  average_rating_min,
  average_rating_max,
  motm_votes_min,
  motm_votes_max,
  team_motm_votes_ratio_min,
  team_motm_votes_ratio_max,
  goals_min,
  goals_max,
  assists_min,
  assists_max,
  clean_sheets_min,
  clean_sheets_max,
  league_level,
  birth_year_min,
  birth_year_max,
  birth_month_min,
  birth_month_max,
  has_note,
  gender,
  shortlist_ids,
  not_in_shortlist_ids,
  currentSeasons,
}) => (
    <Container dNoneXs dFlexMd>
      {shortlisted !== undefined && shortlisted !== null &&
        <FilterButton btnSm btnOutlineReverse onClick={() => onFiltersRemove(['shortlisted'])}>Shortlisted: {shortlisted ? 'Yes' : 'No'} <Icon className="icon-close" /></FilterButton>
      }
      {has_note !== undefined && has_note !== null &&
        <FilterButton btnSm btnOutlineReverse onClick={() => onFiltersRemove(['has_note'])}>Notes: {has_note ? 'Yes' : 'No'} <Icon className="icon-close" /></FilterButton>
      }
      {gender !== undefined && gender !== null &&
        <FilterButton btnSm btnOutlineReverse onClick={() => onFiltersRemove(['gender'])}>Gender: {gender} <Icon className="icon-close" /></FilterButton>
      }
      {primary_foot !== undefined && primary_foot !== null &&
        <FilterButton btnSm btnOutlineReverse onClick={() => onFiltersRemove(['primary_foot'])}>Preferred Foot: {primary_foot} <Icon className="icon-close" /></FilterButton>
      }
      {birthday_max &&
        <FilterButton btnSm btnOutlineReverse onClick={() => onFiltersRemove(['birthday_min', 'birthday_max'])}>Birthday: {birthday_min} to {birthday_max} <Icon className="icon-close" /></FilterButton>
      }
      {(birth_year_min || birth_year_max) &&
        <FilterButton btnSm btnOutlineReverse onClick={() => onFiltersRemove(['birth_year_min', 'birth_year_max'])}>Birth year: {birth_year_min || MIN_BIRTHDAY_YEAR}{birth_year_max && '-'}{birth_year_max || '+'} <Icon className="icon-close" /></FilterButton>
      }
      {(birth_month_min || birth_month_max) &&
        <FilterButton btnSm btnOutlineReverse onClick={() => onFiltersRemove(['birth_month_min', 'birth_month_max'])}>Birth month: {MONTHS[birth_month_min || 0]}{birth_month_max && '-'}{birth_month_max ? MONTHS[birth_month_max] : '+'} <Icon className="icon-close" /></FilterButton>
      }
      {(matches_played_max || matches_played_min) &&
        <FilterButton btnSm btnOutlineReverse onClick={() => onFiltersRemove(['matches_played_min', 'matches_played_max'])}>Matches: {matches_played_min || '0'}{matches_played_max && '-'}{matches_played_max || '+'} <Icon className="icon-close" /></FilterButton>
      }
      {(average_rating_min || average_rating_max) &&
        <FilterButton btnSm btnOutlineReverse onClick={() => onFiltersRemove(['average_rating_min', 'average_rating_max'])}>Rating: {average_rating_min || '0'}{average_rating_max && '-'}{average_rating_max || '+'} <Icon className="icon-close" /></FilterButton>
      }
      {(motm_votes_min || motm_votes_max) &&
        <FilterButton btnSm btnOutlineReverse onClick={() => onFiltersRemove(['motm_votes_min', 'motm_votes_max'])}>Votes: {motm_votes_min || '0'}{motm_votes_max && '-'}{motm_votes_max || '+'} <Icon className="icon-close" /></FilterButton>
      }
      {(team_motm_votes_ratio_max || team_motm_votes_ratio_min) &&
        <FilterButton btnSm btnOutlineReverse onClick={() => onFiltersRemove(['team_motm_votes_ratio_min', 'team_motm_votes_ratio_max'])}>Votes %: {team_motm_votes_ratio_min * 100 || '0'}{team_motm_votes_ratio_max && '-'}{team_motm_votes_ratio_max * 100 || '+'} <Icon className="icon-close" /></FilterButton>
      }
      {(goals_max || goals_min) &&
        <FilterButton btnSm btnOutlineReverse onClick={() => onFiltersRemove(['goals_min', 'goals_max'])}>Goals: {goals_min || '0'}{goals_max && '-'}{goals_max || '+'} <Icon className="icon-close" /></FilterButton>
      }
      {(assists_min || assists_max) &&
        <FilterButton btnSm btnOutlineReverse onClick={() => onFiltersRemove(['assists_min', 'assists_max'])}>Assists: {assists_min || '0'}{assists_max && '-'}{assists_max || '+'} <Icon className="icon-close" /></FilterButton>
      }
      {(clean_sheets_min || clean_sheets_max) &&
        <FilterButton btnSm btnOutlineReverse onClick={() => onFiltersRemove(['clean_sheets_min', 'clean_sheets_max'])}>Clean sheets: {clean_sheets_min || '0'}{clean_sheets_max && '-'}{clean_sheets_max || '+'} <Icon className="icon-close" /></FilterButton>
      }
      {(height_min || height_max) &&
        <FilterButton btnSm btnOutlineReverse onClick={() => onFiltersRemove(['height_min', 'height_max'])}>Height: {height_min || '0'}{height_max && '-'}{height_max || '+'} <Icon className="icon-close" /></FilterButton>
      }
      {league_level && league_level.length !== 0 &&
        <FilterButton btnSm btnOutlineReverse onClick={() => onFiltersRemove(['league_level'])}>League level: {league_level.map((value) => _.find(LEAGUE_LEVEL, { value }).label).join(', ')} <Icon className="icon-close" /></FilterButton>
      }
      {positions && positions.length !== 0 &&
        <FilterButton btnSm btnOutlineReverse onClick={() => onFiltersRemove(['positions'])}>Positions: {positions.map((id) => _.find(POSITIONS, { id }).abbreviation).join(', ')} <Icon className="icon-close" /></FilterButton>
      }
      {seasons && seasons.length !== 0 && currentSeasons && currentSeasons.length !== 0 &&
        <FilterButton btnSm btnOutlineReverse onClick={() => onFiltersRemove(['seasons'])}>Seasons: {seasons.map((number) => _.find(currentSeasons, { number }).name).join(', ')} <Icon className="icon-close" /></FilterButton>
      }
      {shortlist_ids && shortlist_ids.length !== 0 &&
        <FilterButton btnSm btnOutlineReverse onClick={() => onFiltersRemove(['shortlist_ids'])}>In shortlists: {shortlist_ids.map((s) => s.name).join(', ')} <Icon className="icon-close" /></FilterButton>
      }
      {not_in_shortlist_ids && not_in_shortlist_ids.length !== 0 &&
        <FilterButton btnSm btnOutlineReverse onClick={() => onFiltersRemove(['not_in_shortlist_ids'])}>Not in shortlists: {not_in_shortlist_ids.map((s) => s.name).join(', ')} <Icon className="icon-close" /></FilterButton>
      }
      {countries.length !== 0 &&
        <Fragment>
          {countries.map((el, i) => (
            <FilterButton key={`${i}_onCountryClick`} btnSm btnOutlineReverse onClick={() => onCountryClick(el)}>{el.name} <Icon className="icon-close" /></FilterButton>
          ))}
        </Fragment>
      }
      {agegroups.length !== 0 &&
        <Fragment>
          {agegroups.map((el, i) => (
            <FilterButton key={`${i}_onAgegroupClick`} btnSm btnOutlineReverse onClick={() => onAgegroupClick(el)}>{el.name} <Icon className="icon-close" /></FilterButton>
          ))}
        </Fragment>
      }
      {leagues.length !== 0 &&
        <Fragment>
          {leagues.map((el, i) => (
            <FilterButton key={`${i}_onLeagueClick`} btnSm btnOutlineReverse onClick={() => onLeagueClick(el)}>{el.name} <Icon className="icon-close" /></FilterButton>
          ))}
        </Fragment>
      }
    </Container>
  );

FiltersRecap.propTypes = {
  onCountryClick: PropTypes.func.isRequired,
  onAgegroupClick: PropTypes.func.isRequired,
  onLeagueClick: PropTypes.func.isRequired,
  countries: PropTypes.array,
  agegroups: PropTypes.array,
  leagues: PropTypes.array,
};

FiltersRecap.defaultProps = {
  countries: [],
  agegroups: [],
  leagues: [],
  filters: {},
  onFiltersRemove: () => { },
};

export default FiltersRecap;
