import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Step, Segment, Form, Checkbox } from 'semantic-ui-react';
import { FlexboxGrid as Div } from 'baroo';
import Modal from '../Modal';
import { StyledSteps } from '../../assets/themes/styles/components/Steps/Steps';
import { Col } from '../../assets/themes/styles/components/PlayerTable/LayoutModal';

class FilterModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedColumns: [],
      columns: [],
      stepActive: 'bio'
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.modalOpen === this.props.modalOpen) {
      return
    }
    if (this.props.modalOpen) {
      this.initComponent()
    }
  }

  initComponent = () => {
    const columns = [];
    _.forEach(this.props.data.columns, col => {
      columns.push(col)
    })
    this.setState({
      columns,
      selectedColumns: [...this.props.data.currentLayout],
      stepActive: 'bio'
    })
  }

  onSuccess = () => {
    const { selectedColumns } = this.state;
    return this.props.onSuccess({ layout: selectedColumns })
  }

  activeStep = (step) => {
    if (step !== this.state.stepActive) {
      this.setState({ stepActive: step })
    }
  }

  isSelected = (selection, value) => {
    return selection.filter(s => s.key === value.key).length !== 0
  }

  isColumnSelected = (column) => {
    return this.isSelected(this.state.selectedColumns, column)
  }

  getNewSelection = (selection, value) => {
    let nextSelection
    if (this.isSelected(selection, value)) {
      nextSelection = selection.filter(s => s.key !== value.key)
    } else {
      // keep the column order defined by DEFAULT_LAYOUT
      const selectedKeys = selection.map(s => s.key)
      selectedKeys.push(value.key)
      nextSelection = this.state.columns.filter(c => c.alwaysShow || selectedKeys.includes(c.key)).sort((c1, c2) => c1.order - c2.order)
    }
    return nextSelection
  }

  handleColumnsChange = (col) => {
    const nextSelectedColumns = this.getNewSelection(this.state.selectedColumns, col)
    this.setState({
      selectedColumns: nextSelectedColumns
    });
  }

  render() {
    const { modalOpen, handleClose, onFail, dark, size } = this.props;
    const { columns, stepActive } = this.state;

    return (
      <Modal
        modalOpen={modalOpen}
        handleClose={handleClose}
        onSuccess={this.onSuccess}
        onFail={onFail}
        onFailText="Reset"
        title="Table view"
        dark={dark.toString()}
        size={size}
      >
        <Fragment>
          <StyledSteps attached='top' unstackable dark={dark.toString()}>
            <Step active={stepActive === 'bio'} onClick={() => this.activeStep('bio')}>
              <Step.Content>
                <Step.Title>Biography</Step.Title>
              </Step.Content>
            </Step>
            <Step active={stepActive === 'performance'} onClick={() => this.activeStep('performance')}>
              <Step.Content>
                <Step.Title>Performance</Step.Title>
              </Step.Content>
            </Step>
            <Step active={stepActive === 'team'} onClick={() => this.activeStep('team')}>
              <Step.Content>
                <Step.Title>Team</Step.Title>
              </Step.Content>
            </Step>
          </StyledSteps>
          <Segment attached>
            <Segment>
              <Form>
                <Div row noGutters>
                  {columns.filter(col => col.categories.includes(stepActive)).map(col => !col.alwaysShow && (
                    <Col colXs={6} colSm={3} key={col.key}>
                      <Form.Field>
                        <Checkbox
                          label={col.header}
                          name='column'
                          value={col.key}
                          checked={this.isColumnSelected(col)}
                          onChange={() => this.handleColumnsChange(col)}
                        />
                      </Form.Field>
                    </Col>
                  ))}
                </Div>
              </Form>
            </Segment>
          </Segment>
        </Fragment>
      </Modal>
    );
  }
}

FilterModal.propTypes = {
  modalOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFail: PropTypes.func.isRequired,
};

FilterModal.defaultProps = {
  modalOpen: false,
};



export default FilterModal;
