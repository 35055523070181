import { UNSHORTLIST_PLAYER, SHORTLIST_PLAYER, UPDATE_SHORTLIST_PLAYER } from './actionTypes';


export const shortlistPlayer = (playerId) => ({
  type: SHORTLIST_PLAYER,
  payload: { playerId },
});

export const unshortlistPlayer = (playerId) => ({
  type: UNSHORTLIST_PLAYER,
  payload: { playerId },
});

export const updateShortlistPlayer = (playerId, shortlistIds) => ({
  type: UPDATE_SHORTLIST_PLAYER,
  payload: {
    playerId,
    shortlistIds,
  },
});

