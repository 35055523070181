import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button, Dropdown } from 'semantic-ui-react';
import UserAvatar from '../../components/UserAvatar';
import Step from './Step';

class InivtedStep extends Component {
  COLUMNS = [
    {
      accessor: (d) =>
        <div style={{ overflow: 'visible' }}>
          <Button basic onClick={() => this.props.onApprovePlayer(d)}>Invite</Button>
          <Dropdown text='Decline' button basic>
            <Dropdown.Menu>
              {this.props.requiredPermissions.includes('club') && <Dropdown.Item text='Club declined' onClick={() => this.props.onDeclinePlayer(d, 'club_declined')} />}
              {this.props.requiredPermissions.includes('parent') && <Dropdown.Item text='Parent declined' onClick={() => this.props.onDeclinePlayer(d, 'parent_declined')} />}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      , header: 'Invitations', overflowvisible: true
    },
    { accessor: (d) => <UserAvatar user={d} />, header: 'Player' },
    { accessor: (d) => _.isArray(d.clubs) ? d.clubs.join(', ') : d.clubs, header: 'Clubs' },
    { accessor: (d) => d.club_email, header: 'Club email' },
    { accessor: (d) => d.parent_phone_number, header: 'Parent phone' },
    { accessor: (d) => d.parent_name, header: 'Parent name' },
  ];

  render() {
    const { players } = this.props;
    return (
      <Step players={players} columns={this.COLUMNS} light={false} headline="You must reach out to clubs and parents for permissions. It is important that you follow the rules set for your country / association in terms of club and parents permissions." />
    );
  }
}

InivtedStep.propTypes = {
  players: PropTypes.array,
  onApprovePlayer: PropTypes.func.isRequired,
  onDeclinePlayer: PropTypes.func.isRequired,
  requiredPermissions: PropTypes.array.isRequired,
};

InivtedStep.defaultProps = {
  players: [],
};


export default InivtedStep;
