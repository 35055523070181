import React, { Component } from 'react';
import { Redirect } from 'react-router';
import LoginPage from './LoginPage';
import logo from '../assets/images/logoplayerdata_white.png';
import { LogoLoading } from '../components/Styled/';

class LogoutPage extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }
  componentDidMount() {
    window.amplitude.getInstance().setUserId(null);
    window.localStorage.removeItem('token');
    this.setState({ loading: false });
  }
  render() {
    const { loading } = this.state;
    if (loading) {
      return <LogoLoading logo={logo} altText={'TonsserLogo'} height={'65px'} />
    }
    return (
      <Redirect to="/login" />
    );
  }
}

export default LogoutPage;
