import React, { Component } from 'react';
import moment from 'moment';
import { Header, Dimmer } from 'semantic-ui-react';
import { FlexboxGrid as Div } from 'baroo';
import Panel from '../components/Panel';
import {
  PanelStyled,
  CategoryCol,
  Label,
  Description,
} from '../assets/themes/styles/events/EventCardSummary';

const DATE_FORMAT = null;
const DATE_DISPLAY = 'MMM Do, H:mm';

class EventCardSummary extends Component {
  shouldComponentUpdate(nextProps) {
    return !this.props.event || nextProps.event.id !== this.props.event.id;
  }
  render() {
    const { event } = this.props;
    const now = moment();
    const isPast = now.isAfter(moment(event.event_date, DATE_FORMAT));
    // const isSelectionStarted = now.isAfter(moment(event.selection_deadline, DATE_FORMAT));
    return (
      <PanelStyled to={`/events/${event.id}`}>
        <Panel.Header>
          <Header as="h4" icon="calendar check outline" content={event.name} />
        </Panel.Header>
        <Dimmer.Dimmable dimmed={isPast} blurring>

          <Panel.Body>
            <Div row betweenXs noGutters>
              <CategoryCol>
                <Label>Event date</Label>
                <Description>{moment(event.event_date, DATE_FORMAT).format(DATE_DISPLAY)}</Description>
              </CategoryCol>
              <CategoryCol>
                <Label>Application deadline</Label>
                <Description>{moment(event.application_deadline, DATE_FORMAT).format(DATE_DISPLAY)}</Description>
              </CategoryCol>
              <CategoryCol>
                <Label>Selection deadline</Label>
                <Description>{moment(event.selection_deadline, DATE_FORMAT).format(DATE_DISPLAY)}</Description>
              </CategoryCol>
            </Div>
            <Div row noGutters betweenXs>
              <div>{event.applied_count} Applied</div>
              <div>{event.invited_count} Selected</div>
              <div>{event.accepted_count} Invited</div>
              <div>{event.declined_count} Declined</div>
            </Div>
          </Panel.Body>
          <Dimmer inverted active={isPast}>
            <Header as="h2">
              EVENT EXPIRED
            </Header>
          </Dimmer>
        </Dimmer.Dimmable>
      </PanelStyled>


    );
  }
}

export default EventCardSummary;
