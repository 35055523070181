import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import { FlexboxGrid as Div, Themes } from 'baroo';
import { Link } from 'react-router-dom';

const ModalStyled = styled(Modal)`
  max-height: 100%;
  max-width: 100%;
  width: 100% !important;
  height: 100% !important;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #111111!important;
`;
const FixedMediaDiv = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
`;
const MediaContainer = styled.div`
  background-color: #111111;
  width: 100vw;
  position: absolute;
  margin-left: -50vw;
  height: 105vh;
  left: 50%;
  top: 0
  z-index: 999;
  ${(props) => (props.customMarginTop ? `margin-top:${props.customMarginTop}` : '')};
  
`;

const Arrow = styled.div`
  background: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px;
  position: absolute;
  top: 50%;
  user-select: none;
  height: 120px;
  margin-top: -60px;
  width: 40px;
  z-index: 1002;
  ${Themes.Media.sm`
    width: 70px;
  `}
`;

const ArrowLeft = Arrow.extend`
  left: 10px;
`;

const ArrowRight = Arrow.extend`  
  right: 10px;
`;

const Footer = styled.div`
  max-width: ${(props) => props.maxWidth ? `${props.maxWidth}px` : '100%'};
  padding: 6px 0;
`;
const Header = styled.div`
  max-width: ${(props) => props.maxWidth ? `${props.maxWidth}px` : '100%'};
  padding: 6px 0;
  height: 55px;
`;

const Content = styled.div`
  color: #eee;
`;
const Label = styled.div`
  color: #fff;
`;

const Media = styled.div`
  text-align: center;
  position: relative;
  width: fit-content;
  > img {
    max-height: calc(85vh - 120px);
    max-width: 100%;
  }
  > .video-react {
    position: absolute;
    height: 100% !important;
    top: 0;
    font-family: 'Open Sans';
  }
`;
const MediaWrapper = styled.div`
  flex: 100%;
  display: flex;
  justify-content: center;
  border-radius: 8px;
`;

const MediaImage = styled.img``;

const Position = styled.div`
  height: 36px;
  width: 36px;
  border: solid 1px #242c39;
  display: flex;
  flex-direction: ${(props) => (props.vertical ? 'row' : 'column')};
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-radius: 50%;
  cursor: pointer;
  background: ${(props) => (props.checked ? '#242c39' : '#f0f2f3')};
  color: ${(props) => (props.checked ? 'white' : 'black')};
  &:hover{
    opacity: 0.7;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  padding-left: 65px;
  padding-right: 65px;
`;

const FlexDiv = styled.div``;

const ScoreHeadingWrapper = styled.div`
  text-align: center;
  max-height: 90px;
  margin-bottom: 15px;
`;

const ScoreHeading = styled.div`
  display: flex;
  justify-content: center;
  align-tems: center;
`;

const HomeTeamDiv = styled(Div)`
  display: flex;
  max-width: 260px;
  align-items: center;
  justify-content: flex-end;
`;

const AwayTeamDiv = styled(Div)`
  display: flex;
  max-width: 260px;
  align-items: center;
  justify-content: flex-start;
`;

const TeamName = styled.span`
  font-family: Helvetica;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff;
  ${(props) => (props.textAlign ? `text-align:${props.textAlign}` : '')};
  ${(props) => (props.marginRight ? `margin-right:${props.marginRight}` : '')};
  ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}` : '')};
`;
const TeamImage = styled.img`
  width: 45px; 
  height: 45px;
  border-radius: 50px;
`;

const TeamColour = styled.div`    
  width: 20px;
  height: 20px;
  border-radius: 50%;
  ${(props) => (props.teamColor ? `background-color:${props.teamColor}` : '')};
  ${(props) => (props.marginRight ? `margin-right:${props.marginRight}` : '')};
  ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}` : '')};
`;

const MatchScoreDate = styled(Div)`
  font-size: 12px;
  height: 17px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
`;
const MatchScoreDigits = styled(Div)`
  height: 48px;
  font-family: Helvetica;
  font-size: 40px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
`;
const MatchScore = styled(Div)`
  margin-bottom: 15px;
  min-width: 120px;
`;

const Close = styled(Div)`
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 30px;
  cursor: pointer;
  color: #ffffff;
  z-index: 9999;
`;

const TeamContainer = styled(Div)``;
const Team = styled.div`
  line-height: 1.5;
`;

const PlayerRow = styled.div`
  display: flex;
  padding-top: 2px;
  padding-bottom: 2px;
`;

const PlayerRowItem = styled.div`
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: ${(props) => props.textAlign};
  flex: ${(props) => props.flex};
  ${(props) => (props.fontWeight ? `font-weight: ${props.fontWeight}` : ``)};
  ${(props) => (props.paddingRight ? `padding-right: ${props.paddingRight}` : ``)};
`;

const TeamSubstitutionsHeader = styled(Div)`
  margin-top: 10px; 
  margin-bottom: 10px;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
`;

const TeamHeader = styled(Div)`
  display: flex; 
  min-height: 40px;
  margin-bottom: 5px;
  height: 50px;
  max-height: 60px;
  align-items: center;
  max-width: 220px;
`;

const TeamHeaderLogo = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50px;
  margin-right: 10px;
`;
const TeamHeaderName = styled(Div)`
  height: 17px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
`;

const LineupHeading = styled(Div)`
  font-family: Helvetica;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
`;

const LinkStyled = styled(Link)`
  color: #ffffff;
  display: flex;
  padding-top: 2px;
  padding-bottom: 2px;
  &:hover {
    text-decoration: none;
    color: #00ff9a;
  }
`;
const PlayerStyled = styled(Div)`
  color: #ffffff;
  display: flex;
  cursor: pointer;
  align-items: baseline;
  ${props => props.clickable && `
    &:hover {
      text-decoration: none;
      color: #00ff9a;
    }
  `}
`;



const LinupIconContainer = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
  background: rgba(43, 51, 63, 0.9);
  width: 65px;
  height: 65px;
  display: flex;
  border-bottom-left-radius: 50%;
  font-weight: 600;
  padding: 5px;
  :hover {
    color: #00ff9a;
    background: black;
  }
`;
const LinupIcon = styled.span`
  width: 100%;
  font-family: Helvetica;
  display: flex;
  align-items: center;
  justify-content: center;
  
`;

export {
  ModalStyled,
  Arrow,
  ArrowLeft,
  ArrowRight,
  Footer,
  Content,
  Label,
  FixedMediaDiv,
  MediaWrapper,
  Media,
  MediaImage,
  Position,
  FlexContainer,
  FlexDiv,
  Close,
  Header,
  PlayerRow,
  PlayerRowItem,
  TeamSubstitutionsHeader,
  TeamHeader,
  TeamHeaderLogo,
  TeamHeaderName,
  LineupHeading,
  ScoreHeadingWrapper,
  ScoreHeading,
  HomeTeamDiv,
  AwayTeamDiv,
  MatchScore,
  TeamName,
  TeamColour,
  TeamImage,
  MatchScoreDate,
  MatchScoreDigits,
  LinkStyled,
  TeamContainer,
  Team,
  PlayerStyled,
  MediaContainer,
  LinupIconContainer,
  LinupIcon
};