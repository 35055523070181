import React, { Component } from 'react';
import { Popup } from 'semantic-ui-react';
import _ from 'lodash';

class TableCells extends Component {
  constructor(props) {
    super(props);
    this.state = { showPopup: false };
  }
  componentDidMount() {
    if (this.cell) {
      const width = this.cell.offsetWidth;
      this.setState({ showPopup: this.cell.scrollWidth > width });
    }
  }


  render() {
    const { children, ...etc } = this.props;
    const { showPopup } = this.state;
    if (_.isString(children) && showPopup) {
      return (<Popup inverted trigger={<td ref={(el) => this.cell = el} {...etc}>{children}</td>} content={children} />);
    }
    return (
      <td ref={(el) => this.cell = el} {...etc}>{children}</td>
    );
  }

}

export default TableCells;
