import React, { Component, Fragment } from 'react';
import { TransitionablePortal, Message, Header as TextHeader } from 'semantic-ui-react';
import { FlexboxGrid as Div } from 'baroo';
import Header from '../components/Header';
import Api from '../services/api';
import { Loading } from '../components/Styled';
import EventCardSummary from './EventCardSummary';
import {
  Container,
  MessageStyled,
  Col,
  NoEvent,
} from '../assets/themes/styles/events/EventsListPage';

class EventsListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: null,
      error: null,
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    Api.getEvents()
      .then((data) => {
        if (data.response) {
          this.setState({
            loading: false,
            events: data.response,
          });
          window.amplitude.getInstance().logEvent('Events viewed', { event_count: data.response.length });
        } else {
          this.setState({
            loading: false,
            error: 'No events found',
          });
        }
      }).catch(() => {
        this.setState({
          loading: false,
          error: 'No events found',
        });
      });
  }

  onEventClick = (event) => {
    console.log('Event clicked', event)
  }

  render() {
    const { events, error, loading } = this.state;
    return (
      <Fragment>
        <Header user={this.props.user} />
        {loading && <Loading text="Loading events..." />}

        <Container container>
          <TextHeader as="h2" content="Events" />

          {!loading && !!events && events.length !== 0 &&
            <Div row>
              {events.map((event) =>
                (<Col colXs={12} colSm={6} key={event.id}>
                  <EventCardSummary event={event} onClick={() => this.onEventClick(event)} />
                </Col>))}
            </Div>


          }
          {!loading && !!events && events.length === 0 && <NoEvent>No event have been created</NoEvent>}
          {error &&
            <TransitionablePortal open={!!error}
              transition={{
                animation: 'fade',
                duration: 300,
              }}
            >
              <MessageStyled negative
                style={{
                  left: '40%',
                  position: 'fixed',
                  top: '50%',
                  zIndex: 1000,
                }}
              >
                <Message.Header>Acces denied</Message.Header>
              </MessageStyled>

            </TransitionablePortal>
          }
        </Container>
      </Fragment>
    );
  }
}

export default EventsListPage;
