import React, { Component } from 'react';
import Hls from 'hls.js';

export default class HLSSource extends Component {
  constructor(props, context) {
    super(props, context);
    this.hls = new Hls({
      startLevel: window.safari !== undefined ? undefined : -1,
      enableWorker: false,
      debug: false,
    });
  }

  destroyVideo = () => {
    if (this.hls) {
      this.hls.destroy();
    }
  }
  loadAndPlayVideo = async (src, video) => {
    if (Hls.isSupported()) {
      this.hls = new Hls({
        startLevel: window.safari !== undefined ? undefined : -1,
        enableWorker: false,
        debug: false
      });
      if (this.hls) {
        this.hls.loadSource(src);
        this.hls.attachMedia(video);
        this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
          video.play();
        });
      }
    }
  }
  async componentDidMount() {
    // `src` is the property get from this component
    // `video` is the property insert from `Video` component
    // `video` is the html5 video element
    // load hls video source base on hls.js
    const { src, video } = this.props;
    await this.loadAndPlayVideo(src, video);
  }
  async componentDidUpdate(prevProps) {
    if (this.props.src !== prevProps.src) {
      await this.destroyVideo();
      await this.loadAndPlayVideo(this.props.src, this.props.video);
    }
    if (this.props.pausePlayer !== prevProps.pausePlayer) {
      if (this.props.pausePlayer) {
        this.props.video.pause();
      }
    }
  }

  async componentWillUnmount() {
    // destroy hls video source
    await this.destroyVideo()
  }

  render() {
    return (
      <source
        src={this.props.src}
        type={this.props.type || 'application/x-mpegURL'}
      />
    );
  }
}
