import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ScrollSync, ScrollSyncPane } from '../ReactScrollSync';
import _ from 'lodash';
import TableRows from './TableRows';

import {
  TableStyled,
  LightTableStyled,
  LeftPane,
  TopLeftPane,
  RightPane,
  TopRightPane,
  CenterPane,
  TopCenterPane,
  Grid,
  Cell,
  CellHeader,
  SortIcon,
} from '../../assets/themes/styles/components/DataTable/DataTable';

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = { hoveredRowIndex: null };
    this.rightPane = null
    this.tableRows = []

    this.refreshTable = () => {
      // update only table rows
      // ok for now
      this.tableRows.forEach(el => {
        if (el) {
          el.forceUpdate()
        }
      });
    }

    this.forceSync = () => {
      if (this.scollSync) {
        this.scollSync.syncScrollPositions(this.topCenterScrollSync.node)
      }
    }

  }

  getCellContent = ({ col, d }) => {
    if (_.isFunction(col.accessor)) {
      return col.accessor(d)
    } else {
      return d[col.accessor]
    }
  }

  cellRenderer = ({ col, d, rowIndex }) => {
    // -1 offset because first row is the header
    const content = this.getCellContent({ col, d })
    return (<Cell nowrap="true" width={col.width} key={`cell-${rowIndex}-${col.key}`} rowheight={this.props.rowheight} overflowvisible={col.overflowvisible}>{content}</Cell>)
  }


  renderHeader = (col) => {
    const { onSort, sortKey, sortDirection } = this.props;
    const content = col.header;
    const isColSorted = sortKey && sortKey === col.key
    return (
      <CellHeader
        active={isColSorted}
        width={col.width}
        key={col.key}
        sortable={col.sortable}
        onClick={() => { col.sortable && onSort(col) }}>
        {content}
        {col.sortable &&
          <SortIcon active={isColSorted} className={`icon-caret-${isColSorted && sortDirection === 'asc' ? 'up' : 'down'}`} />
        }

      </CellHeader>
    )
  }

  onScroll = (event) => {
    // const { hasNextPage, isNextPageLoading, loadNextPage } = this.props;
    // if (event.scrollHeight && event.scrollTop && event.clientHeight && hasNextPage && !isNextPageLoading) {
    //   const pixelLeft = event.scrollHeight - event.scrollTop - event.clientHeight;
    //   if (pixelLeft < 100) {
    //     loadNextPage()
    //   }
    // }
  }

  onWheel = ({ deltaY }) => {
    // this.props.onScroll(deltaY > 0 ? 'down' : 'up')
  }

  onTouchStart = (event) => {
    if (event && event.touches && event.touches[0]) {
      this.startTouchY = event.touches[0].clientY;
    }
  }

  onTouchMove = event => {
    if (event && event.changedTouches && event.changedTouches[0]) {
      const clientY = event.changedTouches[0].clientY
      const percentageChanged = (clientY - this.startTouchY) / this.startTouchY // prevent for triggering onScroll when touch horizontal

      if (percentageChanged > 0.05) {
        this.props.onScroll('up')
      } else if (percentageChanged < -0.05) {
        this.props.onScroll('down')
      }
    }
  }

  resetHighlight = () => {
    this.setState({ hoveredRowIndex: null })
  }

  setHighlight = (row) => {
    this.setState({ hoveredRowIndex: row })
  }
  shouldComponentUpdate(nextProps, nextState) {
    const { data, rowheight, sortDirection, sortKey, columns } = this.props;
    const { hoveredRowIndex } = this.state;
    if (nextProps.data.length !== data.length) {
      return true
    }
    if (nextState.hoveredRowIndex !== hoveredRowIndex) {
      return true
    }
    if (nextProps.sortDirection !== sortDirection) {
      return true;
    }
    if (nextProps.sortKey !== sortKey) {
      return true;
    }
    if (nextProps.columns.length !== columns.length) {
      return true
    }
    if (nextProps.rowheight !== rowheight) {
      return true;
    }
    if ((new Set([...nextProps.columns.map(c => c.key), ...columns.map(c => c.key)])).size !== columns.length) {
      return true
    }
    return false;
  }
  render() {
    if (this.props.light) {
      return this.renderLight()
    }
    return this.renderComplex()
  }
  renderLight() {
    const { columns, data, rowheight, onRowClick, ...etc } = this.props;
    return (
      <LightTableStyled {...etc}>
        <thead>
          <tr>{columns.map(this.renderHeader)}</tr>
        </thead>
        <tbody>
          {data.length === 0 && <tr></tr>}
          <TableRows
            ref={el => this.tableRows.push(el)}
            rows={data}
            columns={columns}
            cellRenderer={this.cellRenderer}
            onRowClick={onRowClick}
          />
        </tbody>
      </LightTableStyled>
    )

  }
  renderComplex() {
    const { columns, data, fixedColumnCount, fixedRightColumnCount, onRowClick } = this.props;
    const { hoveredRowIndex } = this.state;

    const fixedColumns = columns.filter((c, i) => i < fixedColumnCount)
    const fixedRightColumns = columns.filter((c, i) => i >= columns.length - fixedRightColumnCount)


    const notFixedColumns = columns.filter((c, i) => i >= fixedColumnCount && i < columns.length - fixedRightColumnCount)
    if (fixedRightColumnCount) {
      notFixedColumns.push({ width: 10, key: 'push', accessor: '', header: '' })
    }
    // const notFixedColumnsWidth = notFixedColumns.reduce((acc, val) => acc + val.width, 0);
    const fixedColumnsWidth = fixedColumns.reduce((acc, val) => acc + val.width, 0);
    const fixedRightColumnsWidth = fixedRightColumns.reduce((acc, val) => acc + val.width, 0);
    return (
      <ScrollSync onScroll={this.onScroll} ref={e => this.scollSync = e}>
        <div style={{ height: '100%', display: 'flex', position: 'relative' }} onMouseLeave={this.resetHighlight}>
          {fixedColumnCount !== 0 &&
            <Grid>
              <TopLeftPane width={fixedColumnsWidth}>
                <TableStyled>
                  <thead>
                    <tr>{fixedColumns.map(this.renderHeader)}</tr>
                  </thead>
                </TableStyled>
              </TopLeftPane>
              <ScrollSyncPane>
                <LeftPane width={fixedColumnsWidth} onWheel={this.onWheel} onTouchMove={this.onTouchMove} onTouchStart={this.onTouchStart}>
                  <TableStyled hoveredRowIndex={hoveredRowIndex}>
                    <tbody>
                      <TableRows
                        ref={el => this.tableRows.push(el)}
                        rows={data}
                        columns={fixedColumns}
                        onRowHover={this.setHighlight}
                        onRowClick={onRowClick}
                        cellRenderer={this.cellRenderer}
                      />
                    </tbody>
                  </TableStyled>
                </LeftPane>
              </ScrollSyncPane>
            </Grid>
          }
          <Grid>
            <ScrollSyncPane ref={e => this.topCenterScrollSync = e}>
              <TopCenterPane hasLeftPane={fixedColumnCount !== 0} hasRightPane={fixedRightColumnCount !== 0} width={fixedColumnsWidth + fixedRightColumnsWidth} >
                <TableStyled>
                  <thead>
                    <tr>{notFixedColumns.map(this.renderHeader)}</tr>
                  </thead>
                </TableStyled>
              </TopCenterPane>
            </ScrollSyncPane>

            <ScrollSyncPane>

              <CenterPane
                hasLeftPane={fixedColumnCount !== 0}
                hasRightPane={fixedRightColumnCount !== 0}
                onWheel={this.onWheel}
                onTouchMove={this.onTouchMove}
                onTouchStart={this.onTouchStart}
                width={fixedColumnsWidth + fixedRightColumnsWidth}>
                <TableStyled hoveredRowIndex={hoveredRowIndex} style={{ minHeight: '1px' }}>
                  <tbody>
                    {data.length === 0 && <tr></tr>}
                    <TableRows
                      ref={el => this.tableRows.push(el)}
                      rows={data}
                      columns={notFixedColumns}
                      onRowHover={this.setHighlight}
                      cellRenderer={this.cellRenderer}
                      onRowClick={onRowClick}
                    />
                  </tbody>
                </TableStyled>
              </CenterPane>
            </ScrollSyncPane>
          </Grid>
          {fixedRightColumnCount !== 0 &&
            <Grid>
              <TopRightPane width={fixedRightColumnsWidth}>
                <TableStyled>
                  <thead>
                    <tr>{fixedRightColumns.map(this.renderHeader)}</tr>
                  </thead>
                </TableStyled>
              </TopRightPane>
              <ScrollSyncPane>
                <RightPane width={fixedRightColumnsWidth} onWheel={this.onWheel} onTouchMove={this.onTouchMove} onTouchStart={this.onTouchStart}>
                  <TableStyled hoveredRowIndex={hoveredRowIndex}>
                    <tbody>
                      <TableRows
                        ref={el => this.tableRows.push(el)}
                        rows={data}
                        columns={fixedRightColumns}
                        onRowHover={this.setHighlight}
                        onRowClick={onRowClick}
                        cellRenderer={this.cellRenderer}
                      />
                    </tbody>
                  </TableStyled>
                </RightPane>
              </ScrollSyncPane>
            </Grid>
          }
        </div>
      </ScrollSync>



    )
  }
}

DataTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  rowheight: PropTypes.number,
  fixedColumnCount: PropTypes.number,
  loadNextPage: PropTypes.func,
  hasNextPage: PropTypes.bool,
  isNextPageLoading: PropTypes.bool,
  light: PropTypes.bool,
  onSort: PropTypes.func,
  sortDirection: PropTypes.string,
  sortKey: PropTypes.string,
  onScroll: PropTypes.func,
};

DataTable.defaultProps = {
  data: [],
  columns: [],
  rowheight: 50,
  fixedColumnCount: 2,
  fixedRightColumnCount: 0,
  loadNextPage: () => { },
  onSort: () => { },
  onScroll: () => { },
  refreshTable: () => { },
  hasNextPage: false,
  isNextPageLoading: false,
  light: false,
  sortDirection: null,
  sortKey: null
};
export default DataTable;
