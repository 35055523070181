import React, { Component, Fragment } from 'react';
import Header from '../components/Header';
import PlayerProfileContainer from './PlayerProfileContainer';
import {
  Container
} from '../assets/themes/styles/playerProfile/PlayerProfileStandalonePage';

class PlayerProfileStandalonePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      player: null,
      error: null,
    };
  }
  render() {
    console.log('Using Standalone Profile');
    return (
      <Fragment>
        <Header user={this.props.user} />
        <Container>
          <PlayerProfileContainer playerId={this.props.match.params.id} standalone />
        </Container>
      </Fragment>
    );
  }
}

export default PlayerProfileStandalonePage;
