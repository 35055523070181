import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import hoistNonReactStatic from 'hoist-non-react-statics';
import logo from '../assets/images/logoplayerdata_white.png';
import { LogoLoading } from '../components/Styled/';
import Api from '../services/api';
import config from '../config';

export default (WrappedComponent, { redirect = true, role = ['admin', 'user'], } = {}) => {

  class IsLoggedHOC extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        error: null,
        user: null,
        socialMediaLoaded: false,
      };
    }

    async componentDidMount() {
      const token = window.localStorage.getItem('token');
      if (!token) {
        this.setState({
          loading: false,
          error: 'You must login first',
        });
        return;
      }
      if (this.props.user) {
        this.setState({
          user: this.props.user,
          loading: false,
        });
      } else {
        try {
          const data = await Api.me();
          const user = data.response;
          if (user.access_to_discover) {
            const [googleSessionToken, facebookSessionToken] = await Promise.all([this.getGoogleSessionToken(), this.getFacebookSessionToken()])
            if (googleSessionToken || facebookSessionToken) {
              try {
                await this.initAWS(googleSessionToken || facebookSessionToken);
                this.userIsLogged(user)
              } catch (error) {
                this.setState({
                  loading: false,
                  error: 'Cannot login to AWS'
                })
                console.log('Cannot login to AWS', error)
              }
            } else {
              this.setState({
                loading: false,
                error: 'Session expired'
              })
              console.log('social session expired')
            }
          } else {
            this.setState({
              loading: false,
              error: 'No access',
            });
            console.log('You dont have access to tonsser discover')
          }
        } catch (e) {
          console.log('error when login', e)
          this.setState({
            loading: false,
            error: e || true,
          });
        }
      }
    }


    getGoogleSessionToken = () => {
      return new Promise(resolve => {
        let retry = 20;
        const checkSession = () => {
          if (retry <= 0) {
            return resolve(false)
          } else if (window.auth2 && window.auth2.currentUser) {
            const { id_token } = window.auth2.currentUser.get().getAuthResponse();
            if (id_token) {
              resolve({ 'accounts.google.com': id_token });
            } else {
              resolve(false)
            }
          } else {
            retry--;
            setTimeout(checkSession, 100)
          }
        }

        checkSession();
      })
    }

    getFacebookSessionToken = () => {
      return new Promise(resolve => {
        let retry = 20;
        const checkSession = () => {
          if (retry <= 0) {
            return resolve(false)
          } else if (window.FB && window.FB.login) {
            window.FB.getLoginStatus(({ status, authResponse }) => {
              retry = 0;
              if (status === 'connected' && authResponse.accessToken) {
                resolve({ 'graph.facebook.com': authResponse.accessToken })
              } else {
                resolve(false)
              }
            });
          }
          retry--;
          setTimeout(checkSession, 100)
        }
        checkSession();
      })
    }

    userIsLogged = (user) => {
      window.amplitude.getInstance().setUserId(user.slug);
      this.props.setUser(user);
    }

    initAWS = (credentials) => {
      return new Promise((resolve, reject) => {
        window.AWS.config.update({
          region: config.AWS.region,
          credentials: new window.AWS.CognitoIdentityCredentials({
            IdentityPoolId: config.AWS.IdentityPoolId,
            Logins: credentials
          })
        });
        window.AWS.config.credentials.get((err) => {
          if (err) {
            reject(err)
          } else {
            resolve()
          }
        });
      })

    }
    render() {
      const { error, loading, user } = this.state;
      if (!redirect) {
        // No role for now
        // let authorizedUser = null;
        // if (user && role.indexOf(user.role) !== -1) {
        //   authorizedUser = user;
        // }
        return <WrappedComponent user={user} loading={loading} {...this.props} />;
      }
      if (loading) {
        return <LogoLoading logo={logo} altText={'TonsserLogo'} height={'65px'} />
      }
      if (error || !user) {
        return <Redirect to={{ pathname: '/login' }} />;
      }
      // if (user && role.indexOf(user.role) === -1) {
      //   return <Redirect to={{ pathname: '/login' }} />;
      // }
      return <WrappedComponent user={user} loading={loading} {...this.props} />;
    }
  }
  hoistNonReactStatic(IsLoggedHOC, WrappedComponent);

  return IsLoggedHOC;
};
