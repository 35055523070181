import React from 'react';
import { FlexboxGrid as Div, Button } from 'baroo';
import { TextCenter, VerticalDivider } from '../assets/themes/styles/components/Styled/Styled';
import { ContainerStyled } from '../assets/themes/styles/events/InviteFooter';

export default ({ selected, limit, onInviteClick, disabled, message }) => (
  <ContainerStyled row betweenXs noGutters middleXs>
    <Div colXs><TextCenter>{message}</TextCenter></Div>
    <div>
      <Div row betweenXs middleXs noGutters>
        {limit && <div><strong>{selected} / {limit}</strong></div>}
        <VerticalDivider />
        <Button btnOutlineReverse onClick={!disabled && onInviteClick} disabled={disabled}>
          {(limit || selected) ? 'Select' : 'Invite all'}
        </Button>
      </Div>
    </div>
  </ContainerStyled>
);