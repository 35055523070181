import { create, all } from 'mathjs'
import Api from './api';
const config = {
  number: 'BigNumber',
  precision: 64,
};
const math = create(all, config)

export function normalizeStr(str) {
  return str.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export const POSITIONS = [
  {
    id: 1,
    abbreviation: 'GK',
    name: 'goalkeeper',
  },
  {
    id: 2,
    abbreviation: 'LB',
    name: 'left back',
  },
  {
    id: 3,
    abbreviation: 'CB',
    name: 'center back',
  },
  {
    id: 4,
    abbreviation: 'RB',
    name: 'right back',
  },
  {
    id: 5,
    abbreviation: 'LWB',
    name: 'left wing-back',
  },
  {
    id: 6,
    abbreviation: 'RWB',
    name: 'right wing-back',
  },
  {
    id: 7,
    abbreviation: 'DM',
    name: 'defensive mifield',
  },
  {
    id: 8,
    abbreviation: 'CM',
    name: 'central midfield',
  },
  {
    id: 9,
    abbreviation: 'LM',
    name: 'left midfield',
  },
  {
    id: 10,
    abbreviation: 'RM',
    name: 'right midfield',
  },
  {
    id: 11,
    abbreviation: 'AM',
    name: 'attackign midfield',
  },
  {
    id: 12,
    abbreviation: 'LW',
    name: 'left wing',
  },
  {
    id: 13,
    abbreviation: 'RW',
    name: 'right wing',
  },
  {
    id: 14,
    abbreviation: 'ST',
    name: 'striker',
  },
];


export const MIN_MATCHES = 0;
export const MAX_MATCHES = 50;

export const SEASONS = [
  {
    number: 4038,
    name: 'Spring 2019',
  },
  {
    number: 4037,
    name: 'Autumn 2018',
  },
  {
    number: 4036,
    name: 'Spring 2018',
  },
  {
    number: 4035,
    name: 'Autumn 2017',
  },
  {
    number: 4034,
    name: 'Spring 2017',
  },
];

export const MAX_SEASONS = 2;

export const MAX_HEIGHT = 210;
export const MIN_HEIGHT = 100;

export const MIN_RATING = 0;
export const MAX_RATING = 10;

export const MIN_VOTES = 0;
export const MAX_VOTES = 100;

export const MIN_VOTES_RATIO = 0;
export const MAX_VOTES_RATIO = 1;

export const MIN_GOALS = 0;
export const MAX_GOALS = 50;

export const MIN_ASSISTS = 0;
export const MAX_ASSISTS = 50;

export const MIN_CLEAN_SHEETS = 0;
export const MAX_CLEAN_SHEETS = 50;

export const MIN_BIRTHDAY_YEAR = 1990;
export const MAX_BIRTHDAY_YEAR = 2020;

export const LEAGUE_LEVEL = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: -1,
    label: '4 and below',
  },
];

export const MONTHS = ['0', 'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

/**
 * @param {string} str 
 * @returns Full string with every first char of the word uppercased
 */
export function titleCaseEveryWordInString(str, capatilizeAll = true) {
  if (typeof str === 'string' && str !== undefined) {
    let splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      if (capatilizeAll) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      else if (!capatilizeAll) {
        if (i === 0) {
          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        } else {
          splitStr[i] = splitStr[i];
        }
      }
    }
    return splitStr.join(' ');
  }
  return '-';
}

const heightdData = [
  {
    "4": [-0.087235, 1.23812, 0.50286, -10.2567],
    "4.5": [-0.074454, 1.15964, 0.52887, -10.719],
    "5": [-0.064778, 1.10674, 0.53919, -11.0213],
    "5.5": [-0.05776, 1.0748, 0.53691, -11.1556],
    "6": [-0.052947, 1.05923, 0.52513, -11.1138],
    "6.5": [-0.049892, 1.05542, 0.50692, -11.0221],
    "7": [-0.048144, 1.05877, 0.48538, -10.9984],
    "7.5": [-0.047256, 1.06467, 0.46361, -11.0214],
    "8": [-0.046778, 1.06853, 0.44469, -11.0696],
    "8.5": [-0.046261, 1.06572, 0.43171, -11.122],
    "9": [-0.045254, 1.05166, 0.42776, -11.1571],
    "9.5": [-0.043311, 1.02174, 0.43593, -11.1405],
    "10": [-0.039981, 0.97135, 0.45932, -11.038],
    "10.5": [-0.034814, 0.89589, 0.50101, -10.8286],
    "11": [-0.02905, 0.81239, 0.54781, -10.4917],
    "11.5": [-0.024167, 0.74134, 0.58409, -10.0065],
    "12": [-0.020076, 0.68325, 0.60927, -9.3522],
    "12.5": [-0.016681, 0.63869, 0.62279, -8.6055],
    "13": [-0.013895, 0.60818, 0.62407, -7.8632],
    "13.5": [-0.011624, 0.59228, 0.61253, -7.1348],
    "14": [-0.009776, 0.59151, 0.58762, -6.4299],
    "14.5": [-0.008261, 0.60643, 0.54875, -5.7578],
    "15": [-0.006988, 0.63757, 0.49536, -5.1282],
    "15.5": [-0.005863, 0.68548, 0.42687, -4.5092],
    "16": [-0.004795, 0.75069, 0.34271, -3.9292],
    "16.5": [-0.003695, 0.83375, 0.24231, -3.4873],
    "17": [-0.00247, 0.9352, 0.1251, -3.283],
    "17.5": [-0.001027, 1.05558, -0.0095, -3.4156]
  },
  {
    "4": [-0.19435, 1.24768, 0.44774, -8.1325],
    "4.5": [-0.18519, 1.22177, 0.41381, -6.47656],
    "5": [-0.1753, 1.19932, 0.38467, -5.13583],
    "5.5": [-0.16484, 1.1788, 0.36039, -4.13791],
    "6": [-0.154, 1.15866, 0.34105, -3.51039],
    "6.5": [-0.14294, 1.13737, 0.32672, -3.14322],
    "7": [-0.13184, 1.11342, 0.31748, -2.87645],
    "7.5": [-0.12086, 1.08525, 0.3134, -2.66291],
    "8": [-0.11019, 1.05135, 0.31457, -2.45559],
    "8.5": [-0.09999, 1.01018, 0.32105, -2.20728],
    "9": [-0.09044, 0.9602, 0.33291, -1.87098],
    "9.5": [-0.08171, 0.89989, 0.35025, -1.0633],
    "10": [-0.07397, 0.82771, 0.37312, 0.33468],
    "10.5": [-0.06739, 0.74213, 0.40161, 1.97366],
    "11": [-0.06136, 0.67173, 0.42042, 3.50436],
    "11.5": [-0.05518, 0.6415, 0.41686, 4.57747],
    "12": [-0.04894, 0.64452, 0.3949, 4.84365],
    "12.5": [-0.04272, 0.67386, 0.3585, 4.27869],
    "13": [-0.03661, 0.7226, 0.31163, 3.21417],
    "13.5": [-0.03067, 0.78383, 0.25826, 1.83456],
    "14": [-0.025, 0.85062, 0.20235, 0.32425],
    "14.5": [-0.01967, 0.91605, 0.14787, -1.13224],
    "15": [-0.01477, 0.97319, 0.0988, -2.35055],
    "15.5": [-0.01037, 1.01514, 0.05909, -3.10326],
    "16": [-0.00655, 1.03496, 0.03272, -3.17885],
    "16.5": [-0.0034, 1.02573, 0.02364, -2.41657],
    "17": [-0.001, 0.98054, 0.03584, -0.65579],
    "17.5": [0.00057, 0.89246, 0.07327, 2.26429]
  }
];

export function calculateHeight(gender, age, height, weight, mHeight, fHeight) {
  const showDebug = false;
  // Return current set height if age is greater that 17.5 years old
  if (parseFloat(age) > 17.5) {
    return height;
  }
  let genderType = null;
  // If the gender is a string continue
  if (typeof gender === 'string') {
    // Check if male then sexType is 0
    if (gender.toLowerCase() === "male") {
      genderType = 0;
    }
    // Check if female then sexType is 1
    if (gender.toLowerCase() === "female") {
      genderType = 1;
    }
  }

  // Return null if genderType is null
  if (genderType === null || !age) {
    return null;
  }

  age = parseFloat(age);
  // Convert height (cm) to inches
  height = math.unit(height, 'cm').to('inch').toNumber();
  // Convert height (kilogram) to lbs
  weight = math.unit(weight, 'kilogram').to('lbs').toNumber();
  // Convert mHeight (cm) to inches
  mHeight = math.unit(mHeight, 'cm').to('inch').toNumber();
  // Convert fHeight (cm) to inches
  fHeight = math.unit(fHeight, 'cm').to('inch').toNumber();

  // Get the genders age specific statistical array
  const row = heightdData[genderType][age];
  // Calculate the result height based on khamis_height formula
  let resultHeight = row[0] * weight + row[1] * height + (row[2] * (mHeight + fHeight)) / 2 + row[3];
  // Convert inches back to cm
  resultHeight = math.unit(resultHeight, 'inch').to('cm').toNumber();
  // Round resultHeight to 1 decimal place
  resultHeight = Math.round(resultHeight * 10) / 10;
  // Get the remainder of result height e.g 0.60005 or 0.73232 or 0.31122
  let remainder = (resultHeight % 1);
  // Round remainder to 1 decimal
  remainder = Math.round(remainder * 10) / 10;
  showDebug && console.log('remainder ', remainder);
  // Check if the remainder is greater than 0.5 then Math.ceil() else Math.floor() the value
  if (remainder > 0.5) {
    showDebug && console.log('Greater than 0.5 --> ', Math.ceil(resultHeight));
    return Math.ceil(resultHeight);
  } else if (remainder < 0.5) {
    showDebug && console.log('Less than 0.5 --> ', Math.floor(resultHeight));
    return Math.floor(resultHeight);
  }
  showDebug && console.log('Return resultHeight --> ', resultHeight);
  // Return normal resultHeight if the value contains .5 decimal or no decimal at all
  return resultHeight;
};

export async function getSeasonsFromApi() {
  const seasons = await Api.getSeasons();
  if (seasons && seasons.response) {
    return seasons.response.map((season) => {
      return {
        name: titleCaseEveryWordInString(season.name),
        number: season.number
      }
    })
  }
  return [];
}

// Check if the item is Null or Undefined
export function isNullOrUndefined(item) {
  return typeof item === "undefined" || item === null;
}

// Check if the item is Null, Undefined or empty string
export function isNullOrWhitespace(item) {
  if (isNullOrUndefined(item) || typeof item !== 'string') {
    return true;
  } else {
    return item.replace(/^\s+|\s+$/g, '').length === 0;
  }
}

// Check if the item is Null, Undefined or empty array/object
export function isNullOrEmpty(item) {
  if (isNullOrUndefined(item) || typeof item !== 'object') {
    return true;
  } else {
    if (Array.isArray(item)) {
      return item.length === 0;
    } else {
      return Object.keys(item).length === 0;
    }
  }
}

// Safe function to test that the desired object or array properties path exists
export function safe(safeFn, response = false) {
  let safeFnResponse = safeFn();
  if (typeof safeFnResponse === 'number') {
    safeFnResponse = safeFnResponse.toString();
  }
  if (isNullOrUndefined(safeFnResponse) || !safeFnResponse) {
    return response;
  }
  return true;
}
