import React from 'react';

import {
  PitchContainer,
  Row,
  Position,
} from '../../assets/themes/styles/components/PlayerTable/PositionFilter';

const PositionFilter = ({ positions = [], onChange, vertical = false }) => {
  const field = [
    [
      {
        id: 1,
        abbreviation: 'GK',
        name: 'goalkeeper',
      },
    ],
    [
      {
        id: 2,
        abbreviation: 'LB',
        name: 'left back',
      },
      {
        id: 3,
        abbreviation: 'CB',
        name: 'center back',
      },
      {
        id: 4,
        abbreviation: 'RB',
        name: 'right back',
      },
    ],
    [
      {
        id: 5,
        abbreviation: 'LWB',
        name: 'left wing-back',
      },
      {
        id: 7,
        abbreviation: 'DM',
        name: 'defensive mifield',
      },
      {
        id: 6,
        abbreviation: 'RWB',
        name: 'right wing-back',
      },
    ],
    [
      {
        id: 9,
        abbreviation: 'LM',
        name: 'left midfield',
      },
      {
        id: 8,
        abbreviation: 'CM',
        name: 'central midfield',
      },
      {
        id: 10,
        abbreviation: 'RM',
        name: 'right midfield',
      },
    ],
    [
      {
        id: 12,
        abbreviation: 'LW',
        name: 'left wing',
      },
      {
        id: 11,
        abbreviation: 'AM',
        name: 'attackign midfield',
      },
      {
        id: 13,
        abbreviation: 'RW',
        name: 'right wing',
      },
    ],
    [
      {
        id: 14,
        abbreviation: 'ST',
        name: 'striker',
      },
    ],
    [],
  ];
  return (
    <PitchContainer vertical={vertical}>
      {field.map((row, i) => (
        <Row key={i} vertical={vertical}>
          {row.map((p) => (
            <Position key={p.id} vertical={vertical} onClick={() => onChange(p.id)} checked={positions.includes(p.id)}>{p.abbreviation}</Position>
          ))}
        </Row>
      ))}
    </PitchContainer>
  );
};

export default PositionFilter;
