import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UserAvatar from '../../components/UserAvatar';
import Step from './Step';

class AttendingStep extends Component {
  COLUMNS = [
    {accessor:(d) => <UserAvatar user={d}/>, header: 'Player'},
  ];
  
  render() {    
    const {players} = this.props;
    return (
      <Step players={players} columns={this.COLUMNS} headline="You have accepted the following players to attend the event"/>
    );
  }
}

AttendingStep.propTypes = {
  players: PropTypes.array,
};

AttendingStep.defaultProps = {
  players: [],
};


export default AttendingStep;
