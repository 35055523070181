import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import spinner from '../../assets/lottie/spinner.json';
import spinnerBlack from '../../assets/lottie/spinner-black.json';

const Spinner = ({ width, height, options, black, inline }) => {
  let style = { minHeight: `${height}px` };
  if (inline) {
    style = {
      marginLeft: '10px',
      display: 'inline-block',
      verticalAlign: 'middle',
      minHeight: `${height}px`,
    };
  }

  if (black) {
    options.animationData = spinnerBlack;
  } else {
    options.animationData = spinner;
  }
  return (
    <Lottie style={style} options={options} width={width} height={height} />
  );
};


Spinner.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  options: PropTypes.object,
  black: PropTypes.bool,
  inline: PropTypes.bool,
};
Spinner.defaultProps = {
  width: 44,
  height: 44,
  black: false,
  inline: false,
  options: {
    loop: true,
    autoplay: true,
    animationData: spinner,
  },
};

export default Spinner;
