import React, { Component, Fragment } from 'react';
import { FlexboxGrid as Div } from 'baroo';
import { Divider, Message } from 'semantic-ui-react';
import qs from 'qs';
import Spinner from '../components/Spinner';
import logo from '../assets/images/logoplayerdata_white.png';
import googleLogo from '../assets/images/google.png';
import facebookLogo from '../assets/images/facebook.png';
import Api from '../services/api';
import { safe } from '../services/utils';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router'
import config from '../config/';
import {
  Body,
  Logo,
  LoginPanel,
  H1,
  BackgroundPicture,
  DividerStyled,
  ButtonStyled,
  Terms,
} from '../assets/themes/styles/auth/LoginPage';
const API_URL = `${config.apiHost}`;
const useServer = !!config.useServer;

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = { error: null, loading: false };
  }
  componentDidMount() {
    this._isMounted = true;
    if (window && window.amplitude) window.amplitude.getInstance().logEvent('Login page shown');
    this.checkFBInit()
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loginWithFacebook = () => {
    window.amplitude.getInstance().logEvent('Sign in button clicked', { type: 'facebook' });
    window.FB && window.FB.login(async ({ status, authResponse }) => {
      if (status === "connected" && authResponse.accessToken) {
        try {
          this._isMounted && this.setState({ loading: true, error: null });
          const response = await Api.post('oauth/token', { facebook_access_token: authResponse.accessToken, facebook_id: authResponse.userID })
          this.loginSuccess(response.token, { loginType: 'facebook', newUser: response.new_user });
        } catch (e) {
          console.log('API login fail', e);
          this._isMounted && this.setState({
            loading: false,
            error: 'An unexpected error occured. Please try again later'
          });
        }
      }
    })
  }

  loginWithGoogle = async () => {
    this._isMounted && this.setState({ loading: true, error: null });
    window.amplitude.getInstance().logEvent('Sign in button clicked', { type: 'google' });
    try {
      await window.auth2.signOut()
      const result = await window.auth2.signIn(null, () => console.log('signIn'))
      let response = {};
      if (safe(() => useServer)) {
        response = await fetch(`/get_token_google?discover_id_token=${result.getAuthResponse().id_token}&discover_api_url=${API_URL}`)
          .then(response => response.json())
          .then(response => {
            return JSON.parse(JSON.stringify(response));
          })
          .catch(error => console.error('Error:', error));
      } else {
        response = await Api.post('oauth/token_google', { id_token: result.getAuthResponse().id_token })
      }
      console.log('API login response', response);
      await this.loginSuccess(response.token, { loginType: 'google', newUser: response.new_user });

    } catch (e) {
      console.log('Login with google error', e);
      this._isMounted && this.setState({
        loading: false,
        error: 'An unexpected error occured. Please try again later'
      });
    }
  }

  loginSuccess = async (token, { loginType, newUser }) => {
    const queryParams = qs.parse(window.location.search.replace('?', ''))
    window.localStorage.setItem('token', token);
    try {
      const { response } = await Api.me()
      window.amplitude.getInstance().setUserId(response.slug);
      if (response.access_to_discover) {
        this._isMounted && this.setState({ loading: false });
        window.amplitude.getInstance().logEvent('Signed in', { type: loginType, newUser });
        return this.props.history.replace('/');
      }
    } catch (e) {
      return this._isMounted && this.setState({
        loading: false,
        error: 'An unexpected error occured. Please try again later',
      });
    }
    if (queryParams['invite-token']) {
      try {
        await Api.get('discover/invite_tokens/' + queryParams['invite-token'] + '/validate')
        this.props.history.replace('/');
        window.amplitude.getInstance().logEvent('Signed in', { type: loginType });
        this._isMounted && this.setState({ loading: false });
      } catch (e) {
        window.localStorage.removeItem('token');
        window.amplitude.getInstance().logEvent('Access denied');
        this._isMounted && this.setState({ loading: false, error: 'Your invite token is not valid' });
      }
    } else {
      window.localStorage.removeItem('token');
      window.amplitude.getInstance().logEvent('Access denied');
      this._isMounted && this.setState({ loading: false, error: 'Access denied' });
    }
  }

  checkFBInit = () => {
    if (this.state.canFBLogin) { return }
    const canFBLogin = !!(window.FB && window.FB.login);
    if (this.state.canFBLogin !== canFBLogin) {
      this._isMounted && this.setState({ canFBLogin });
    }
    setTimeout(this.checkFBInit, 500)
  }

  render() {
    const { error, canFBLogin } = this.state;
    const loading = this.state.loading || this.props.loading
    return (
      <Body>
        <LoginPanel row middleXs centerXs noGutters>
          <H1>Login {loading && <Spinner black inline width={24} height={24} />}</H1>
          <ButtonStyled btnSm onClick={this.loginWithFacebook} disabled={loading || !canFBLogin}>
            <img src={facebookLogo} width="24" alt="facebook" /> Log in with facebook
              </ButtonStyled>

          <DividerStyled horizontal>Or</DividerStyled>
          <ButtonStyled btnSm onClick={this.loginWithGoogle} disabled={loading}>
            <img src={googleLogo} width="24" alt="google" />Log in with google
            </ButtonStyled>
          <Divider hidden />
          {error &&
            <Fragment>
              <Divider horizontal />
              <Message negative content={error} />
            </Fragment>
          }
          <Terms>
            <a rel="noopener noreferrer" href="https://tonsser.com/terms" target="_blank">Terms and conditions</a>
          </Terms>
        </LoginPanel>
        <Div row noGutters>
          <BackgroundPicture colXs={12} >
            <Link to="/"><Logo><img src={logo} alt="tonsser" /></Logo></Link>
          </BackgroundPicture>
        </Div>
      </Body>
    )
  }

}


export default withRouter(LoginPage);
