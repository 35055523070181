import { Message, } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledMessage = styled(Message)`
  margin-top: 44px !important;
  margin-bottom: 44px !important;
  text-align:center;
`;

export {
  StyledMessage
}