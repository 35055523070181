import React, { Component } from 'react';
import moment from 'moment';
import { Header } from 'semantic-ui-react';
import { FlexboxGrid as Div } from 'baroo';
import { Link } from 'react-router-dom';
import { ButtonLight } from '../assets/themes/styles/components/Styled/Styled';
import {
  PanelStyled,
  CategoryCol,
  Label,
  Description,
  PanelHeaderStyled,
  PanelBodyStyled
} from '../assets/themes/styles/shortlists/ShortlistCardSummary';

const DATE_FORMAT = null;
const DATE_DISPLAY = 'MMM Do, H:mm';

class ShortlistCardSummary extends Component {
  shouldComponentUpdate(nextProps) {
    return !this.props.shortlist || nextProps.shortlist.id !== this.props.shortlist.id;
  }
  render() {
    const { shortlist } = this.props;

    return (
      <PanelStyled to={`/shortlists/${shortlist.id}`}>
        <PanelHeaderStyled>
          <Header as="h4" content={shortlist.name} floated="left" />
          <Header as="h4" floated="right">
            <Link to={`/shortlists/${shortlist.id}/update`}><ButtonLight btnXs style={{ color: 'white' }}>Edit</ButtonLight></Link>
          </Header>
        </PanelHeaderStyled>
        <PanelBodyStyled>
          <Div row noGutters style={{ marginBottom: '32px' }}>
            <CategoryCol>
              <Label>Description</Label>
              <Description>{shortlist.description}</Description>
            </CategoryCol>
          </Div>
          <Div row noGutters betweenXs>
            <CategoryCol>
              <Label>Players</Label>
              <Description>{shortlist.users_count}</Description>
            </CategoryCol>
            <CategoryCol>
              <Label>Creator</Label>
              <Description>{shortlist.creator.firstname} {shortlist.creator.lastname}</Description>
            </CategoryCol>
            <CategoryCol>
              <Label>Last update</Label>
              <Description>{moment(shortlist.updated_at, DATE_FORMAT).format(DATE_DISPLAY)}</Description>
            </CategoryCol>
          </Div>
        </PanelBodyStyled>
      </PanelStyled>


    );
  }
}

export default ShortlistCardSummary;
