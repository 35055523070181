export default {
  env: 'development',
  apiHost: 'https://staging-api.tonsser.com/49',
  graphqlHost: 'http://localhost:3000/graphql',
  graphqlApiHost: 'https://staging-graphql.tonsser.com/graphql',
  graphqlAPIKey: 'TkwqY1NRsOcDonmboE89mxtOyfTQ3+YjPf5EkgINIAo=',
  mock: false,
  useServer: true,
  AWS: {
    region: 'eu-west-1',
    IdentityPoolId: 'eu-west-1:f7bee622-07e1-4a74-a177-f0a6e74aa537',
    inputBucket: 'tonsser-dev-input',
  },
};