import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Header, Divider, Form } from 'semantic-ui-react';
import Api from '../../services/api';
import { getPlayerShortlists } from '../../redux/selectors';
import { updateShortlistPlayer } from '../../redux/actions';

import {
  IconStyled,
  LabelStyled
} from '../../assets/themes/styles/components/Shortlists/Shortlists';

class Shortlists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shortlistsAvailable: [],
      loading: false,
      newShortlistName: null,
      playerShortlists: []
    }
  }

  componentDidMount() {
    this.setState({ loading: true })
    Api.getShortlists()
      .then((data) => {
        this.setState({ shortlistsAvailable: data.response || [], loading: false })
      }).catch(() => {
        this.setState({ loading: false })
      })
    if (this.props.playerShortlists.length) {
      this.setState({ playerShortlists: this.props.playerShortlists })
    } else {
      this.setState({ playerShortlists: this.props.player.shortlists || [] })
    }
  }

  createShortlist = () => {
    this.setState({ loading: true });
    Api.createShortlist({ name: this.state.newShortlistName })
      .then((data) => {
        this.setState({ shortlistsAvailable: [...this.state.shortlistsAvailable, data.response], newShortlistName: null, loading: false });
        this.addToShortlist(data.response);
      }).catch(() => {
        this.setState({ loading: false })
      })
  }

  addToShortlist = (shortlist) => {
    if (this.state.loading) {
      return
    }
    this.setState({ loading: true })
    Api.addPlayerToShortlist(shortlist.id, this.props.player.user_id)
      .then(() => {
        const newPlayerShortlists = [...this.state.playerShortlists, shortlist];
        this.props.updateShortlistPlayer(this.props.player.user_id, newPlayerShortlists);
        this.setState({ loading: false, playerShortlists: newPlayerShortlists });
      })
      .catch(() => {
        this.setState({ loading: false })
      })
  }

  removeFromShortlist = (shortlist) => {
    if (this.state.loading) {
      return
    }
    this.setState({ loading: true })
    Api.removePlayerFromShortlist(shortlist.id, this.props.player.user_id)
      .then(() => {
        const newPlayerShortlists = this.state.playerShortlists.filter(s => s.id !== shortlist.id);
        this.props.updateShortlistPlayer(this.props.player.user_id, newPlayerShortlists);
        this.setState({ loading: false, playerShortlists: newPlayerShortlists });
      })
      .catch(() => {
        this.setState({ loading: false });
      })
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value })

  render() {
    const { shortlistsAvailable, playerShortlists, newShortlistName, loading } = this.state;
    const canJoin = shortlistsAvailable.filter(s => !playerShortlists.find(p => p.id === s.id))
    return (
      <div>
        {playerShortlists.length !== 0 &&
          <Fragment>
            <Header as='h4'>Added to Shortlists:</Header>
            {playerShortlists.map(s => (
              <LabelStyled onClick={() => this.removeFromShortlist(s)}>
                {s.name}
                <IconStyled name='delete' />
              </LabelStyled>
            ))}
            <Divider />
          </Fragment>
        }
        {canJoin.length !== 0 &&
          <Fragment>
            <Header as='h4'>Available Shortlists:</Header>
            {canJoin.map(s => (
              <LabelStyled onClick={() => this.addToShortlist(s)}>
                {s.name}
                <IconStyled name='add' />
              </LabelStyled>
            ))}
            <Divider />
          </Fragment>
        }
        <Form onSubmit={this.createShortlist}>
          <Form.Group>
            <Form.Input disabled={loading} placeholder='Create a new shortlist' name='newShortlistName' value={newShortlistName} onChange={this.handleChange} />
            <Form.Button content='Add' disabled={loading} size="tiny" style={{ height: '28px' }} />
          </Form.Group>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  playerShortlists: getPlayerShortlists(state, props.player.user_id),
});

export default connect(mapStateToProps, { updateShortlistPlayer })(Shortlists);
