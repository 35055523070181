import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import './assets/themes/index.css';
import './assets/icons/tonsser-discover-icons.css';
import store from './redux/store';

import { ApolloClient } from 'apollo-client';
import { ApolloLink } from "apollo-link";
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { ApolloProvider } from 'react-apollo';
import { Provider } from 'react-redux';

import App from './App';
import config from './config';
const graphqlHost = `${config.graphqlHost}`;
const graphqlAPIKey = `${config.graphqlAPIKey}`;

const token = localStorage.getItem('token');

const httpLink = createHttpLink({
  uri: graphqlHost,
  credentials: 'same-origin'
});
const middlewareLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      'authorization': token ? `Bearer ${token}` : '',
      'x-api-key': `${graphqlAPIKey}`,
    }
  });
  return forward(operation);
});

// use with apollo-client
const link = middlewareLink.concat(httpLink);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store} >
      <App />
    </Provider>
  </ApolloProvider >,
  document.getElementById('root')
);
// registerServiceWorker();
