import { FlexboxGrid as Div } from 'baroo';
import styled, { css } from 'styled-components';

const Category = Div.extend`
`;
const CategoryName = Div.extend`
  color: black;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
`;

const Label = Div.extend`
  color: #242c39;
`;

const Content = Div.extend`
  color: #555555;
`;

const Media = Div.extend`
  margin-bottom: 16px;
  cursor: pointer;
`;

// TODO match-video
const Image = Div.extend`
  position: relative;
  background: transparent url(${(props) => props.src}) center no-repeat;
  background-color: black; 
  background-size: cover;
  height: 200px;
  > img {
    background: transparent;
    max-width: 100%;
  }
  > i {
    ${(props) => props.type === 'match-video' && css`
      position: absolute;
      bottom: 68px;
      left: 72px;
      font-size: 55px;
      font-weight: '600';
      color: #fff;
      text-shadow: 1px 1px 3px black;
    `}
    ${(props) => props.type === 'video' && css`
      position: absolute;
      bottom: 68px;
      left: 72px;
      font-size: 55px;
      font-weight: '600';
      color: #fff;
      text-shadow: 1px 1px 3px black;
    `}
    ${(props) => props.type === 'image' && css`
      position: absolute;
      bottom: 8px;
      right: 4px;
      font-size: 18px;
      color: #fff;
      text-shadow: 1px 1px 3px black;
    `}
  }
`;

const Description = Div.extend`
  margin-top: 8px !important;
`;

const SectionButtons = styled(Div)`
  margin-left: 0px;
  margin-bottom: 30px;
`;

const SectionButton = styled(Div)`
  width: 80px;
  padding: 3px;
  text-align: center;
  border-radius: 2px;
  background-color: white;
  border: 1px #575757 solid;
  margin-right: 8px;
  font-weight: 600;
  cursor: pointer;
  ${(props) => props.active && css`
    background-color: #242d39;
    color: white;
  `}
`;

const MediaType = styled(Div)`
  position: absolute;
  bottom: 4px;
  left: 4px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  text-shadow: 1px 1px 5px black;
`;

const MatchDetails = styled(Div)`
`;
const MatchDetailsTitle = styled(Div)`
  display: flex;
  font-size: 18px;
  font-weight: 600;
  align-items: baseline;
  max-width: 240px;
  flex-wrap: wrap;
`;
const MatchDetailsType = styled(Div)`
  font-size: 11px;
`;
const MatchDetailsTime = styled(Div)`
  font-size: 11px;
`;


const OverviewHomeTeam = styled.div`
  ${props => props.isHome && ``}
`;
const OverviewAwayTeam = styled.div`
  ${props => props.isAway && ``}
`;
const OverviewScore = styled.div`
  margin-left: 5px;
  margin-right: 5px;
`;

export {
  Category,
  CategoryName,
  Label,
  Content,
  Media,
  Image,
  Description,
  SectionButtons,
  SectionButton,
  MediaType,
  MatchDetails,
  MatchDetailsTitle,
  MatchDetailsType,
  MatchDetailsTime,
  OverviewHomeTeam,
  OverviewAwayTeam,
  OverviewScore,
};
