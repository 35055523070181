import React, { Fragment } from 'react';
import PlayerSearchPage from '../playerSearch/PlayerSearchPage';
import LoginPage from '../auth/LoginPage';
import EventsListPage from '../events/EventsListPage';
import logo from '../assets/images/logoplayerdata_white.png';
import { LogoLoading } from '../components/Styled/';

const LandingPage = ({ user, loading, match }) => (
  <Fragment>
    {!loading && user && user.access_to_player_search && <PlayerSearchPage user={user} playerId={match.params.id} />}
    {!loading && user && !user.access_to_player_search && user.access_to_events && <EventsListPage user={user} />}
    {(loading) &&
      <LogoLoading logo={logo} altText={'TonsserLogo'} height={'65px'} />
    }
    {!loading && !user &&
      <LoginPage loading={loading} />
    }
  </Fragment>
);

export default LandingPage;
