import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Step, Segment, Form, Checkbox, Popup } from 'semantic-ui-react';
import { FlexboxGrid as Div } from 'baroo';
import 'inobounce';
import 'react-datepicker/dist/react-datepicker.css';
import Api from '../../services/api';

import {
  normalizeStr,
  MIN_MATCHES,
  MAX_MATCHES,
  MAX_SEASONS,
  MAX_HEIGHT,
  MIN_HEIGHT,
  MIN_RATING,
  MAX_RATING,
  MIN_VOTES,
  MAX_VOTES,
  MIN_VOTES_RATIO,
  MAX_VOTES_RATIO,
  MIN_GOALS,
  MAX_GOALS,
  MIN_ASSISTS,
  MAX_ASSISTS,
  MIN_CLEAN_SHEETS,
  MAX_CLEAN_SHEETS,
  MIN_BIRTHDAY_YEAR,
  MAX_BIRTHDAY_YEAR,
  LEAGUE_LEVEL,
  MONTHS
} from '../../services/utils';
import Spinner from '../Spinner';
import Modal from '../Modal';
import Range from '../Range';
import { StyledSteps } from '../../assets/themes/styles/components/Steps/Steps';
import PositionFilter from './PositionFilter';
import withContext from '../../hoc/withContext';
import UserContext from '../../contexts/user';

import {
  Col,
  SearchBar,
  FilterCategory,
  FilterLabel,
  PositionContainer,
  CheckButton,
  InfoMessage,
  Count
} from '../../assets/themes/styles/components/PlayerTable/FilterModal';


class FilterModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepActive: null,
      loading: 0,
      countriesDisabled: false,
      agegroupsDisabled: true,
      leaguesDisabled: true,
      countries: [],
      shortlists: [],
      selectedCountries: [],
      agegroups: [],
      selectedAgegroups: [],
      leagues: [],
      filteredLeagues: [], // used by search text
      selectedLeagues: [],
      fetchAgegroups: true,
      fetchLeagues: true,
      canFilterCountries: false,
      canFilterAgegroups: false,
      canFilterLeagues: false,
      filtersChanged: false, // don't reload data if filters don't change
      matches_played_min: MIN_MATCHES,
      matches_played_max: MAX_MATCHES,
      shortlisted: null,
      positions: [],
      seasons: [],
      birth_year_max: MAX_BIRTHDAY_YEAR,
      birth_year_min: MIN_BIRTHDAY_YEAR,
      birth_month_min: 1,
      birth_month_max: 12,
      height_min: MIN_HEIGHT,
      height_max: MAX_HEIGHT,
      primary_foot: null,
      average_rating_min: MIN_RATING,
      average_rating_max: MAX_RATING,
      motm_votes_min: MIN_VOTES,
      motm_votes_max: MAX_VOTES,
      team_motm_votes_ratio_min: MIN_VOTES_RATIO,
      team_motm_votes_ratio_max: MAX_VOTES_RATIO,
      goals_min: MIN_GOALS,
      goals_max: MAX_GOALS,
      assists_min: MIN_ASSISTS,
      assists_max: MAX_ASSISTS,
      clean_sheets_min: MIN_CLEAN_SHEETS,
      clean_sheets_max: MAX_CLEAN_SHEETS,
      league_level: [],
      has_note: null,
      gender: null,
      shortlist_ids: [],
      not_in_shortlist_ids: []
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.modalOpen === this.props.modalOpen) {
      return
    }
    if (nextProps.modalOpen) {
      this.initComponent()
    }
  }

  initComponent = async () => {
    const { filters } = this.props.data;

    const { audience } = this.props; //Countries and agegroup are known (event page)

    this.setState({
      selectedCountries: filters.countries || [],
      selectedAgegroups: filters.agegroups || [],
      selectedLeagues: filters.leagues || [],
      stepActive: 'general',
      loading: 1,
      filtersChanged: false,
      matches_played_min: filters.matches_played_min || MIN_MATCHES,
      matches_played_max: filters.matches_played_max || MAX_MATCHES,
      shortlisted: filters.shortlisted,
      positions: filters.positions || [],
      seasons: filters.seasons || [],
      has_note: filters.has_note,

      birth_year_max: filters.birth_year_max || MAX_BIRTHDAY_YEAR,
      birth_year_min: filters.birth_year_min || MIN_BIRTHDAY_YEAR,
      birth_month_min: filters.birth_month_min || 1,
      birth_month_max: filters.birth_month_max || 12,

      height_min: filters.height_min || MIN_HEIGHT,
      height_max: filters.height_max || MAX_HEIGHT,
      primary_foot: filters.primary_foot,
      average_rating_min: filters.average_rating_min || MIN_RATING,
      average_rating_max: filters.average_rating_max || MAX_RATING,

      motm_votes_min: filters.motm_votes_min || MIN_VOTES,
      motm_votes_max: filters.motm_votes_max || MAX_VOTES,

      team_motm_votes_ratio_min: filters.team_motm_votes_ratio_min || MIN_VOTES_RATIO,
      team_motm_votes_ratio_max: filters.team_motm_votes_ratio_max || MAX_VOTES_RATIO,

      goals_min: filters.goals_min || MIN_GOALS,
      goals_max: filters.goals_max || MAX_GOALS,

      assists_min: filters.assists_min || MIN_ASSISTS,
      assists_max: filters.assists_max || MAX_ASSISTS,

      clean_sheets_min: filters.clean_sheets_min || MIN_CLEAN_SHEETS,
      clean_sheets_max: filters.clean_sheets_max || MAX_CLEAN_SHEETS,
      league_level: filters.league_level || [],
      gender: filters.gender,
      shortlist_ids: filters.shortlist_ids || [],
      not_in_shortlist_ids: filters.not_in_shortlist_ids || [],
    })
    // Check if can filter more than one country, regions... otherwise we should remove the tab
    try {
      let canFilterCountries = true;
      let canFilterAgegroups = true;
      let canFilterLeagues = true;
      let countries = [];
      let agegroups = [];
      let leagues = [];
      let shortlists = []

      let fetchAgegroups;
      let fetchLeagues;

      if (audience) {
        canFilterCountries = audience.countries.length !== 1;
        canFilterAgegroups = audience.agegroups.length !== 1;
        canFilterLeagues = false;
        countries = [...audience.countries];
        agegroups = [...audience.agegroups];
        fetchAgegroups = false;
        fetchLeagues = false;
      } else {
        fetchAgegroups = true;
        fetchLeagues = true;
        const countriesResp = await Api.getCountries()
        canFilterCountries = countriesResp.response.length > 1;
        if (canFilterCountries) {
          countries = countriesResp.response;
        } else {
          const [agegroupsResp] = await Promise.all([Api.getAgegroups()])
          canFilterAgegroups = agegroupsResp.response.length > 1;
          if (canFilterAgegroups) {
            agegroups = agegroupsResp.response;
          } else {
            const leaguesResp = await Api.getLeagues();
            leagues = leaguesResp.response;
          }
        }
      }

      const shortlistsResp = await Api.getShortlists()
      shortlists = shortlistsResp.response;


      this.setState({
        agegroupsDisabled: canFilterCountries && (!filters.countries || !filters.countries.length),
        leaguesDisabled: (canFilterAgegroups && (!filters.agegroups || !filters.agegroups.length)),
        fetchAgegroups,
        fetchLeagues,
        countries,
        agegroups,
        filteredLeagues: leagues,
        leagues,
        shortlists,
        loading: 0,
        canFilterCountries,
        canFilterAgegroups,
        canFilterLeagues
      })
    } catch (e) {
      this.setState({ error: 'Unable to fetch filters, please try again later', loading: 0 })
    }

  }

  onSuccess = () => {
    const {
      selectedCountries,
      selectedLeagues,
      selectedAgegroups,
      filtersChanged,
      matches_played_max,
      matches_played_min,
      shortlisted,
      positions,
      seasons,
      birth_year_max,
      birth_year_min,
      birth_month_max,
      birth_month_min,
      height_max,
      height_min,
      primary_foot,
      average_rating_min,
      average_rating_max,
      motm_votes_min,
      motm_votes_max,
      team_motm_votes_ratio_min,
      team_motm_votes_ratio_max,
      goals_min,
      goals_max,
      assists_min,
      assists_max,
      clean_sheets_min,
      clean_sheets_max,
      league_level,
      has_note,
      gender,
      shortlist_ids,
      not_in_shortlist_ids
    } = this.state;
    if (!filtersChanged) {
      return this.props.handleClose();
    }
    return this.props.onSuccess({
      countries: selectedCountries,
      agegroups: selectedAgegroups,
      leagues: selectedLeagues,
      matches_played_min: matches_played_min === MIN_MATCHES ? null : matches_played_min,
      matches_played_max: matches_played_max === MAX_MATCHES ? null : matches_played_max,
      shortlisted,
      positions,
      seasons,
      has_note,

      birth_year_max: birth_year_max === MAX_BIRTHDAY_YEAR ? null : birth_year_max,
      birth_year_min: birth_year_min === MIN_BIRTHDAY_YEAR ? null : birth_year_min,
      birth_month_max: birth_month_max === 12 ? null : birth_month_max,
      birth_month_min: birth_month_min === 1 ? null : birth_month_min,

      height_min: height_min === MIN_HEIGHT ? null : height_min,
      height_max: height_max === MAX_HEIGHT ? null : height_max,
      primary_foot,
      average_rating_min: average_rating_min === MIN_RATING ? null : average_rating_min,
      average_rating_max: average_rating_max === MAX_RATING ? null : average_rating_max,

      motm_votes_min: motm_votes_min === MIN_VOTES ? null : motm_votes_min,
      motm_votes_max: motm_votes_max === MAX_VOTES ? null : motm_votes_max,

      team_motm_votes_ratio_min: team_motm_votes_ratio_min === MIN_VOTES_RATIO ? null : team_motm_votes_ratio_min,
      team_motm_votes_ratio_max: team_motm_votes_ratio_max === MAX_VOTES_RATIO ? null : team_motm_votes_ratio_max,

      goals_min: goals_min === MIN_GOALS ? null : goals_min,
      goals_max: goals_max === MAX_GOALS ? null : goals_max,

      assists_min: assists_min === MIN_ASSISTS ? null : assists_min,
      assists_max: assists_max === MAX_ASSISTS ? null : assists_max,

      clean_sheets_min: clean_sheets_min === MIN_CLEAN_SHEETS ? null : clean_sheets_min,
      clean_sheets_max: clean_sheets_max === MAX_CLEAN_SHEETS ? null : clean_sheets_max,
      league_level,
      gender,
      shortlist_ids,
      not_in_shortlist_ids
    })
  }


  fetchAgegroups = () => {
    this.setState(prevState => {
      return { loading: prevState.loading + 1 }
    })
    Api.getAgegroups(this.state.selectedCountries.map(c => c.id)).then(data => {
      const agegroups = data.response;
      this.setState(prevState => {
        return { loading: prevState.loading - 1, agegroups, fetchAgegroups: false }
      })
    })
      .catch(this.setState({ error: 'Unable to fetch agegroups, please try again later' }))
  }

  fetchLeagues = () => {
    this.setState(prevState => {
      return { loading: prevState.loading + 1 }
    })
    Api.getLeagues(null, this.state.selectedAgegroups.map(a => a.id)).then(data => {
      const leagues = data.response;
      this.setState(prevState => {
        return { loading: prevState.loading - 1, leagues, filteredLeagues: leagues, fetchLeagues: false }
      })
    })
      .catch(this.setState({ error: 'Unable to fetch leagues, please try again later' }))
  }

  activeStep = (step) => {
    if (step !== this.state.stepActive) {
      this.setState({ stepActive: step })
      switch (step) {
        case 'agegroups':
          this.state.fetchAgegroups && this.fetchAgegroups()
          break;
        case 'leagues':
          this.state.fetchLeagues && this.fetchLeagues()
          break;
        default:

      }
    }
  }

  isSelected = (selection, value) => {
    return selection.filter(s => s.id === value.id).length !== 0
  }

  isCountrySelected = (country) => {
    return this.isSelected(this.state.selectedCountries, country)
  }
  isAgegroupSelected = (agegroup) => {
    return this.isSelected(this.state.selectedAgegroups, agegroup)
  }
  isLeagueSelected = (league) => {
    return this.isSelected(this.state.selectedLeagues, league)
  }

  getNewSelection = (selection, value) => {
    let nextSelection
    if (this.isSelected(selection, value)) {
      nextSelection = selection.filter(s => s.id !== value.id)
    } else {
      nextSelection = [value, ...selection]
    }
    return nextSelection
  }

  handleCountryChange = (country) => {
    const { audience } = this.props;
    const nextSelectedCountries = this.getNewSelection(this.state.selectedCountries, country)
    this.setState({
      selectedCountries: nextSelectedCountries,
      agegroupsDisabled: nextSelectedCountries.length === 0,
      fetchAgegroups: !audience,
      fetchLeagues: !audience,
      filtersChanged: true
    })
    this.updateAgegroupSelection(nextSelectedCountries)
  }


  handleAgegroupChange = (agegroup) => {
    const { audience } = this.props;
    const nextSelectedAgegroups = this.getNewSelection(this.state.selectedAgegroups, agegroup)
    this.setState({
      selectedAgegroups: nextSelectedAgegroups,
      leaguesDisabled: nextSelectedAgegroups.length === 0,
      fetchLeagues: !audience,
      filtersChanged: true
    })
    this.updateLeagueSelection(nextSelectedAgegroups)

  }

  handleLeagueChange = (league) => {
    const nextSelectedLeagues = this.getNewSelection(this.state.selectedLeagues, league)
    this.setState({
      selectedLeagues: nextSelectedLeagues,
      filtersChanged: true
    })
  }


  updateAgegroupSelection = (countries) => {
    if (this.state.selectedAgegroups.length) {
      const countryIds = countries.map(c => c.id)
      const nextSelectedAgegroups = this.state.selectedAgegroups.filter(a => countryIds.includes(a.country_id))
      this.setState(prevState => {
        return {
          selectedAgegroups: nextSelectedAgegroups,
          leaguesDisabled: nextSelectedAgegroups.length === 0,
        }
      })
      this.updateLeagueSelection(nextSelectedAgegroups)
    }
  }

  updateLeagueSelection = (agegroups) => {
    if (this.state.selectedLeagues.length) {
      const agegroupIds = agegroups.map(a => a.id)
      this.setState(prevState => {
        return { selectedLeagues: prevState.selectedLeagues.filter(l => agegroupIds.includes(l.agegroup_id)) }
      })
    }
  }

  filterLeagues = (event) => {
    let search = event.target.value
    if (!search || !search.length) {
      return this.setState({ filterLeagues: this.state.leagues })
    }
    search = normalizeStr(search)
    const { leagues } = this.state;
    const filteredLeagues = leagues.filter(r => normalizeStr(r.name).includes(search))
    this.setState({ filteredLeagues })
  }

  onMatchesChange = ({ min, max }) => {
    this.setState({ matches_played_max: max, matches_played_min: min, filtersChanged: true })
  }

  handleShortlistedChange = (e, { value }) => {
    this.setState({ shortlisted: this.state.shortlisted === value ? null : value, filtersChanged: true })
  }
  handlePositionsChange = (value) => {
    if (this.state.positions.includes(value)) {
      this.setState({ positions: this.state.positions.filter(id => id !== value), filtersChanged: true })
    } else {
      this.setState({ positions: [...this.state.positions, value], filtersChanged: true })
    }
  }
  handleInShortlistsChange = (shortlist) => {
    if (this.isSelected(this.state.shortlist_ids, shortlist)) {
      this.setState({ shortlist_ids: this.state.shortlist_ids.filter(s => s.id !== shortlist.id), filtersChanged: true })
    } else {
      this.setState({ shortlist_ids: [...this.state.shortlist_ids, shortlist], filtersChanged: true })
    }
    if (this.isSelected(this.state.not_in_shortlist_ids, shortlist)) {
      this.setState({ not_in_shortlist_ids: this.state.not_in_shortlist_ids.filter(s => s.id !== shortlist.id), filtersChanged: true })
    }
  }
  handleNotInShortlistsChange = (shortlist) => {

    if (this.isSelected(this.state.not_in_shortlist_ids, shortlist)) {
      this.setState({ not_in_shortlist_ids: this.state.not_in_shortlist_ids.filter(s => s.id !== shortlist.id), filtersChanged: true })
    } else {
      this.setState({ not_in_shortlist_ids: [...this.state.not_in_shortlist_ids, shortlist], filtersChanged: true })
    }
    if (this.isSelected(this.state.shortlist_ids, shortlist)) {
      this.setState({ shortlist_ids: this.state.shortlist_ids.filter(s => s.id !== shortlist.id), filtersChanged: true })
    }
  }
  handleSeasonsChange = (e, { value }) => {
    if (this.state.seasons.includes(value)) {
      this.setState({ seasons: this.state.seasons.filter(id => id !== value), filtersChanged: true })
    } else if (this.state.seasons.length < MAX_SEASONS) {
      this.setState({ seasons: [...this.state.seasons, value], filtersChanged: true })
    }
  }

  onBirthdayYearChange = ({ min, max }) => {
    this.setState({ birth_year_max: max, birth_year_min: min, filtersChanged: true })

  }

  onBirthdayMonthChange = ({ min, max }) => {
    this.setState({ birth_month_max: max, birth_month_min: min, filtersChanged: true })
  }

  onHeightChange = ({ min, max }) => {
    this.setState({ height_max: max, height_min: min, filtersChanged: true })
  }

  handlePrimaryFootChange = (e, { value }) => {
    this.setState({ primary_foot: this.state.primary_foot === value ? null : value, filtersChanged: true })
  }

  onRatingChange = ({ min, max }) => {
    this.setState({ average_rating_max: max, average_rating_min: min, filtersChanged: true })
  }

  onVotesChange = ({ min, max }) => {
    this.setState({ motm_votes_max: max, motm_votes_min: min, filtersChanged: true })
  }

  onVotesRatioChange = ({ min, max }) => {
    this.setState({ team_motm_votes_ratio_max: max / 100, team_motm_votes_ratio_min: min / 100, filtersChanged: true })
  }

  onGoalsChange = ({ min, max }) => {
    this.setState({ goals_max: max, goals_min: min, filtersChanged: true })
  }

  onAssistsChange = ({ min, max }) => {
    this.setState({ assists_max: max, assists_min: min, filtersChanged: true })
  }

  onCleanSheetsChange = ({ min, max }) => {
    this.setState({ clean_sheets_max: max, clean_sheets_min: min, filtersChanged: true })
  }

  onLeagueLevelChange = (e, { value }) => {
    if (this.state.league_level.includes(value)) {
      this.setState({ league_level: this.state.league_level.filter(l => l !== value), filtersChanged: true })
    } else {
      this.setState({ league_level: [...this.state.league_level, value], filtersChanged: true })
    }
  }

  handleHasNoteChange = (e, { value }) => {
    this.setState({ has_note: this.state.has_note === value ? null : value, filtersChanged: true })
  }

  handleGenderChange = (e, { value }) => {
    this.setState({ gender: this.state.gender === value ? null : value, filtersChanged: true })
  }



  render() {
    const { modalOpen, handleClose, onFail, hideShortlisted, dark, size, user, hideInShortlists, hideNotInShortlists, currentSeasons } = this.props;

    const {
      stepActive,
      countriesDisabled,
      agegroupsDisabled,
      leaguesDisabled,
      countries,
      agegroups,
      filteredLeagues,
      loading,
      canFilterLeagues,
      canFilterAgegroups,
      canFilterCountries,
      matches_played_min,
      matches_played_max,
      shortlisted,
      positions,
      seasons,
      birth_year_min,
      birth_year_max,
      birth_month_min,
      birth_month_max,
      height_max,
      height_min,
      primary_foot,
      average_rating_min,
      average_rating_max,
      motm_votes_min,
      motm_votes_max,
      goals_min,
      goals_max,
      assists_min,
      assists_max,
      clean_sheets_min,
      clean_sheets_max,
      league_level,
      has_note,
      gender,
      shortlists,
      shortlist_ids,
      not_in_shortlist_ids,
    } = this.state;
    const LeagueStep = (
      <Step active={stepActive === 'leagues'} disabled={leaguesDisabled} onClick={() => this.activeStep('leagues')}>
        <Step.Content>
          <Step.Title>Leagues</Step.Title>
        </Step.Content>
      </Step>
    )
    const AgegroupStep = (
      <Step active={stepActive === 'agegroups'} disabled={agegroupsDisabled} onClick={() => this.activeStep('agegroups')}>
        <Step.Content>
          <Step.Title>Age groups</Step.Title>
        </Step.Content>
      </Step>
    )
    return (
      <Modal
        modalOpen={modalOpen}
        handleClose={handleClose}
        onSuccess={this.onSuccess}
        onFail={onFail}
        title="Filters"
        onFailText="Clear"
        dark={dark.toString()}
        size={size}
      >
        <Fragment>
          <StyledSteps attached='top' dark={dark.toString()}>
            <Step active={stepActive === 'general'} onClick={() => this.activeStep('general')}>
              <Step.Content>
                <Step.Title>General</Step.Title>
              </Step.Content>
            </Step>
            <Step active={stepActive === 'performance'} onClick={() => this.activeStep('performance')}>
              <Step.Content>
                <Step.Title>Performance</Step.Title>
              </Step.Content>
            </Step>
            <Step active={stepActive === 'seasons'} onClick={() => this.activeStep('seasons')}>
              <Step.Content>
                <Step.Title>
                  Seasons
                </Step.Title>
              </Step.Content>
            </Step>
            {canFilterCountries && <Step active={stepActive === 'countries'} disabled={countriesDisabled} onClick={() => this.activeStep('countries')}>
              <Step.Content>
                <Step.Title>Countries</Step.Title>
              </Step.Content>
            </Step>}
            {canFilterAgegroups &&
              <Fragment>
                {agegroupsDisabled && <Popup
                  trigger={AgegroupStep}
                  content='You must select a country first'
                  position='bottom center'
                  inverted
                />}
                {!agegroupsDisabled && AgegroupStep}
              </Fragment>
            }
            {canFilterLeagues &&
              <Fragment>
                {leaguesDisabled && <Popup
                  trigger={LeagueStep}
                  content={`You must select an age group first`}
                  position='bottom center'
                  inverted
                />}
                {!leaguesDisabled && LeagueStep}
              </Fragment>
            }
          </StyledSteps>
          <Segment attached>
            {stepActive === 'general' &&
              <Div row style={{ marginRight: 0 }}>
                <Div colXs={12} colSm={4}>
                  {!hideShortlisted &&
                    <Segment>
                      <FilterCategory>
                        <FilterLabel>Shortlisted</FilterLabel>
                        <Form>
                          <Form.Group inline>
                            <CheckButton
                              label='Yes'
                              name='shortlisted'
                              value={true}
                              checked={shortlisted === true}
                              onChange={this.handleShortlistedChange}
                            />
                            <CheckButton
                              label='No'
                              name='shortlisted'
                              value={false}
                              checked={shortlisted === false}
                              onChange={this.handleShortlistedChange}
                            />
                          </Form.Group>
                        </Form>
                      </FilterCategory>
                    </Segment>
                  }
                  <Segment>
                    <PositionContainer>
                      <Div row>
                        <FilterCategory colXs={12}>
                          <FilterLabel>Position</FilterLabel>
                          <PositionFilter positions={positions} onChange={this.handlePositionsChange} vertical={true} />
                        </FilterCategory>
                      </Div>
                    </PositionContainer>
                  </Segment>
                </Div>
                <Div colXs={12} colSm={8} style={{ paddingRight: 0 }}>
                  <Div row>
                    <Div colXs={6} dInlineFlex>
                      <Segment style={{ flexGrow: 1 }}>
                        <FilterCategory>
                          <FilterLabel>Birth Year</FilterLabel>
                          <Range
                            formatLabel={value => value >= MAX_BIRTHDAY_YEAR ? `${MAX_BIRTHDAY_YEAR}+` : value}
                            minValue={MIN_BIRTHDAY_YEAR}
                            maxValue={MAX_BIRTHDAY_YEAR}
                            value={{ min: birth_year_min, max: birth_year_max }}
                            onChange={this.onBirthdayYearChange}
                          />
                        </FilterCategory>
                        <FilterCategory style={{ marginBottom: 0 }}>
                          <FilterLabel>Birthday Month</FilterLabel>
                          <Range
                            formatLabel={value => MONTHS[value]}
                            minValue={1}
                            maxValue={12}
                            value={{ min: birth_month_min, max: birth_month_max }}
                            onChange={this.onBirthdayMonthChange}
                          />
                        </FilterCategory>
                      </Segment>
                    </Div>
                    <Div colXs={6} dInlineFlex>
                      <Segment style={{ flexGrow: 1 }}>
                        <FilterCategory>
                          <FilterLabel>Height (cm)</FilterLabel>
                          <Range
                            formatLabel={value => value >= MAX_HEIGHT ? `${MAX_HEIGHT}+` : value}
                            minValue={MIN_HEIGHT}
                            maxValue={MAX_HEIGHT}
                            value={{ min: height_min, max: height_max }}
                            onChange={this.onHeightChange}
                          />
                        </FilterCategory>
                        <FilterCategory>
                          <FilterLabel>Preferred Foot</FilterLabel>
                          <Form>
                            <Form.Group inline>
                              <CheckButton
                                label='Left'
                                name='primary_foot'
                                value={'left'}
                                checked={primary_foot === 'left'}
                                onChange={this.handlePrimaryFootChange}
                              />
                              <CheckButton
                                label='Both'
                                name='primary_foot'
                                value={'both'}
                                checked={primary_foot === 'both'}
                                onChange={this.handlePrimaryFootChange}
                              />
                              <CheckButton
                                label='Right'
                                name='primary_foot'
                                value={'right'}
                                checked={primary_foot === 'right'}
                                onChange={this.handlePrimaryFootChange}
                              />
                            </Form.Group>
                          </Form>
                        </FilterCategory>
                        {!user.accessible_gender &&
                          <FilterCategory>
                            <FilterLabel>Gender</FilterLabel>
                            <Form>
                              <Form.Group inline>
                                <CheckButton
                                  label='Male'
                                  name='gender'
                                  value={'male'}
                                  checked={gender === 'male'}
                                  onChange={this.handleGenderChange}
                                />
                                <CheckButton
                                  label='Female'
                                  name='gender'
                                  value={'female'}
                                  checked={gender === 'female'}
                                  onChange={this.handleGenderChange}
                                />
                              </Form.Group>
                            </Form>
                          </FilterCategory>
                        }
                      </Segment>
                    </Div>
                  </Div>
                  <Div row>
                    <Div colXs={7}>
                      <Segment>
                        <FilterCategory>
                          <FilterLabel>League level</FilterLabel>
                          <Div row noGutters betweenXs>
                            {LEAGUE_LEVEL.map(l => (
                              <Div key={l.label}>
                                <Checkbox
                                  label={l.label}
                                  value={l.value}
                                  name='leagueLevel'
                                  checked={league_level.includes(l.value)}
                                  onChange={this.onLeagueLevelChange}
                                />
                              </Div>
                            ))}
                          </Div>
                        </FilterCategory>
                      </Segment>
                    </Div>
                    <Div colXs={5}>
                      <Segment>
                        <FilterCategory>
                          <FilterLabel>Notes</FilterLabel>
                          <Form>
                            <Form.Group inline>
                              <CheckButton
                                label='Yes'
                                name='has_note'
                                value={1}
                                checked={has_note === 1}
                                onChange={this.handleHasNoteChange}
                              />
                              <CheckButton
                                label='No'
                                name='has_note'
                                value={0}
                                checked={has_note === 0}
                                onChange={this.handleHasNoteChange}
                              />
                            </Form.Group>
                          </Form>
                        </FilterCategory>
                      </Segment>
                    </Div>
                  </Div>

                  {(!hideInShortlists || !hideNotInShortlists) &&
                    <Div row>
                      {!hideInShortlists &&
                        <Div colXs={6}>
                          <Segment>
                            <FilterCategory>
                              <FilterLabel>In Shortlist</FilterLabel>
                              {shortlists.map(s => (
                                <Div key={s.name}>
                                  <Checkbox
                                    label={s.name}
                                    value={s.id}
                                    name='inShortlists'
                                    checked={this.isSelected(shortlist_ids, s)}
                                    onChange={() => this.handleInShortlistsChange(s)}
                                  />
                                </Div>
                              ))}
                            </FilterCategory>
                          </Segment>
                        </Div>
                      }
                      {!hideNotInShortlists &&
                        <Div colXs={6}>
                          <Segment>
                            <FilterCategory>
                              <FilterLabel>Not In Shortlists</FilterLabel>
                              {shortlists.map(s => (
                                <Div key={s.name}>
                                  <Checkbox
                                    label={s.name}
                                    value={s.id}
                                    name='notInShortlists'
                                    checked={this.isSelected(not_in_shortlist_ids, s)}
                                    onChange={() => this.handleNotInShortlistsChange(s)}
                                  />
                                </Div>
                              ))}
                            </FilterCategory>
                          </Segment>
                        </Div>
                      }
                    </Div>
                  }
                </Div>
              </Div>
            }
            {stepActive === 'seasons' &&
              <Segment>
                <InfoMessage>You can select maximum two seasons at a time in the player table.</InfoMessage>
                <Form>
                  <Div row noGutters>
                    {currentSeasons.map(season => (
                      <Col colXs={6} colSm={4} key={season.number}>
                        <Form.Field>
                          <Checkbox
                            label={season.name}
                            name='season'
                            value={season.number}
                            checked={seasons.includes(season.number)}
                            onChange={this.handleSeasonsChange}
                          />
                        </Form.Field>
                      </Col>
                    ))}
                  </Div>
                </Form>
                <Div row endXs>
                  <Div colXs={12}>
                    <Count>{seasons.length} / {MAX_SEASONS}</Count>
                  </Div>
                </Div>
              </Segment>
            }
            {stepActive === 'performance' &&
              <Segment>
                <Div row style={{ marginRight: 0 }}>
                  <FilterCategory colXs={4}>
                    <FilterLabel>Goals</FilterLabel>
                    <Range
                      formatLabel={value => value >= MAX_GOALS ? `${MAX_GOALS}+` : value}
                      minValue={MIN_GOALS}
                      maxValue={MAX_GOALS}
                      value={{ min: goals_min, max: goals_max }}
                      onChange={this.onGoalsChange}
                    />
                  </FilterCategory>
                  <FilterCategory colXs={4}>
                    <FilterLabel>Matches played</FilterLabel>
                    <Range
                      formatLabel={value => value >= MAX_MATCHES ? `${MAX_MATCHES}+` : value}
                      minValue={MIN_MATCHES}
                      maxValue={MAX_MATCHES}
                      value={{ min: matches_played_min, max: matches_played_max }}
                      onChange={this.onMatchesChange}
                    />
                  </FilterCategory>
                  <FilterCategory colXs={4}>
                    <FilterLabel>Votes</FilterLabel>
                    <Range
                      formatLabel={value => value >= MAX_VOTES ? `${MAX_VOTES}+` : value}
                      minValue={MIN_VOTES}
                      maxValue={MAX_VOTES}
                      value={{ min: motm_votes_min, max: motm_votes_max }}
                      onChange={this.onVotesChange}
                    />
                  </FilterCategory>
                  <FilterCategory colXs={4}>
                    <FilterLabel>Assists</FilterLabel>
                    <Range
                      formatLabel={value => value >= MAX_ASSISTS ? `${MAX_ASSISTS}+` : value}
                      minValue={MIN_ASSISTS}
                      maxValue={MAX_ASSISTS}
                      value={{ min: assists_min, max: assists_max }}
                      onChange={this.onAssistsChange}
                    />
                  </FilterCategory>

                  <FilterCategory colXs={4} style={{ marginBottom: 0 }}>
                    <FilterLabel>Match Rating</FilterLabel>
                    <Range
                      formatLabel={value => value >= MAX_RATING ? `${MAX_RATING}+` : value.toFixed(1)}
                      step={0.1}
                      minValue={MIN_RATING}
                      maxValue={MAX_RATING}
                      value={{ min: average_rating_min, max: average_rating_max }}
                      onChange={this.onRatingChange}
                    />
                  </FilterCategory>

                  <FilterCategory colXs={4} style={{ marginBottom: 0 }}>
                    <FilterLabel>Clean Sheets</FilterLabel>
                    <Range
                      formatLabel={value => value >= MAX_CLEAN_SHEETS ? `${MAX_CLEAN_SHEETS}+` : value}
                      minValue={MIN_CLEAN_SHEETS}
                      maxValue={MAX_CLEAN_SHEETS}
                      value={{ min: clean_sheets_min, max: clean_sheets_max }}
                      onChange={this.onCleanSheetsChange}
                    />
                  </FilterCategory>
                </Div>
              </Segment>


            }
            {stepActive === 'countries' &&
              <Segment>
                {loading !== 0 && <Spinner black />}
                <Form>
                  <Div row noGutters>
                    {countries.map(country => (
                      <Col colXs={6} colSm={4} key={country.id}>
                        <Form.Field>
                          <Checkbox
                            label={country.name}
                            name='country'
                            value={country.id}
                            checked={this.isCountrySelected(country)}
                            onChange={() => this.handleCountryChange(country)}
                          />
                        </Form.Field>
                      </Col>
                    ))}
                  </Div>
                </Form>
              </Segment>
            }
            {stepActive === 'agegroups' &&
              <Segment>
                {loading !== 0 && <Spinner black />}
                <Form>
                  <Div row noGutters>
                    {agegroups.map(agegroup => (
                      <Col colXs={6} colSm={4} key={agegroup.id}>
                        <Form.Field>
                          <Checkbox
                            label={agegroup.name}
                            name='agegroup'
                            value={agegroup.id}
                            checked={this.isAgegroupSelected(agegroup)}
                            onChange={() => this.handleAgegroupChange(agegroup)}
                          />
                        </Form.Field>
                      </Col>
                    ))}
                  </Div>
                </Form>
              </Segment>
            }
            {stepActive === 'leagues' &&
              <Segment>
                {loading !== 0 && <Spinner black />}
                <Form>
                  <Div row noGutters>
                    {loading === 0 &&
                      <Div colXs={12}>
                        <SearchBar icon='search' placeholder='Search...' onChange={this.filterLeagues} />
                      </Div>
                    }
                    {filteredLeagues.map(league => (
                      <Col colXs={12} colSm={6} key={league.id}>
                        <Form.Field>
                          <Checkbox
                            label={league.name}
                            name='league'
                            value={league.id}
                            checked={this.isLeagueSelected(league)}
                            onChange={() => this.handleLeagueChange(league)}
                          />
                        </Form.Field>
                      </Col>
                    ))}
                  </Div>
                </Form>
              </Segment>
            }
          </Segment>
        </Fragment>
      </Modal>
    );
  }
}

FilterModal.propTypes = {
  modalOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFail: PropTypes.func.isRequired,
  user: PropTypes.object,
  hideShortlisted: PropTypes.bool,
  hideInShortlists: PropTypes.bool,
  hideNotInShortlists: PropTypes.bool,
};

FilterModal.defaultProps = {
  hideShortlisted: false,
  hideInShortlists: false,
  hideNotInShortlists: false,
  user: {},
  disabledFilters: []
};



export default withContext(UserContext, FilterModal);
