import { Checkbox, Input } from 'semantic-ui-react';
import { FlexboxGrid as Div, Themes } from 'baroo';
import 'inobounce';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';

const Col = Div.extend`
  padding: 6px 0;
  ${Themes.Media.sm`
    padding: 14px 0;
    &:nth-child(even){
      padding-right: 32px;
    }
  `}
`;

const SearchBar = styled(Input)`
  margin-bottom: 14px;
  margin-right: 32px;
`;

const FilterCategory = Div.extend`
  margin-bottom: 24px;
  &:last-of-type {
    margin-bottom: 0;
  }
  .ui.form .inline.fields {
    margin-bottom: 0;
  }
`;

const FilterLabel = styled.div`
  font-size: 16px;
  color: #555;
  margin-bottom: 12px;
  font-weight: bold;
`;

const PositionContainer = Div.extend`
  > div {
    height: 100%;
  }
`;

const CheckButton = styled(Checkbox)`
  &.ui.checkbox {
    margin-right: 10px;
    flex-grow: 1;
    &:last-of-type{
      margin-right: 0;
    }
    label {
      width: 58px;
      height: 28px;
      line-height: 28px;
      border-radius: 3px;
      border: solid 1px #474747;
      padding: 0;
      color: #000;
      font-size: 12px;
      text-align: center;
      margin-bottom: 0;
      width: 100%;
      &:after,
      &:before {
        display: none;
      }
    }
  }


  &.ui.checkbox.checked label {
    background-color: #242c39;
    color: white
  }
`;

const Bottom = Div.extend`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const InfoMessage = Div.extend`
  font-size: 10px;
  font-weight: bold;
`;

const Count = Div.extend`
  font-size: 20px;
  font-weight: bold;
`;

export {
  Col,
  SearchBar,
  FilterCategory,
  FilterLabel,
  PositionContainer,
  CheckButton,
  Bottom,
  InfoMessage,
  Count
};
