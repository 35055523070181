import React, { Component, Fragment } from 'react';
import { Icon, Segment } from 'semantic-ui-react';
import dayjs from 'dayjs';
import _ from 'lodash';
import { FlexboxGrid as Div } from 'baroo';
import Spinner from '../components/Spinner';
import { ButtonLight } from '../assets/themes/styles/components/Styled/Styled';
import {
  Table,
  RowHeader,
  RowMatch,
  ClubLogo,
  TeamName,
  SeasonName,
  SectionTitle
} from '../assets/themes/styles/playerProfile/MatchHistoryStep';

const didPlay = (match) => match.status === 'starter' || match.status === 'substitute'

class MatchHistoryStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAllNextMatches: false
    }
  }
  getStatus = (status) => {
    return {
      starter: 'STARTER',
      substitute: 'SUBSTITUTE',
      did_not_play: 'DID NOT PLAY',
      unknown: 'NOT UPDATED',
    }[status]
  }

  toggleShowAllNextMatches = () => {
    this.setState({ showAllNextMatches: !this.state.showAllNextMatches })
  }
  render() {
    const { loading, previousMatches, nextMatches } = this.props;
    const { showAllNextMatches } = this.state;
    let seasons = []
    if (previousMatches && previousMatches.length) {
      seasons = _.toArray(_.groupBy(previousMatches, 'season_name')).map(s => ({
        seasonName: s[0].season_name,
        seasonStartDate: s[0].season_start_date,
        seasonEndDate: s[0].season_end_date,
        matches: s
      }))
      seasons = _.sortBy(seasons, 'seasonEndDate').reverse()
    }

    return (
      <Div row>
        <Div colXs={12} dInlineFlex>
          <Segment style={{ width: '100%' }}>
            {loading && <Spinner />}
            <SectionTitle>Upcoming Matches</SectionTitle>
            {!loading && nextMatches.length === 0 && <Div centerXs>
              No upcoming matches yet
            </Div>}
            {nextMatches.length !== 0 &&
              <Fragment>
                <Table>
                  <tbody>
                    <RowMatch>
                      <td style={{ border: 'none' }}>
                        <Div row betweenXs noGutters>
                          <Div startXs><TeamName style={{ width: '300px' }}><ClubLogo src={nextMatches[0].home_team_logo_url} /> {nextMatches[0].home_team_name}</TeamName></Div>
                          <Div><strong>{dayjs(nextMatches[0].kickoff_at).format('D MMM YYYY - HH:mm')}</strong></Div>
                          <Div endXs><TeamName style={{ width: '300px', textAlign: 'right' }}>{nextMatches[0].away_team_name} <ClubLogo src={nextMatches[0].away_team_logo_url} /></TeamName></Div>
                        </Div>
                      </td>
                    </RowMatch>

                    {showAllNextMatches && nextMatches.map((match, index) => (
                      <Fragment>
                        {index !== 0 &&
                          <RowMatch>
                            <td style={{ border: 'none' }}>
                              <Div row betweenXs noGutters>
                                <Div startXs><TeamName style={{ width: '300px' }} ><ClubLogo src={match.home_team_logo_url} /> {match.home_team_name}</TeamName></Div>
                                <Div><strong>{dayjs(match.kickoff_at).format('D MMM YYYY - HH:mm')}</strong></Div>
                                <Div endXs><TeamName style={{ width: '300px', textAlign: 'right' }}>{match.away_team_name} <ClubLogo src={match.away_team_logo_url} /></TeamName></Div>
                              </Div>
                            </td>
                          </RowMatch>
                        }
                      </Fragment>

                    ))}
                  </tbody>
                </Table>
                <Div centerXs aroundXs>
                  <ButtonLight btnXs onClick={this.toggleShowAllNextMatches} style={{ margin: '8px auto' }}>
                    {!showAllNextMatches && <Fragment><Icon className="icon-caret-down" /><span>Show More</span></Fragment>}
                    {showAllNextMatches && <Fragment><Icon className="icon-caret-up" /><span>Show Less</span></Fragment>}
                  </ButtonLight>
                </Div>
              </Fragment>
            }
          </Segment>
        </Div>
        <Div colXs={12} dInlineFlex>
          <Segment style={{ width: '100%' }}>
            <SectionTitle>Previous Matches</SectionTitle>
            {seasons.map(({ seasonName, matches }) => (
              <Fragment>
                <SeasonName>{seasonName}</SeasonName>
                {matches && matches.length !== 0 &&
                  <Table>
                    <thead>
                      <RowHeader>
                        <th>Date</th>
                        <th>Score</th>
                        <th>Status</th>
                        <th>Position</th>
                        <th>G</th>
                        <th>A</th>
                        <th>Votes</th>
                        <th>Votes %</th>
                        <th>MR</th>
                      </RowHeader>
                    </thead>
                    <tbody>
                      {matches.map((match) => (
                        <RowMatch disabled={['did_not_play', 'unknown'].includes(match.status)}>
                          <td>{dayjs(match.kickoff_at).format('HH:mm')}<br />{dayjs(match.kickoff_at).format('D MMM YYYY')}</td>
                          <td>
                            <Div row betweenXs noGutters style={{ marginBottom: '6px', fontWeight: match.home_team_score > match.away_team_score ? 'bold' : 'normal' }}>
                              <TeamName><ClubLogo src={match.home_team_logo_url} /> {match.home_team_name}</TeamName>
                              <Div>{match.home_team_score}</Div>
                            </Div>
                            <Div row betweenXs noGutters style={{ fontWeight: match.away_team_score > match.home_team_score ? 'bold' : 'normal' }}>
                              <TeamName><ClubLogo src={match.away_team_logo_url} /> {match.away_team_name}</TeamName>
                              <Div>{match.away_team_score}</Div>
                            </Div>
                          </td>
                          <td style={{ width: '130px' }}>{this.getStatus(match.status)}</td>
                          <td>{_.isString(match.position) ? match.position.toUpperCase() : '-'}</td>
                          <td>{didPlay(match) ? match.goals : '-'}</td>
                          <td>{didPlay(match) ? match.assists : '-'}</td>
                          <td>{didPlay(match) ? match.votes : '-'}</td>
                          <td>{didPlay(match) ? match.votes_percentage.toFixed(0) : '-'}</td>
                          <td>{didPlay(match) ? match.rating.toFixed(2) : '-'}</td>
                        </RowMatch>
                      ))}
                    </tbody>
                  </Table>
                }
              </Fragment>
            ))
            }
          </Segment>
        </Div>
      </Div>
    );
  }
}

export default MatchHistoryStep;
