import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UserAvatar from '../../components/UserAvatar';
import Step from './Step';

class DeclinedStep extends Component {
  COLUMNS = [
    {accessor:(d) => <UserAvatar user={d}/>, header: 'Player'},
    {accessor:(d) => {
      if (d.applicant_state === 'player_declined') return 'Player'
      if (d.applicant_state === 'parent_declined') return 'Parent'
      if (d.applicant_state === 'club_declined') return 'Club'
      return 'Other'
    }, header: 'Reason'},
  ];
  
  render() {    
    const {players} = this.props;
    return (
      <Step players={players} columns={this.COLUMNS} headline="You have declined the following players"/>
    );
  }
}

DeclinedStep.propTypes = {
  players: PropTypes.array,
};

DeclinedStep.defaultProps = {
  players: [],
};


export default DeclinedStep;
