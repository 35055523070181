import React, { Component, Fragment } from 'react';
import dayjs from 'dayjs';
import Spinner from '../components/Spinner';
import {
  Table,
  RowHeader,
  RowTotal,
  RowSeasonTotal,
  RowLeague
} from '../assets/themes/styles/playerProfile/CareerStep';

const Stats = ({ stats }) => (
  <Fragment>
    <td>{stats.matches_played || 0}</td>
    <td>{stats.goals || 0}</td>
    <td>{stats.assists || 0}</td>
    <td>{stats.clean_sheets || 0}</td>
    <td>{stats.motm_votes || 0}</td>
    <td>{stats.average_rating ? stats.average_rating.toFixed(1) : 0}</td>
  </Fragment>
);

class CareerStep extends Component {
  render() {
    const { loading, career } = this.props;
    return (
      <Fragment>
        {loading && <Spinner />}
        {career &&
          <Table>
            <thead>
              <RowHeader>
                <th>Season</th>
                <th>Start-end</th>
                <th>Clubs</th>
                <th>Age G.</th>
                <th>M</th>
                <th>G</th>
                <th>A</th>
                <th>CS</th>
                <th>Votes</th>
                <th>MR</th>
              </RowHeader>
            </thead>
            <tbody>
              {career.seasons.map(({ total, teams }) => (
                <Fragment>
                  <RowSeasonTotal>
                    <td>{total.season}</td>
                    <td>{dayjs(total.season_start_date).format('MMM')}-{dayjs(total.season_end_date).format('MMM')}</td>
                    <td title={total.clubs}>{total.clubs}</td>
                    <td>{total.agegroups}</td>
                    <Stats stats={total} />
                  </RowSeasonTotal>
                  {teams.map((team, i) => (
                    <RowLeague even={i % 2 === 0}>
                      <td></td>
                      <td>{dayjs(team.season_start_date).format('MMM')}-{dayjs(team.season_end_date).format('MMM')}</td>
                      <td colSpan="2" style={{ textAlign: 'left' }}>{team.league}</td>
                      <Stats stats={team} />
                    </RowLeague>
                  ))}
                </Fragment>
              ))}
              <RowTotal>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <Stats stats={career.total} />
              </RowTotal>
            </tbody>
          </Table>
        }
      </Fragment>
    );
  }
}

export default CareerStep;
