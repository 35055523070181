import styled from 'styled-components';
import { FlexboxGrid as Div } from 'baroo';

const Table = Div.withComponent('table').extend`
  width: 100%;
  text-align: center;
`;

const Row = Div.withComponent('tr').extend`
  > td,
  > th {
    padding: 12px;

  }
`;

const RowHeader = Row.extend`
  text-transform: uppercase;  
  height: 44px;
`;

const RowMatch = Row.extend`
  &:nth-of-type(odd) {
    background-color: rgb(250, 250, 250);
  }
  opacity: ${props => props.disabled ? 0.5 : 1};
  > td:nth-of-type(1),
  > td:nth-of-type(2),
  > td:nth-of-type(3) {
    border-right: solid 1px #cdcdcd;
  }
`;

const ClubLogo = styled('img')`
  width: 18px;
`;

const TeamName = Div.extend`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 244px;
    max-width: 244px;
    text-align: left;
`;

const SeasonName = Div.extend`
  margin: 36px 0 12px;
  font-weight: bold;
  font-size: 16px;
  &:first-of-type {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
`;

const SectionTitle = SeasonName.extend`
  font-size: 18px;
`;

export {
  Table,
  Row,
  RowHeader,
  RowMatch,
  ClubLogo,
  TeamName,
  SeasonName,
  SectionTitle
};
