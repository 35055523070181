import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Message, Dimmer } from 'semantic-ui-react';
import { Loading } from '../components/Styled';
import Api from '../services/api';
import PlayerProfile from './PlayerProfile';
import Slideshow from '../components/Slideshow';
import SlideshowMatch from '../components/SlideshowMatch';
import { shortlistPlayer, unshortlistPlayer } from '../redux/actions';

import {
  MessageStyled
} from '../assets/themes/styles/playerProfile/PlayerProfileContainer';

class PlayerProfileContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      player: null,
      mediaType: 'all',
      media: null, // Media
      matchMedia: null, // Match Media
      userMedia: null, // User Media
      selectedMedia: null, // Media
      selectedMediaIndex: null, // Media
      selectedMediaSection: null, // Media
      hasNextMediaPage: true, // Media
      isNextMediaPageLoading: false,// Media
      isNextMediaPageScrollLoading: false,// Media
      career: null,
      isCareerLoading: false,
      notes: [],
      isNotesLoading: false,
      previousMatches: [],
      nextMatches: [],
      isMatchesLoading: false,
    }
  }
  async componentDidMount() {
    const playerSlug = this.props.player ? this.props.player.slug : this.props.playerId;
    if (playerSlug) {
      const playerProfile = await Api.playerProfile(playerSlug)
        .then((data) => {
          if (data.response) {
            return data.response;
          } else {
            this.setState({ error: 'Player not found' });
            window.amplitude.getInstance().logEvent('Player profile no data', { slug: playerSlug });
          }
        }).catch(() => {
          this.setState({ error: 'Player not found' });
          window.amplitude.getInstance().logEvent('Player profile not found', { slug: playerSlug });
        });
      if (playerProfile) {
        const playerDetailedProfile = await Api.playerDetailedProfile(playerSlug)
          .then((detailedData) => {
            if (detailedData.response) {
              return detailedData.response;
            } else {
              // this.setState({ error: 'Player Detailed not found' });
              window.amplitude.getInstance().logEvent('Player Detailed profile no data', { slug: playerSlug });
            }
          }).catch(() => {
            // this.setState({ error: 'Player Detailed not found' });
            window.amplitude.getInstance().logEvent('Player Detailed profile not found', { slug: playerSlug });
          });
        playerProfile.detailedData = playerDetailedProfile ? playerDetailedProfile : null;
        await this.initPlayer(playerProfile);
      }
    }
  }

  initPlayer = (player) => {
    this.setState({ player })
    this.loadMedia(player)
    this.loadCareer(player)
    this.loadNotes(player)
    this.loadMatches(player)
  }

  onShortlistClick = () => {
    const player = { ...this.state.player };
    if (player.shortlisted) {
      window.amplitude.getInstance().logEvent('Shortlist removed', { slug: player.slug });
      this.props.unshortlistPlayer(player.user_id);
      Api.del('users/' + player.slug + '/unfollow')
        .catch(e => {
          console.log('fail del shortlist', e);
        })
    } else {
      window.amplitude.getInstance().logEvent('Shortlist added', { slug: player.slug });
      this.props.shortlistPlayer(player.user_id);
      Api.post('users/' + player.slug + '/follow')
        .catch(e => {
          console.log('fail put shortlist', e);
        })
    }
    player.shortlisted = !player.shortlisted;
    this.setState({ player })
  }

  loadCareer = (player) => {
    this.setState({ isCareerLoading: true })
    Api.getCareerOverview(player.slug).then(data => {
      this.setState({ career: data.response, isCareerLoading: false })
    })
      .catch(() => this.setState({ isCareerLoading: false }));
  }

  loadMatches = async (player) => {
    this.setState({ isMatchesLoading: true })
    try {
      const previousMatches = await Api.getMatchHistory(player.slug)
      const nextMatches = await Api.getNextMatches(player.slug)
      this.setState({ previousMatches: previousMatches.response, nextMatches: nextMatches.response, isMatchesLoading: false })
    } catch (error) {
      this.setState({ isMatchesLoading: false })
    }

  }

  loadNotes = (player) => {
    this.setState({ isNotesLoading: true })
    Api.getPlayerNotes(player.slug).then(data => {
      this.setState({ notes: data.response, isNotesLoading: false })
    })
      .catch(() => this.setState({ isNotesLoading: false }))
  }

  onCreateNoteClick = (note) => {
    this.setState({ isNotesLoading: true })
    Api.createPlayerNote(this.state.player.slug, note).then(data => {
      this.setState({ notes: [data.response, ...this.state.notes], isNotesLoading: false })
      if (data.response.media_item && data.response.media_item.type === 'video' && data.response.media_item.status === 'processing') {
        this.pingProcessingVideo(data.response.id)
      }
      window.amplitude.getInstance().logEvent(`Player profile: Note created`, { player: this.state.player.slug, type: note.file_item ? 'file' : note.media_item ? note.media_item.type : 'text' });

    })
      .catch(() => this.setState({ isNotesLoading: false }))
  }

  onUpdateNoteClick = (note) => {
    this.setState({ isNotesLoading: true })
    Api.updatePlayerNote(this.state.player.slug, note).then(data => {
      const index = _.findIndex(this.state.notes, { id: note.id })
      const nextNotes = [...this.state.notes]
      nextNotes.splice(index, 1, data.response)
      this.setState({ notes: nextNotes, isNotesLoading: false })
      window.amplitude.getInstance().logEvent(`Player profile: Note updated`, { player: this.state.player.slug });
    })
      .catch(() => this.setState({ isNotesLoading: false }))
  }

  onDeleteNoteClick = (note) => {
    this.setState({ isNotesLoading: true })
    Api.deletePlayerNote(this.state.player.slug, note).then(data => {
      const nextNotes = [...this.state.notes]
      _.remove(nextNotes, { id: note.id })
      this.setState({ notes: nextNotes, isNotesLoading: false })
      window.amplitude.getInstance().logEvent(`Player profile: Note deleted`, { player: this.state.player.slug });
    })
      .catch(() => this.setState({ isNotesLoading: false }))
  }

  loadMedia = async (player) => {
    this.setState({ isNextMediaPageLoading: true })
    // All Media
    await Api.getMedia(player.slug).then(data => {
      this.setState({
        media: data.response,
        isNextMediaPageLoading: false,
        hasNextMediaPage: data.response.length !== 0
      });

    });

    // User Media
    await Api.getMedia(player.slug, { include_only_user_videos: true }).then(data => {
      this.setState({
        userMedia: data.response,
        isNextMediaPageLoading: false,
        hasNextUserMediaPage: data.response.length !== 0
      });
    });

    // Match Media
    await Api.getMedia(player.slug, { include_only_match_videos: true }).then(data => {
      this.setState({
        matchMedia: data.response,
        isNextMediaPageLoading: false,
        hasNextMatchMediaPage: data.response.length !== 0
      });
    });

    this.setState({ isNextMediaPageLoading: false });
  }
  onMediaTypeChange = (type) => {
    this.setState({ mediaType: type })
  }
  loadNextMediaPage = async () => {
    window.amplitude.getInstance().logEvent('Load next media page');
    const { media, player } = this.state
    await this.setState({ isNextMediaPageScrollLoading: true })
    await Api.getMedia(player.slug, { current_media_item_id: _.last(media).id }).then(data => {
      if (data.response.length) {
        this.setState({ media: [...media, ...data.response], isNextMediaPageScrollLoading: false });
      } else {
        this.setState({ hasNextMediaPage: false, isNextMediaPageScrollLoading: false })
      }
    })
  }

  loadNextUserMediaPage = async () => {
    window.amplitude.getInstance().logEvent('Load next user media page');
    const { userMedia, player } = this.state;
    await this.setState({ isNextMediaPageScrollLoading: true });
    await Api.getMedia(player.slug, { include_only_user_videos: true, current_media_item_id: _.last(userMedia).id }).then(data => {
      if (data.response.length) {
        this.setState({ userMedia: [...userMedia, ...data.response], isNextMediaPageScrollLoading: false })
      } else {
        this.setState({ hasNextUserMediaPage: false, isNextMediaPageScrollLoading: false })
      }
    })
  }

  loadNextMatchMediaPage = async () => {
    window.amplitude.getInstance().logEvent('Load next match media page');
    const { matchMedia, player } = this.state
    await this.setState({ isNextMediaPageScrollLoading: true });
    await Api.getMedia(player.slug, { include_only_match_videos: true, current_media_item_id: _.last(matchMedia).id }).then(data => {
      if (data.response.length) {
        this.setState({ matchMedia: [...matchMedia, ...data.response], isNextMediaPageScrollLoading: false })
      } else {
        this.setState({ hasNextMatchMediaPage: false, isNextMediaPageScrollLoading: false })
      }
    })
  }
  getMatchDetails = async (media) => {
    this.setState({ isNextMediaPageScrollLoading: true });
    return await Api.getMatches(media.author.slug).then(data => {
      return data.response;
    });
  }
  onMediaClick = async (mediaItem, source = 'player', activeSection = null) => {
    const { mediaType, media, userMedia, matchMedia } = this.state;
    let stateMedia = media;
    if (mediaType === 'user') {
      stateMedia = userMedia;
    } else if (mediaType === 'match') {
      stateMedia = matchMedia;
    } else {
      stateMedia = media;
    }
    if (source === 'player') {
      const selectedMediaIndex = _.findIndex(stateMedia, (m) => m.id === mediaItem.id);
      if (mediaItem.author.type === 'match') {
        mediaItem.matchDetails = await this.getMatchDetails(mediaItem);
      }
      this.setState({ selectedMedia: mediaItem, selectedMediaIndex, selectedMediaSource: source, selectedMediaSection: activeSection })
    }
    if (source === 'notes') {
      const { notes } = this.state;
      const notesMedias = notes.filter(n => !!n.mediaItem)
      const selectedMediaIndex = _.findIndex(notesMedias, (m) => m.media_item.id === mediaItem.id)
      this.setState({ selectedMedia: mediaItem, selectedMediaIndex, selectedMediaSource: source })
    }
    window.amplitude.getInstance().logEvent(`Player profile: Media opened`, { player: this.state.player.slug, media_id: mediaItem.id, media_type: mediaItem.type, source });
  }

  onNoteMediaClick = (media) => {
    this.onMediaClick(media, 'notes')
  }

  onPlayerMediaClick = (media, activeSection) => {
    this.onMediaClick(media, 'player', activeSection)
  }

  onPreviousClick = async () => {
    const { selectedMediaIndex, mediaType, media, userMedia, matchMedia, notes, selectedMediaSource } = this.state;
    let stateMedia = media;
    if (mediaType === 'user') {
      stateMedia = userMedia;
    } else if (mediaType === 'match') {
      stateMedia = matchMedia;
    } else {
      stateMedia = media;
    }
    let nextSelectedMediaIndex, nextMedia;

    if (selectedMediaSource === 'player') {
      nextSelectedMediaIndex = selectedMediaIndex === 0 ? stateMedia.length - 1 : selectedMediaIndex - 1;
      nextMedia = stateMedia[nextSelectedMediaIndex];
      if (nextMedia.author.type === 'match') {
        nextMedia.matchDetails = await this.getMatchDetails(nextMedia);
      }
    }

    if (selectedMediaSource === 'notes') {
      const notesMedias = notes.filter(n => !!n.media_item)
      nextSelectedMediaIndex = selectedMediaIndex === 0 ? notesMedias.length - 1 : selectedMediaIndex - 1;
      nextMedia = notesMedias[nextSelectedMediaIndex].media_item
    }

    this.setState({ selectedMedia: nextMedia, selectedMediaIndex: nextSelectedMediaIndex })
    window.amplitude.getInstance().logEvent('Player profile: Media opened', { player: this.state.player.slug, media_id: nextMedia.id, media_type: nextMedia.type, source: selectedMediaSource });

  }

  onNextClick = async () => {
    const { selectedMediaIndex, mediaType, media, userMedia, matchMedia, notes, selectedMediaSource } = this.state;
    let stateMedia = media;
    if (mediaType === 'user') {
      stateMedia = userMedia;
    } else if (mediaType === 'match') {
      stateMedia = matchMedia;
    } else {
      stateMedia = media;
    }
    let nextSelectedMediaIndex, nextMedia;
    if (selectedMediaSource === 'player') {
      nextSelectedMediaIndex = selectedMediaIndex === stateMedia.length - 1 ? 0 : selectedMediaIndex + 1;
      nextMedia = stateMedia[nextSelectedMediaIndex];
      if (nextMedia.author.type === 'match') {
        nextMedia.matchDetails = await this.getMatchDetails(nextMedia);
      }
    }

    if (selectedMediaSource === 'notes') {
      const notesMedias = notes.filter(n => !!n.media_item)
      nextSelectedMediaIndex = selectedMediaIndex === notesMedias.length - 1 ? 0 : selectedMediaIndex + 1;
      nextMedia = notesMedias[nextSelectedMediaIndex].media_item
    }
    this.setState({ selectedMedia: nextMedia, selectedMediaIndex: nextSelectedMediaIndex })
    window.amplitude.getInstance().logEvent('Player profile: Media opened', { player: this.state.player.slug, media_id: nextMedia.id, media_type: nextMedia.type, source: selectedMediaSource });
  }

  closeMedia = () => {
    this.setState({ selectedMedia: null, selectedMediaSource: null })
    window.amplitude.getInstance().logEvent('Player profile: Media closed');
  }

  pingProcessingVideo = async (noteId) => {
    const { response } = await Api.getPlayerNotes(this.state.player.slug)
    const processingVideo = response.find(n => n.id === noteId)
    if (processingVideo && processingVideo.media_item.status !== 'processing') {
      const newNotes = this.state.notes.map(n => {
        if (n.id === noteId) {
          return processingVideo
        } else {
          return n
        }
      })
      this.setState({ notes: newNotes })
    } else {
      setTimeout(() => this.pingProcessingVideo(noteId), 500)
    }
  }

  render() {
    const { dark, inModal, standalone, activeStep } = this.props
    const {
      player,
      mediaType,
      media,
      userMedia,
      matchMedia,
      selectedMedia,
      hasNextMediaPage,
      hasNextUserMediaPage,
      hasNextMatchMediaPage,
      isNextMediaPageLoading,
      isNextMediaPageScrollLoading,
      career,
      isCareerLoading,
      previousMatches,
      nextMatches,
      isMatchesLoading,
      notes,
      isNotesLoading,
      error
    } = this.state;

    const zIndex = this.props.zIndex + 1;
    const loading = isNextMediaPageLoading || isCareerLoading || isMatchesLoading || isNotesLoading;

    if (loading || (!player && !error)) {
      return <Loading customMarginTop={'28%'} text="Loading player profile..." />;
    }

    return (
      <Fragment>
        {player &&
          <PlayerProfile
            player={player}
            onShortlistClick={this.onShortlistClick}
            mediaType={mediaType}
            media={media}
            userMedia={userMedia}
            matchMedia={matchMedia}
            onMediaTypeChange={this.onMediaTypeChange}
            onMediaClick={this.onPlayerMediaClick}
            loadNextMediaPage={this.loadNextMediaPage}
            loadNextUserMediaPage={this.loadNextUserMediaPage}
            loadNextMatchMediaPage={this.loadNextMatchMediaPage}
            hasNextMediaPage={hasNextMediaPage}
            hasNextUserMediaPage={hasNextUserMediaPage}
            hasNextMatchMediaPage={hasNextMatchMediaPage}
            isNextMediaPageLoading={isNextMediaPageLoading}
            isNextMediaPageScrollLoading={isNextMediaPageScrollLoading}
            career={career}
            isCareerLoading={isCareerLoading}
            previousMatches={previousMatches}
            nextMatches={nextMatches}
            isMatchesLoading={isMatchesLoading}
            notes={notes}
            isNotesLoading={isNotesLoading}
            onCreateNoteClick={this.onCreateNoteClick}
            onDeleteNoteClick={this.onDeleteNoteClick}
            onUpdateNoteClick={this.onUpdateNoteClick}
            onNoteMediaClick={this.onNoteMediaClick}
            dark={dark ? dark.toString() : 'false'}
            inModal={inModal}
            standalone={standalone}
            activeStep={!activeStep ? 'profile' : activeStep}
          />
        }
        {selectedMedia && selectedMedia.author.type === 'match' &&
          <Fragment>
            <Dimmer active page>
              <SlideshowMatch
                open={!!selectedMedia}
                onClose={this.closeMedia}
                media={selectedMedia}
                onNextClick={this.onNextClick}
                onPreviousClick={this.onPreviousClick}
                zIndex={zIndex}
              />
            </Dimmer>
          </Fragment>
        }
        {selectedMedia && selectedMedia.author.type !== 'match' &&
          <Fragment>
            <Dimmer active page>
              <Slideshow
                open={!!selectedMedia}
                onClose={this.closeMedia}
                media={selectedMedia}
                onNextClick={this.onNextClick}
                onPreviousClick={this.onPreviousClick}
              />
            </Dimmer>
          </Fragment>
        }

        {error &&
          <MessageStyled negative
            style={{
              left: '50%',
              transform: 'translateX(-50%)',
              position: 'fixed',
              top: '50%',
              zIndex: '1000',
            }}
          >
            <Message.Header>The player you are looking for doesn't exist</Message.Header>
          </MessageStyled>
        }
      </Fragment>
    );
  }
}

export default connect(null, { shortlistPlayer, unshortlistPlayer })(PlayerProfileContainer);
