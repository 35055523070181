import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css';
import { Icon } from 'semantic-ui-react';
import { FlexboxGrid as Div } from 'baroo';
import logo from '../../assets/images/logoplayerdata_white.png';

import HLSSource from '../HLSSource';
import {
  ModalStyled,
  ArrowLeft,
  ArrowRight,
  Footer,
  Content,
  Label,
  Media,
  MediaContainer,
  Close,
  Header
} from '../../assets/themes/styles/components/Slideshow/Slideshow';


class Slideshow extends Component {

  componentDidMount() {
    if (this.props.open) {
      window.addEventListener('keydown', this.handleKeyboardInput);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.open && nextProps.open) {
      window.addEventListener('keydown', this.handleKeyboardInput);
    }
    if (!nextProps.open) {
      window.removeEventListener('keydown', this.handleKeyboardInput);
    }
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyboardInput);
  }



  handleKeyboardInput = (event) => {
    const { onNextClick, onPreviousClick } = this.props;
    if (event.keyCode === 37) { // left
      onPreviousClick();
      return true;
    } else if (event.keyCode === 39) { // right
      onNextClick();
      return true;
    }
    return false;
  }
  render() {
    const { media, onNextClick, onPreviousClick, onClose, open, ...etc } = this.props;
    const footerStyle = {
      textAlign: 'left',
      margin: 'auto',
    };
    if (media.width !== 0) {
      footerStyle.maxWidth = media.width;
    }
    return (
      <Fragment>
        {open && <MediaContainer>
          <div>
            <ArrowLeft onClick={onPreviousClick}>
              <span>
                <svg fill="white" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 512 512" xmlSpace="preserve">
                  <path d="M213.7,256L213.7,256L213.7,256L380.9,81.9c4.2-4.3,4.1-11.4-0.2-15.8l-29.9-30.6c-4.3-4.4-11.3-4.5-15.5-0.2L131.1,247.9 c-2.2,2.2-3.2,5.2-3,8.1c-0.1,3,0.9,5.9,3,8.1l204.2,212.7c4.2,4.3,11.2,4.2,15.5-0.2l29.9-30.6c4.3-4.4,4.4-11.5,0.2-15.8 L213.7,256z" />
                </svg>
              </span>
            </ArrowLeft>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', backgroundColor: '#111111', padding: '38px', borderRadius: '8px' }}>
              <Header>
                <Div style={{
                  position: 'absolute',
                  top: '15px',
                  left: '15px'
                }}>
                  <Link to="/discover/country"><img style={{ height: '30px' }} alt="logo" src={logo} /></Link>
                </Div>
                <Div>
                  <Close onClick={() => onClose()}><Icon className={'close'} /></Close>
                </Div>
              </Header>
              <div style={{ flex: '0 0 100%', display: 'flex', alignSelf: 'center', justifyContent: 'center' }}>
                <Media>
                  <img src={media.thumbnail_url || media.url} alt="Media Url" />
                  {media.type === 'video' &&
                    <Player poster={media.thumbnail_url}>
                      <HLSSource
                        isVideoChild
                        src={media.url}
                      />
                    </Player>
                  }
                </Media>
              </div>
              <div style={footerStyle}>
                <Footer {...footerStyle}>
                  <Label>{media.description}</Label>
                  <Content>{moment(media.publish_at).fromNow()}{media.like && media.like.count ? ` • ${media.like.count} likes` : ''}{media.views_count ? ` • ${media.views_count} views` : ''}</Content>
                </Footer>
              </div>
            </div>

            <ArrowRight onClick={onNextClick}>
              <span>
                <svg fill="white" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 512 512" xmlSpace="preserve">
                  <path d="M298.3,256L298.3,256L298.3,256L131.1,81.9c-4.2-4.3-4.1-11.4,0.2-15.8l29.9-30.6c4.3-4.4,11.3-4.5,15.5-0.2l204.2,212.7 c2.2,2.2,3.2,5.2,3,8.1c0.1,3-0.9,5.9-3,8.1L176.7,476.8c-4.2,4.3-11.2,4.2-15.5-0.2L131.3,446c-4.3-4.4-4.4-11.5-0.2-15.8 L298.3,256z" />
                </svg>
              </span>
            </ArrowRight>
          </div>

        </MediaContainer>}
      </Fragment>
    );
  }
}


export default Slideshow;
