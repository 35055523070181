import common from './common';
const isStaging = window && window.location && window.location.host.includes('staging-discover')
let env = null;
if (process.env.NODE_ENV === 'test') {
    env = 'development';
} else {
    env = isStaging ? 'staging' : process.env.NODE_ENV || 'development';
}
const config = require(`./${env}`).default;

export default Object.assign({}, common, config);
