import styled from 'styled-components';
import { Header } from 'semantic-ui-react';
import { FlexboxGrid as Div } from 'baroo';

const CategoryCol = Div.extend`
  margin-bottom: 32px;
`;
const Label = styled.div`
  font-weight: bold;
  color: #242c39;
`;
const Description = styled.div`
  color: #7f7f7f;
`;
const ApplicantCount = styled.div`
  margin-left: 24px;
  font-size: 14px;
  &:first-of-type {
    margin-left: 0;
  }
`;

const HeaderStyled = styled(Header)`
  &.ui.header {
    margin: 0;
  }
`;

export {
  CategoryCol,
  Label,
  Description,
  ApplicantCount,
  HeaderStyled,
};
