import styled, { css } from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { FlexboxGrid as Div, Themes } from 'baroo';

const VerticalDivider = Div.extend`
  margin: 0 12px;
  height: 40px;
  width: 1px;
  background: #ddd;
`;

const DataLoading = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  flex-direction: column;
  background: rgba(0,0,0,.80);
  z-index: 1;
`;

const Avatar = styled(Image)`
  &.ui.avatar.image {
  margin-right: 10px;
  width: 32px;
  height: 32px;
  background: #b1b1b1;
  }
`;

const TextCenter = styled.div`
  text-align: center;
`;
const TextRight = styled.div`
  text-align: right;
`;

const ButtonLight = styled.button`
  font-size: 16px;
  font-weight: bold;
  color: #000;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  &:hover {
    opacity: 0.6;
  }
  &:focus {
    outline: none;
  }
  span {
    display: none;
    margin-left: 10px;
  }
  i.large.icon {
    margin-right: 0;
  }
  ${Themes.Media.sm`
    span {
      display: inline;
    }
  `}
  ${(props) => props.btnXs && css`
    font-size: 12px;
    span {
      margin-left: 0px;
    }
    .i.icon {
      margin: 0;
  }
  `}
`;

const PlayerProfileWraper = Div.extend`
  &.ui.right.sidebar {
    width: 1000px;
    max-width: 100%;
    height: 100%;
    background: #fff;
    padding: 0;
    .ui.header {
      margin-top: 0;
      padding: 0 32px;
    }
    .ui.steps {
      padding: 0 32px;
    }
  }
`;

const CloseIcon = styled(Icon)`
  &.icon {
    margin: 0;
    font-size: 16px;
    color: black;
    cursor: pointer;
    right: 32px;
    position: absolute;
    z-index: 999;
  }
`;

const Logo = styled.img`
  height: ${(props) => props.height}
  @keyframes zoominoutsinglefeatured {
    0% {
      opacity: 0.1;
    }
    10% {
      opacity: 0.2;
    }
    20% {
      opacity: 0.4;
    }
    30% {
      opacity: 0.6;
    }
    40% {
      opacity: 0.8;
    }
    50% {
      opacity: 1;
    }
    60% {
      opacity: 0.8;
    }
    70% {
      opacity: 0.6;
    }
    80% {
      opacity: 0.4;
    }
    90% {
      opacity: 0.2;
    }
    100% {
      opacity: 0.1;
    }
  } 
  animation: zoominoutsinglefeatured 1.5s infinite;
`;

const LoadingDiv = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  flex-direction: column;
  background: #000000;
  z-index: 1;
`;

export {
  VerticalDivider,
  DataLoading,
  Avatar,
  TextCenter,
  TextRight,
  ButtonLight,
  PlayerProfileWraper,
  CloseIcon,
  Logo,
  LoadingDiv
};