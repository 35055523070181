import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UserAvatar from '../../components/UserAvatar';
import Step from './Step';

class InivtedStep extends Component {
  COLUMNS = [
    {accessor:(d) => <UserAvatar user={d}/>, header: 'Player'},
    {accessor:(d) => {
      if (d.applicant_state === 'selected') return ''
      if (d.applicant_state === 'player_declined') return '❌'
      return '✔️'
    }, header: 'Invite Status'},
  ];
  
  render() {    
    const {players} = this.props;
    return (
      <Step players={players} columns={this.COLUMNS} headline="Pending players must accept their invitation before you contact their club and parents."/>
    );
  }
}

InivtedStep.propTypes = {
  players: PropTypes.array,
};

InivtedStep.defaultProps = {
  players: [],
};


export default InivtedStep;
