import React, { Component } from 'react';
import dayjs from 'dayjs';
import { Button, FlexboxGrid as Div } from 'baroo';
import { Icon, Progress } from 'semantic-ui-react';
import Spinner from '../components/Spinner';
import config from '../config/';
import fileLogo from '../assets/images/file.png';
import { ButtonLight } from '../assets/themes/styles/components/Styled/Styled';
import {
  Container,
  SubmitButton,
  CancelButton,
  Note,
  Form,
  TextareaStyled,
  Image,
  MediaContainer,
  File,
  InputFile,
  ConfirmStyled
} from '../assets/themes/styles/playerProfile/NotesStep';

class NotesStep extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newComment: '',
      updateComments: {},
      noteUpdating: null,
      confirmDeleteNote: null,
      uploadingFile: false,
      uploadProgress: null,
      uploadError: null
    }
  }

  handleFileChange = (e) => {
    // upload file to AWS S3
    // see https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/s3-example-photo-album.html

    const s3 = new window.AWS.S3({
      apiVersion: '2006-03-01',
      params: { Bucket: config.AWS.inputBucket }
    });
    const file = e.target.files[0];
    if (!file) {
      return
    }
    const type = file.type.startsWith('image') ? 'image' : file.type.startsWith('video') ? 'video' : 'file'
    if (!type) {
      return
    }
    this.setState({ uploadingFile: true, uploadProgress: 0, uploadError: null })
    const fileName = file.name.replace(/\s/g, '-').split('.');
    const fileExtension = fileName.pop()
    const now = dayjs().format('DDMMYYHHmm');
    s3.upload({
      Key: fileName.join('.') + '-' + now + '.' + fileExtension,
      Body: file,
      ACL: 'public-read'
    }).on('httpUploadProgress', (progress) => {
      this.setState({ uploadProgress: Math.round(100 * progress.loaded / progress.total) })
    }).send((err, data) => {
      if (err) {
        console.log('ERROR upload file', err)
        this.setState({ uploadingFile: false, uploadProgress: 0, uploadError: err })
      } else {
        if (type === 'file') {
          this.props.onCreateNoteClick({ file_item: { url: data.Location } })
        } else {
          this.props.onCreateNoteClick({ media_item: { amazon_object_key: data.key || data.Key, type: type } })
        }
        this.setState({ uploadingFile: false, uploadProgress: 0 })
      }
    })
  }

  handleCommentChange = (noteId, text) => {
    if (noteId) {
      this.setState({ updateComments: Object.assign({}, this.state.updateComments, { [noteId]: text }) })
    } else {
      this.setState({ newComment: text });
    }
  }

  onSubmitNewComment = (e) => {
    e.preventDefault()
    const { newComment } = this.state;
    if (newComment && newComment.trim().length) {
      this.props.onCreateNoteClick({ text: this.state.newComment })
      this.setState({ newComment: '' })
    }
  }

  onSubmitUpdateComment = (e) => {
    e.preventDefault()
    const { updateComments, noteUpdating } = this.state;
    if (updateComments && updateComments[noteUpdating] && updateComments[noteUpdating].trim().length) {
      this.props.onUpdateNoteClick({ id: noteUpdating, text: updateComments[noteUpdating] })
      const nextUpdateComments = Object.assign({}, this.state.updateComments);
      delete nextUpdateComments[noteUpdating];
      this.setState({ noteUpdating: null, updateComments: nextUpdateComments })
    }
  }

  onEditCommentClicked = (note) => {
    this.setState({
      updateComments: Object.assign({}, { [note.id]: note.text }, this.state.updateComments),
      noteUpdating: note.id
    }) // don't erase edit not saved
  }

  onDeleteCommentClicked = (note) => {
    this.props.onDeleteNoteClick(note)
    this.setState({ confirmDeleteNote: null })
  }

  render() {
    const { loading, notes, onMediaClick, standalone } = this.props;
    const { newComment, noteUpdating, updateComments, confirmDeleteNote, uploadingFile, uploadProgress } = this.state;

    return (
      <Container>
        {loading && <Spinner />}
        <Div row bottomXs>
          <Div colSm={10} colXs={12}>
            <Form onSubmit={this.onSubmitNewComment} style={{ paddingBottom: '24px' }}>
              <TextareaStyled placeholder="Add a comment... " disabled={loading || noteUpdating || uploadingFile} value={newComment} onChange={(event) => this.handleCommentChange(null, event.target.value)} />
              <InputFile>
                <input type="file" onChange={this.handleFileChange} disabled={loading || noteUpdating || uploadingFile} />
                <Icon className="icon-upload" /> Upload
              </InputFile>
              <SubmitButton type="submit" disabled={loading || noteUpdating || uploadingFile}>Post</SubmitButton>
              {uploadingFile && <Progress percent={uploadProgress} indicating progress style={{ marginTop: '1.5em' }} />}
            </Form>
            {notes.map(note => (
              <Note key={note.id}>
                <Note.Left>
                  <img src={note.creator.profile_picture} alt="Profile Pciture" />
                </Note.Left>
                {noteUpdating !== note.id && <Note.Content>
                  <Note.Title>{note.creator.firstname} {note.creator.lastname}</Note.Title>
                  {!note.media_item && !note.file_item && <Note.Body>{note.text}</Note.Body>}
                  {!!note.media_item && note.media_item.status !== 'processing' &&
                    <Note.Body>
                      <Image onClick={() => onMediaClick(note.media_item)} src={note.media_item.thumbnail_url || note.media_item.url}>
                        <Icon className={note.media_item.type === 'video' ? 'icon-play-circle' : 'icon-enlarge'} />
                      </Image>
                    </Note.Body>
                  }
                  {!!note.media_item && note.media_item.status === 'processing' &&
                    <Note.Body>
                      <MediaContainer>
                        <Spinner />
                        Processing video
                  </MediaContainer>
                    </Note.Body>}
                  {!!note.file_item &&
                    <File href={note.file_item.url} target="_blank">
                      <img src={fileLogo} alt="File Logo" />
                      <Div>{note.file_item.url.split('/').pop()}</Div>
                    </File>
                  }
                </Note.Content>}
                {noteUpdating === note.id && <Note.Content>
                  <Note.Title>{note.creator.firstname} {note.creator.lastname}</Note.Title>
                  <Form onSubmit={this.onSubmitUpdateComment}>
                    <TextareaStyled disabled={loading} value={updateComments[note.id]} onChange={(event) => this.handleCommentChange(note.id, event.target.value)} />
                    <CancelButton btnSm btnOutlineReverse onClick={() => this.setState({ noteUpdating: null })}>Cancel</CancelButton>
                    <SubmitButton type="submit" disabled={loading} btnSm>Save</SubmitButton>
                  </Form>
                </Note.Content>}
                <Note.Right>
                  <Note.Date>{dayjs(note.created_at).format('DD/MM/YYYY')}</Note.Date>
                  {noteUpdating !== note.id && <Note.Controls>
                    {!note.media_item && !note.file_item && <ButtonLight onClick={() => this.onEditCommentClicked(note)} disabled={loading}><Icon className="icon-edit" size='normal' /> </ButtonLight>}
                    <ButtonLight onClick={() => this.setState({ confirmDeleteNote: note })} disabled={loading}><Icon className="icon-delete " size='normal' /> </ButtonLight>
                  </Note.Controls>}
                </Note.Right>
              </Note>
            ))}
            <ConfirmStyled
              header="Are you sure you want to delete this note?"
              open={!!confirmDeleteNote}
              confirmButton={<Button style={{ marginLeft: '24px' }}>Delete</Button>}
              cancelButton={<Button btnOutlineReverse>Cancel</Button>}
              content={confirmDeleteNote && (
                confirmDeleteNote.text ||
                (confirmDeleteNote.media_item && <img src={confirmDeleteNote.media_item.thumbnail_url || confirmDeleteNote.media_item.url} alt="Media Item" />) ||
                (confirmDeleteNote.file_item && confirmDeleteNote.file_item.url.split('/').pop()) || ''
              )}
              onCancel={() => this.setState({ confirmDeleteNote: null })}
              onConfirm={() => this.onDeleteCommentClicked(confirmDeleteNote)}
            />
          </Div>
        </Div>
      </Container>
    );
  }
}

export default NotesStep;
