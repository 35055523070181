import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, withRouter, Switch } from 'react-router-dom';
import LandingPage from './landing/LandingPage';
import LoginPage from './auth/LoginPage';
import LogoutPage from './auth/LogoutPage';
import isLoggedHOC from './hoc/isLoggedHOC';
import withContext from './hoc/withContext';
import PlayerProfileStandalonePage from './playerProfile/PlayerProfileStandalonePage';
import EventsListPage from './events/EventsListPage';
import EventDetailPage from './events/EventDetailPage';
import SkillsListPage from './skills/SkillsListPage';
import ShortlistListPage from './shortlists/ShortlistListPage';
import ShortlistCreate from './shortlists/ShortlistCreate';
import ShortlistDetailPage from './shortlists/ShortlistDetailPage';
import ShortlistedPage from './shortlisted/ShortlistedPage';
import UserContext from './contexts/user';

class MyRoutes extends Component {
  componentDidMount() {
    this.onRouteChanged();
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }
  onRouteChanged() {
    setTimeout(() => {
      // HACK wait user to be identified
      window.ga('send', 'pageview', this.props.location.pathname);
    }, 1000);
  }

  render() {
    return (
      <Switch>
        <Route exact path="/" component={withContext(UserContext, isLoggedHOC(LandingPage, { redirect: false }))} />
        <Route exact path="/player/:id" component={withContext(UserContext, isLoggedHOC(LandingPage, { redirect: true }))} />
        {/* <Route path="/player/:id" component={withContext(UserContext, isLoggedHOC(PlayerProfilePage))} /> */}
        <Route path="/player/:id/standalone" component={withContext(UserContext, isLoggedHOC(PlayerProfileStandalonePage))} />
        <Route exact path="/events" component={withContext(UserContext, isLoggedHOC(EventsListPage))} />
        <Route exact path="/shortlist" component={withContext(UserContext, isLoggedHOC(ShortlistedPage))} />
        <Route exact path="/shortlists" component={withContext(UserContext, isLoggedHOC(ShortlistListPage))} />
        <Route exact path="/shortlists/new" component={withContext(UserContext, isLoggedHOC(ShortlistCreate))} />
        <Route exact path="/shortlists/:id" component={withContext(UserContext, isLoggedHOC(ShortlistDetailPage))} />
        <Route exact path="/shortlists/:id/update" component={withContext(UserContext, isLoggedHOC(ShortlistCreate))} />
        <Route exact path="/skills" component={withContext(UserContext, isLoggedHOC(SkillsListPage))} />
        <Route path="/events/:id" component={withContext(UserContext, isLoggedHOC(EventDetailPage))} />
        <Route path="/login" component={LoginPage} />
        <Route path="/logout" component={LogoutPage} />
      </Switch>

    );
  }
}

MyRoutes.propTypes = { location: PropTypes.object.isRequired };

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { user: null };
  }
  render() {
    const Routes = withRouter(MyRoutes);
    const userState = {
      user: this.state.user,
      setUser: (user) => {
        this.setState({ user });
      },
    };
    return (
      <UserContext.Provider value={userState}>
        <Router>
          <Routes></Routes>
        </Router>
      </UserContext.Provider>
    );
  }
}
export default App;
