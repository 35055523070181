import { Themes } from 'baroo';
import { Segment } from 'semantic-ui-react';
import styled from 'styled-components';

const SegmentStyled = styled(Segment)`
&.ui.attached.segment {
  width: auto;
  max-width: unset;
  padding: 0 !important;
  margin-left: -16px;
  margin-right: -16px;
  ${Themes.Media.sm`
    margin-left: -32px;
    margin-right: -32px;
  `}
}
`;

const Headline = styled.div`
  padding: 16px;
  font-weight: bold;
  ${Themes.Media.sm`
    padding-left: 32px;
    padding-right: 32px;
  `}
`;

export {
  SegmentStyled,
  Headline
};
