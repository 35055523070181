import { FlexboxGrid as Div } from 'baroo';
import { css } from 'styled-components';
import pitchlines from  '../../../../images/pitchlines.png';
import pitchlinesVertical from '../../../../images/pitchlines-vertical.png';

const PitchContainer = Div.extend`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background: url(${pitchlines}) center center no-repeat;
  background-size: contain;
  height: 270px;
  width: 419px;
  max-width: 100%;
  ${(props) => props.vertical && css`
    background: url(${pitchlinesVertical}) center center no-repeat;
    background-size: contain;
    flex-direction: column-reverse;
    height: 338px;
  `}
`;
const Row = Div.extend`
  display: flex;
  flex-direction: ${(props) => (props.vertical ? 'row' : 'column')};
  justify-content: space-around;
  align-items: center;
`;
const Position = Div.extend`
  height: 36px;
  width: 36px;
  border: solid 1px #242c39;
  display: flex;
  flex-direction: ${(props) => (props.vertical ? 'row' : 'column')};
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-radius: 50%;
  cursor: pointer;
  background: ${(props) => (props.checked ? '#242c39' : '#f0f2f3')};
  color: ${(props) => (props.checked ? 'white' : 'black')};
  &:hover{
    opacity: 0.7;
  }
`;

export {
  PitchContainer,
  Row,
  Position,
};
