import styled, { css } from 'styled-components';
import { Header, Image } from 'semantic-ui-react';
import { FlexboxGrid as Div } from 'baroo';
import { Link } from 'react-router-dom';

const ImageStyled = styled(Image)`
  width: 80px;
  height: 80px;
  margin-right: 24px;
`;

const PlayerName = Div.extend`
  font-size:38px;
  font-weight: normal;
  line-height: 1.05;
  color: ${(props) => (props.dark ? 'white' : 'black')};
`;
const ClubName = Div.extend`
    font-size: 16px;
    line-height: 1.25;
    font-weight: normal;
    margin-top: 8px;
    color: ${(props) => (props.dark ? '#c2ccca' : '#555555')};
`;

const HeaderStyled = styled(Header)`
  &.ui.header { 
    height: 128px;  
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: ${(props) => (props.dark ? 'black' : 'white')};
    margin-bottom: 0;
    ${(props) => props.standalone && css`
      padding-left: 10px;
      padding-right: 10px;
    `}
  }
`;
const LinkStyled = styled(Link)`
  color: #00FFC8;
  &:hover {
    text-decoration: none;
    opacity: 0.6;
    color: #dbdbdb;
  }
`;

const Actions = Div.extend`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 160px;
`;

export {
  ImageStyled,
  PlayerName,
  ClubName,
  HeaderStyled,
  LinkStyled,
  Actions
};