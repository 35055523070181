import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Icon, Grid, Transition, Popup } from 'semantic-ui-react';
import { Player, BigPlayButton, ControlBar, ReplayControl, ForwardControl } from 'video-react';
import 'video-react/dist/video-react.css';
import HLSSource from '../HLSSource';
import PlayerProfileContainer from '../../playerProfile/PlayerProfileContainer';
import Modal from '../Modal';
import ScoreHeader from './ScoreHeader';
import LineUp from './LineUp';
import { FlexboxGrid as Div } from 'baroo';
import Spinner from '../../components/Spinner';
import logo from '../../assets/images/logoplayerdata_white.png';

import {
  MediaContainer,
  MediaWrapper,
  Media,
  MediaImage,
  FlexContainer,
  Close,
  Header,
  ArrowLeft,
  ArrowRight,
  FixedMediaDiv,
  LinupIconContainer,
  LinupIcon
} from '../../assets/themes/styles/components/SlideshowMatch/SlideshowMatch';


class SlideshowMatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seletectedPlayer: null,
      activeStep: 'profile',
      rightPanelOpen: false,
      pausePlayer: false,
      loading: true,
      playerData: [],
      lineUpVisible: true,
      loadingTransition: false
    };
  }
  async componentDidMount() {
    await this.orchestrateData();
    if (this.props.open) {
      window.addEventListener('keydown', this.handleKeyboardInput);
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.media.id !== this.props.media.id) {
      this.orchestrateData();
      this.setState({
        lineUpVisible: true,
        loadingTransition: false
      });
    }
    if (prevProps.open && this.props.open) {
      window.addEventListener('keydown', this.handleKeyboardInput);
    }
    if (!this.props.open) {
      window.removeEventListener('keydown', this.handleKeyboardInput);
    }
  }
  componentWillReceiveProps(nextProps) {

  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyboardInput);
  }

  handleKeyboardInput = (event) => {
    const { onNextClick, onPreviousClick } = this.props;
    if (event.keyCode === 37) { // left
      this.setState({ playerData: [], loading: true }, () => {
        onPreviousClick();
        return true;
      });
    } else if (event.keyCode === 39) { // right
      this.setState({ playerData: [], loading: true }, () => {
        onNextClick();
        return true;
      });
    }
    return false;
  }

  onPreviousClick = () => {
    const { onPreviousClick } = this.props;
    this.setState({ playerData: [], loading: true }, () => {
      onPreviousClick();
    });
  }
  onNextClick = () => {
    const { onNextClick } = this.props;
    this.setState({ playerData: [], loading: true }, () => {
      onNextClick();
    });
  }

  onPlayerClick = () => {
    this.setState({ pausePlayer: true })
  }
  closeRightPanel = () => {
    if (this.state.rightPanelOpen) {
      this.setState({ rightPanelOpen: false, pausePlayer: false })
      window.amplitude.getInstance().logEvent('Player profile closed');
    }
  }

  toggleVisibility = () => {
    this.setState((prevState) => ({ lineUpVisible: !prevState.lineUpVisible, loadingTransition: true }), () => {
      setTimeout(() => {
        this.setState({ loadingTransition: false });
      }, 500);
    });
  }

  buildTeamPlayers = (userMatchTags, team) => {
    let jerseyColor = null;
    let lineup = [];
    let subs = [];
    // Filter users on maytch by team
    userMatchTags.filter(u => u.team.name === team.name).forEach((player) => {
      if (!jerseyColor) {
        jerseyColor = player.jersey_color;
      }

      const name = player.on_tonsser ? `${player.user.firstname} ${player.user.lastname}` : player.user_name;
      const slug = player.on_tonsser && player.user.slug ? player.user.slug : null;
      const position = player.position ? player.position.localized_abbreviation : null;
      const jerseyNumber = player.jersey_number ? player.jersey_number : null;
      const onTonsser = player.on_tonsser ? player.on_tonsser : false;
      const hasPlayerStats = player.has_player_stats ? player.has_player_stats : false;

      const playerDetails = {
        name,
        slug,
        position,
        jerseyNumber,
        onTonsser,
        hasPlayerStats,
      }

      if (player.status === 'lineup') {
        lineup.push(playerDetails);
      } else if (player.status === 'substitute') {
        subs.push(playerDetails);
      }
    });

    // Sort by Jersey Number
    lineup = lineup.sort((a, b) => {
      if (a.jerseyNumber < b.jerseyNumber) { return -1; }
      if (a.jerseyNumber > b.jerseyNumber) { return 1; }
      return 0;
    });
    // Sort by Jersey Number
    subs = subs.sort((a, b) => {
      if (a.jerseyNumber < b.jerseyNumber) { return -1; }
      if (a.jerseyNumber > b.jerseyNumber) { return 1; }
      return 0;
    });

    return {
      jerseyColor,
      players: lineup,
      subPlayers: subs
    }
  }

  orchestrateData = async () => {
    const { media } = this.props;
    let data = null;
    if (media.matchDetails) {
      const { user_match_tags, home_team, away_team } = media.matchDetails;
      const hometeam = this.buildTeamPlayers(user_match_tags, home_team);
      const awayTeam = this.buildTeamPlayers(user_match_tags, away_team);
      const hasData = hometeam.players.length > 0
        || hometeam.subPlayers.length > 0
        || awayTeam.players.length > 0
        || awayTeam.subPlayers.length > 0;
      data = {
        hasData,
        match: {
          date: moment.utc(media.matchDetails.kickoff_at).format('DD MMM YYYY'),
          score: {
            home: media.matchDetails.home_team_score,
            away: media.matchDetails.away_team_score,
          },
        },
        homeTeam: {
          name: media.matchDetails.home_team.club.name,
          logo: media.matchDetails.home_team.club.logo_url,
          color: hometeam.jerseyColor,
          players: hometeam.players,
          playersCount: hometeam.players.length,
          subPlayers: hometeam.subPlayers,
          subPlayersCount: hometeam.subPlayers.length
        },
        awayTeam: {
          name: media.matchDetails.away_team.club.name,
          logo: media.matchDetails.away_team.club.logo_url,
          color: awayTeam.jerseyColor,
          players: awayTeam.players,
          playersCount: awayTeam.players.length,
          subPlayers: awayTeam.subPlayers,
          subPlayersCount: awayTeam.subPlayers.length
        },
      }
    }

    this.setState({ playerData: data, loading: false });
  }
  buildPlayer = () => {
    const { media } = this.props;
    const { pausePlayer } = this.state;
    const hlsPlayer = <Player key={media.id} poster={media.thumbnail_url} >
      <BigPlayButton position="center" />
      <ControlBar autoHide={true}>
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>
      <HLSSource
        key={media.id}
        pausePlayer={pausePlayer}
        isVideoChild
        src={media.url}
      />
    </Player>;
    return hlsPlayer;
  }

  render() {
    const { media, onClose, open } = this.props;
    const { playerData, seletectedPlayer, activeStep, rightPanelOpen, loading, lineUpVisible, loadingTransition } = this.state;
    const popupStyle = {
      borderRadius: '8px',
    }
    return (
      <Fragment>
        {open &&
          <FixedMediaDiv>
            <MediaContainer customMarginTop={this.props.customMarginTop}>
              <Fragment>
                <Div>
                  {!loading && <ArrowLeft onClick={() => this.onPreviousClick()}>
                    <span>
                      <svg fill="white" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 512 512" xmlSpace="preserve">
                        <path d="M213.7,256L213.7,256L213.7,256L380.9,81.9c4.2-4.3,4.1-11.4-0.2-15.8l-29.9-30.6c-4.3-4.4-11.3-4.5-15.5-0.2L131.1,247.9 c-2.2,2.2-3.2,5.2-3,8.1c-0.1,3,0.9,5.9,3,8.1l204.2,212.7c4.2,4.3,11.2,4.2,15.5-0.2l29.9-30.6c4.3-4.4,4.4-11.5,0.2-15.8 L213.7,256z" />
                      </svg>
                    </span>
                  </ArrowLeft>}
                  <Div>
                    <Header>
                      <Div style={{
                        position: 'absolute',
                        top: '15px',
                        left: '15px'
                      }}>
                        <Link to="/discover/country"><img style={{ height: '30px' }} alt="logo" src={logo} /></Link>
                      </Div>
                      <Div>
                        {!loading && <Close onClick={() => onClose()}><Icon className={'close'} /></Close>}
                      </Div>
                    </Header>
                    <FlexContainer style={{
                      justifyContent: 'center'
                    }}>
                      {loading && <Div style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-50px',
                        marginLeft: '-50px',
                        width: '100px',
                        height: '100px',
                      }}>
                        <Div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <Spinner />
                        </Div>
                      </Div>}
                      {!loading &&
                        <Fragment>
                          <Grid>
                            <Grid.Column stretched width={lineUpVisible ? 10 : 16} style={{ transition: 'width .35s ease-in-out' }}>
                              <ScoreHeader data={playerData} />
                            </Grid.Column>
                            <Grid.Column style={{ transition: 'width .35s ease-in-out' }} width={lineUpVisible ? 10 : 16}>
                              <MediaWrapper>
                                <Media>
                                  <MediaImage src={media.thumbnail_url || media.url} alt={media.thumbnail_url || media.url} />
                                  {media.url && media.type === 'video' && media.author.type === 'match' &&
                                    this.buildPlayer()
                                  }
                                  <LinupIconContainer style={{ right: lineUpVisible ? '0px' : '-1px', transition: 'right .35s ease-in-out' }} onClick={this.toggleVisibility}>
                                    {!lineUpVisible &&
                                      <Popup inverted style={popupStyle} content='Show Lineup' trigger={<LinupIcon>
                                        Toggle Lineup On
                                      </LinupIcon>} />
                                    }
                                    {lineUpVisible &&
                                      <Popup inverted style={popupStyle} content='Hide Lineup' trigger={<LinupIcon>
                                        Toggle Lineup Off
                                      </LinupIcon>} />}
                                  </LinupIconContainer>
                                </Media>
                              </MediaWrapper>
                            </Grid.Column>
                            {lineUpVisible && !loadingTransition && <Grid.Column width={6}>
                              <Transition visible={lineUpVisible} animation='scale' duration={500}>
                                <div>
                                  <LineUp data={playerData} onPlayerClick={this.onPlayerClick} />
                                </div>
                              </Transition>
                            </Grid.Column>}
                          </Grid>
                        </Fragment>
                      }
                    </FlexContainer>
                  </Div>
                  {!loading && <ArrowRight onClick={() => this.onNextClick()}>
                    <span>
                      <svg fill="white" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 512 512" xmlSpace="preserve">
                        <path d="M298.3,256L298.3,256L298.3,256L131.1,81.9c-4.2-4.3-4.1-11.4,0.2-15.8l29.9-30.6c4.3-4.4,11.3-4.5,15.5-0.2l204.2,212.7 c2.2,2.2,3.2,5.2,3,8.1c0.1,3-0.9,5.9-3,8.1L176.7,476.8c-4.2,4.3-11.2,4.2-15.5-0.2L131.3,446c-4.3-4.4-4.4-11.5-0.2-15.8 L298.3,256z" />
                      </svg>
                    </span>
                  </ArrowRight>}
                </Div>
              </Fragment>
            </MediaContainer>
          </FixedMediaDiv>
        }
        <Modal
          open={rightPanelOpen}
          onClose={this.closeRightPanel}
          size='large'
          hideactions
          dark
          style={{ color: 'black', backgroundColor: 'rgba(0,0,0,0.8)', zIndex: `${this.props.zIndex}` }}>
          <PlayerProfileContainer player={seletectedPlayer} activeStep={activeStep} dark inModal onPlayerClick={this.onPlayerClick} zIndex={this.props.zIndex} />
        </Modal>
      </Fragment>
    );
  }
}

export default SlideshowMatch;