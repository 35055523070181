import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Step } from 'semantic-ui-react';
import Api from '../../services/api';
import Modal from '../../components/Modal';
import InivtedStep from './InivtedStep';
import AttendingStep from './AttendingStep';
import DeclinedStep from './DeclinedStep';
import PermissionStep from './PermissionStep';
import { StyledSteps } from '../../assets/themes/styles/components/Steps/Steps';

class InivteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepActive: 'invited',
      refreshApplicantOnClose: false,
      declinedPlayers: [],
      approvedPlayers: [],
      playerDeclined: 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.modalOpen === this.props.modalOpen) {
      return
    }
    if (this.props.modalOpen) {
      this.initComponent()
    }
  }

  initComponent = () => {
    this.setState({ stepActive: 'invited', refreshApplicantOnClose: false, approvedPlayers: [], declinedPlayers: [], playerDeclined: 0 })
  }

  handleClose = () => {
    this.props.handleClose({ refreshApplicant: this.state.refreshApplicantOnClose, playerDeclined: this.state.playerDeclined })
  }

  activeStep = (step) => {
    if (step !== this.state.stepActive) {
      this.setState({ stepActive: step })
    }
  }

  setPermission = (player, reason) => {
    player.applicant_state = reason
    this.setState({ refreshApplicantOnClose: true })
    Api.setPermission(this.props.data.event.id, player, reason)
    window.amplitude.getInstance().logEvent('Events: Permissions', { event_id: this.props.data.event.id, event_name: this.props.data.event.name, player: player.slug, permission: reason });

  }

  onApprovePlayer = (player) => {
    this.setPermission(player, 'approved')
  }

  onDeclinePlayer = (player, reason) => {
    this.setState({ playerDeclined: this.state.playerDeclined + 1 })
    this.setPermission(player, reason)
  }

  render() {
    const { modalOpen, data, dark } = this.props;
    const { stepActive } = this.state;
    const players = data.players || []
    const invitedPlayers = players.filter(p => ['selected', 'invite_accepted'].includes(p.applicant_state))
    const declinedPlayers = players.filter(p => ['player_declined', 'club_declined', 'parent_declined'].includes(p.applicant_state))
    const attendingPlayers = players.filter(p => ['approved', 'attending', 'attended'].includes(p.applicant_state))
    const permissionPlayers = players.filter(p => ['invite_accepted'].includes(p.applicant_state))
    return (
      <Modal
        modalOpen={modalOpen}
        handleClose={this.handleClose}
        onSuccess={this.handleClose}
        onSuccessText="Close"
        hideCancel
        title="Manage invites"
        dark={dark.toString()}
      >
        <Fragment>
          <StyledSteps attached='top' unstackable dark={dark.toString()}>
            <Step active={stepActive === 'invited'} onClick={() => this.activeStep('invited')}>
              <Step.Content>
                <Step.Title>Selected ({invitedPlayers.length})</Step.Title>
              </Step.Content>
            </Step>
            {(data.event.required_permissions && data.event.required_permissions.length) && <Step active={stepActive === 'permissions'} onClick={() => this.activeStep('permissions')}>
              <Step.Content>
                <Step.Title>Permissions ({permissionPlayers.length})</Step.Title>
              </Step.Content>
            </Step>}
            <Step active={stepActive === 'declined'} onClick={() => this.activeStep('declined')}>
              <Step.Content>
                <Step.Title>Declined ({declinedPlayers.length})</Step.Title>
              </Step.Content>
            </Step>
            <Step active={stepActive === 'attending'} onClick={() => this.activeStep('attending')}>
              <Step.Content>
                <Step.Title>Attending ({attendingPlayers.length})</Step.Title>
              </Step.Content>
            </Step>
          </StyledSteps>
          {stepActive === 'invited' && <InivtedStep players={invitedPlayers} />}
          {stepActive === 'permissions' && <PermissionStep players={permissionPlayers} requiredPermissions={data.event.required_permissions} onApprovePlayer={this.onApprovePlayer} onDeclinePlayer={this.onDeclinePlayer} />}
          {stepActive === 'declined' && <DeclinedStep players={declinedPlayers} />}
          {stepActive === 'attending' && <AttendingStep players={attendingPlayers} />}
        </Fragment>
      </Modal>
    );
  }
}

InivteModal.propTypes = {
  modalOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
};

InivteModal.defaultProps = {
  modalOpen: false,
};



export default InivteModal;
