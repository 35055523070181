import styled from 'styled-components';
const Panel = styled.div`
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);  
  overflow:hidden;
  width: 100%;
`;

const Header = styled.div`
  padding: 16px 24px;
  background-color: black; 
  color: #fff;
  .ui.header {
    color: #fff;
  } 
`;

const Body = styled.div`
  background-color: #fff;
  padding: 24px;
`;

export {
  Panel,
  Header,
  Body,
};
