import React, { Component, Fragment } from 'react';
import { Visibility, Segment, Ref } from 'semantic-ui-react';
import Steps from '../components/Steps';
import Spinner from '../components/Spinner';
import ProfileStep from './ProfileStep';
import MediaStep from './MediaStep'
import ProfileHeader from './ProfileHeader'
import CareerStep from './CareerStep';
import MatchHistoryStep from './MatchHistoryStep';
import NotesStep from './NotesStep';
import {
  StyledMessage
} from '../assets/themes/styles/playerProfile/PlayerProfile';

class PlayerProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: this.props.activeStep,
      currentMedia: this.props.media,
      hasMedia: false,
      steps: [
        {
          id: 'profile',
          name: 'Profile'
        },
        {
          id: 'media',
          name: 'Media'
        },
        {
          id: 'career',
          name: 'Career'
        },
        {
          id: 'matchHistory',
          name: 'Matches'
        },
        {
          id: 'notes',
          name: 'Notes'
        }
      ]
    };
  }

  activeStep = (step) => {
    if (step !== this.state.activeStep) {
      window.amplitude.getInstance().logEvent(`${step} Career tab loaded`, { slug: this.props.player.slug });
      this.setState({ activeStep: step })
    }
  }

  handleMediaScroll = (e, { calculations }) => {
    const { mediaType } = this.props;
    const { loadNextMediaPage, loadNextUserMediaPage, loadNextMatchMediaPage, hasNextMediaPage, hasNextUserMediaPage, hasNextMatchMediaPage, isNextMediaPageScrollLoading } = this.props;
    if (this.state.activeStep === 'media') {
      if (calculations.bottomVisible) {
        if (mediaType === 'user') {
          if (hasNextUserMediaPage) {
            loadNextUserMediaPage();
          }

        } else if (mediaType === 'match') {
          if (hasNextMatchMediaPage) {
            loadNextMatchMediaPage();
          }

        } else {
          if (hasNextMediaPage) {
            loadNextMediaPage();
          }
        }
      }
    }
  }

  onMediaTypeChange = (type) => {
    const { media, userMedia, matchMedia, onMediaTypeChange } = this.props;
    if (type === 'match' && matchMedia) {
      this.setState({ currentMedia: matchMedia ? matchMedia : null, hasMedia: matchMedia.length > 0, isNextMediaPageLoading: true }, () => {
        if (this.onMediaTypeChange) {
          onMediaTypeChange('match')
        }
      });
    } else if (type === 'user' && userMedia) {
      this.setState({ currentMedia: userMedia ? userMedia : null, hasMedia: userMedia.length > 0, isNextMediaPageLoading: true }, () => {
        if (this.onMediaTypeChange) {
          onMediaTypeChange('user')
        }
      });
    } else if (type === 'all' && media) {
      this.setState({ currentMedia: media ? media : null, hasMedia: media.length > 0, isNextMediaPageLoading: true }, () => {
        if (this.onMediaTypeChange) {
          onMediaTypeChange('all')
        }
      });
    }
  }

  render() {
    const { steps, activeStep, hasMedia } = this.state;
    const {
      player,
      onShortlistClick,
      media,
      userMedia,
      matchMedia,
      onMediaClick,
      isNextMediaPageLoading,
      loadNextMediaPage,
      loadNextUserMediaPage,
      loadNextMatchMediaPage,
      isNextMediaPageScrollLoading,
      career,
      isCareerLoading,
      isMatchesLoading,
      previousMatches,
      nextMatches,
      notes,
      isNotesLoading,
      onCreateNoteClick,
      onUpdateNoteClick,
      onDeleteNoteClick,
      onNoteMediaClick,
      dark,
      inModal,
      standalone,
    } = this.props;

    return (
      <Fragment>
        <ProfileHeader player={player} onShortlistClick={onShortlistClick} dark={dark.toString()} hideAddNoteBtn={activeStep === 'notes'} onAddNoteBtnClick={() => this.activeStep('notes')} standalone={standalone} />
        <Steps steps={steps} activeStep={activeStep} onStepClick={this.activeStep} dark={dark.toString()} standalone={standalone} />
        <Ref innerRef={e => this.mediaScrollRef = e}>
          <Segment attached style={{ border: 'none' }}>
            {activeStep === 'profile' && <Fragment>
              <ProfileStep player={player} standalone={standalone} />
            </Fragment>}
            {activeStep === 'media' &&
              <Visibility onUpdate={this.handleMediaScroll} context={inModal ? this.mediaScrollRef : window}>
                <Segment>
                  <MediaStep key={media} onMediaTypeChange={this.onMediaTypeChange} player={player} media={media} userMedia={userMedia} matchMedia={matchMedia} onMediaClick={onMediaClick} standalone={standalone} />
                  {isNextMediaPageLoading || isNextMediaPageScrollLoading && <Spinner />}
                </Segment>
              </Visibility>
            }
            {activeStep === 'career' &&
              <Segment>
                <CareerStep career={career} loading={isCareerLoading} standalone={standalone} />
              </Segment>
            }
            {activeStep === 'matchHistory' &&
              <Fragment>
                <MatchHistoryStep nextMatches={nextMatches} previousMatches={previousMatches} loading={isMatchesLoading} standalone={standalone} />
              </Fragment>
            }

            {activeStep === 'notes' &&
              <NotesStep
                notes={notes}
                loading={isNotesLoading}
                onCreateNoteClick={onCreateNoteClick}
                onDeleteNoteClick={onDeleteNoteClick}
                onUpdateNoteClick={onUpdateNoteClick}
                onMediaClick={onNoteMediaClick}
                standalone={standalone}
              />}
          </Segment>
        </Ref>
      </Fragment>
    );
  }

}

export default PlayerProfile;
