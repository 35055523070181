
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Popup } from 'semantic-ui-react';

import { getUnshortlistedPlayers, getShortlistedPlayers } from '../../redux/selectors';

import {
  InfoIcon
} from '../../assets/themes/styles/components/PlayerTable/UserInfo';

const UserInfo = ({ user, unshortlistedPlayers, shortlistedPlayers, onRowInfoItemClick }) => {
  const badges = [];
  if (!unshortlistedPlayers.includes(user.user_id) && (shortlistedPlayers.includes(user.user_id) || user.shortlisted)) {
    badges.push({
      icon: 'icon-star-circle-light',
      tooltip: 'Shortlisted',
      step: 'profile',
    });
    user.shortlisted = true;
  } else {
    user.shortlisted = false;
  }
  if (user.has_note) {
    badges.push({
      icon: 'icon-note-circle',
      tooltip: 'Notes',
      step: 'notes',
    });
  }
  if (user.has_media) {
    badges.push({
      icon: 'icon-play-circle-light',
      tooltip: 'Media Available',
      step: 'media',
    });
  }
  if (user.is_playing_older_agegroup) {
    badges.push({
      icon: 'icon-arrow-up-circle',
      tooltip: 'Plays With Older Age Group',
      step: 'profile',
    });
  }
  if (user.player_level && ['high', 'very high'].includes(user.player_level.toLowerCase())) {
    badges.push({
      icon: 'icon-perf-level',
      tooltip: `${user.player_level} Performing`,
      step: 'profile',
    });
  }

  return (
    <Fragment >
      {badges.map((badge, i) => (
        <Popup key={i} inverted trigger={<InfoIcon onClick={(e) => onRowInfoItemClick && onRowInfoItemClick(e, user, badge.step)} className={badge.icon} style={{ zIndex: 8 - i }} />} content={badge.tooltip} />
      ))}
      {badges.length === 0 && '-'}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  unshortlistedPlayers: getUnshortlistedPlayers(state),
  shortlistedPlayers: getShortlistedPlayers(state),
});
export default connect(mapStateToProps)(UserInfo);
