import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DataTable from '../../components/DataTable';
import {
  SegmentStyled,
  Headline
} from '../../assets/themes/styles/events/InviteModal/Step';

class Step extends Component {
  render() {
    const { players, columns, headline } = this.props;
    return (
      <SegmentStyled attached>
        <Headline>{headline}</Headline>
        <DataTable
          columns={columns}
          data={players}
          light
        />
      </SegmentStyled>

    );
  }
}

Step.propTypes = {
  players: PropTypes.array,
  columns: PropTypes.array,
  headline: PropTypes.string,
};

Step.defaultProps = {
  players: [],
  columns: [],
  headline: '',
};


export default Step;
