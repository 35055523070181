import React from 'react';
import { FlexboxGrid as Div } from 'baroo';
import {
  Avatar,
  Container,
} from '../../assets/themes/styles/components/UserAvatar/UserAvatar';

const UserAvatar = ({ user, ...etc }) => (
  <Container {...etc}>
    <Avatar src={user.profile_picture} avatar />
    <Div>
      <strong>{user.firstname} {user.lastname}</strong><br />
      {user.current_club}
    </Div>
  </Container>
);

export default UserAvatar;
