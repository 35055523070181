import { Button, FlexboxGrid as Div } from 'baroo';
import { Confirm, Segment } from 'semantic-ui-react';
import Textarea from 'react-textarea-autosize';
import styled from 'styled-components';

const Container = Div.extend`
  width: 100%;
`;
const SubmitButton = Button.withComponent('button').extend`
  width: 120px;
  &:focus {
    outline: none;
  }
`;

const CancelButton = Button.withComponent('button').extend`
  margin-right: 12px;
  &:focus {
    outline: none;
  }
`;

const Note = styled(Segment)`
  display: flex;
  min-height: 160px;
`;
Note.Left = Div.extend`
  > img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }
`;
Note.Content = Div.extend`
  padding: 0 16px;
  flex-grow: 1;
`;
Note.Right = Div.extend`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`;

Note.Title = Div.extend`
  font-weight: bold;
  margin-bottom: 8px;
`;

Note.Body = Div.extend`
  white-space: pre-line;
`;

Note.Date = Div.extend`
  font-weight: bold;
  color: #727271;
`;

Note.Controls = Div.extend`
  display: flex;
  > button {
    margin-left: 12px;
  }
`;

const Form = styled('form')`
  text-align: right;
`;
const TextareaStyled = styled(Textarea)`
  width: 100%;
  min-height: 120px;
  background: #fff;
  border: solid 1px #ccced1;
  border-radius: 8px;
  padding: 12px 24px;
  margin-bottom: 12px;
  &:disabled {
    background: #d8d8d8;
  }
  &:focus {
    outline: none;
  }
`;

const Media = Div.extend`
  margin-bottom: 16px;
  cursor: pointer;
  flex-direction: column;
  > button {
    margin: 6px auto;
    font-weight: normal;
    font-size: 12px;
  }
`;

const Image = Div.extend`
  position: relative;
  display: flex;
  align-items: center;
  background: url(${props => props.src}) center no-repeat;
  background-size: cover;
  height: 180px;
  width: 180px;
  border: solid 1px #cdcdcd;
  border-radius: 8px;
  cursor: pointer;
  > i {
    position: absolute;
    bottom: 4px;
    left: 4px;
    font-size: 18px;
    color: #f0f2f3;
  } 
`;
const MediaContainer = Div.extend`  
  flex-grow: 1;  
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const File = MediaContainer.withComponent('a').extend`
  align-items: start;
  flex-direction: row;
`;

const InputFile = styled('label')`
  > input {
    opacity: 0;
    width: 0;
  }
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  margin-right: 24px;
   
  &:hover {
    opacity: 0.7;
  }
`;

const ConfirmStyled = styled(Confirm)`
  &.ui.modal > .content {
    white-space: pre-line;
    overflow: auto;
    max-height: 180px;
  }
  &.ui.modal > img {
    max-width: 100%;
    height: 180px;
    margin: 24px auto;
    display: block;
  }
`;

export {
  Container,
  SubmitButton,
  CancelButton,
  Note,
  Form,
  TextareaStyled,
  Media,
  Image,
  MediaContainer,
  File,
  InputFile,
  ConfirmStyled
};
