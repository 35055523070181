import React, { Fragment } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { FlexboxGrid as Div } from 'baroo';
import { Segment, Table, Grid, Icon, Accordion, Header as TextHeader } from 'semantic-ui-react'
import gql from 'graphql-tag';
import { titleCaseEveryWordInString } from '../services/utils';

import { Loading } from '../components/Styled';

import {
    Container,
} from '../assets/themes/styles/events/EventsListPage';

const GET_SKILL_CATEGORIES = gql`
  query getSkillCategories {
    skillCategories {
        id
      name
      skills {
        id
        name
      }
      localizedName
      positionGroups {
        id
        name
         positions {
           id
          name
         }
      }
    }
  }
`;
export default function SkillCategories({ handleActiveIndexChange, activeIndex }) {
    const { data, loading, error } = useQuery(GET_SKILL_CATEGORIES, {fetchPolicy: 'no-cache'});
    if (loading) return <Loading text="Loading skills..." />;
    if (error) return <p>ERROR</p>;

    return (
        <Container container>
            {/* <TextHeader as="h2" content="Skills" /> */}
            <Div row>
                {data.skillCategories &&
                    data.skillCategories &&
                    data.skillCategories.map(skillCategory => (
                        <div style={{ fontWeight: 'bold', width: '100%' }} key={`skillCategory_${skillCategory.id}`}>
                            <Segment style={{
                                marginBottom: '10px', display: 'flex', alignItems: 'center', backgroundColor: '#f0f2f3'
                            }}>
                                <Grid style={{ width: '100%' }}>
                                    <Grid.Row>
                                        <Grid.Column width={3}>
                                            <div style={{
                                                display: 'flex',
                                                height: '150px',
                                                width: '150px',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginRight: '15px',
                                                fontSize: '20px',
                                                backgroundColor: '#000000',
                                                color: '#ffffff',
                                                borderRadius: '50%'
                                            }}>{skillCategory.name}</div>
                                        </Grid.Column>
                                        <Grid.Column width={13}>
                                            <Segment>
                                                <Accordion styled style={{ width: '100%' }}>
                                                    <Accordion.Title active={activeIndex === `${skillCategory.name}_skills`} index={`${skillCategory.name}_skills`} onClick={handleActiveIndexChange}>
                                                        <div style={{ display: 'flex', alignItems: 'baseline' }}><h2>Skills</h2><Icon name='dropdown' /></div>
                                                    </Accordion.Title>
                                                    <Accordion.Content active={activeIndex === `${skillCategory.name}_skills`}>
                                                        <Table basic='very' celled style={{}}>
                                                            <Table.Body>
                                                                {skillCategory.skills.map(skill => {
                                                                    return <Table.Row style={{ fontSize: '14px' }} key={`${skillCategory.name}_skill_${skill.id}`}>
                                                                        <Table.Cell>{skill.name}</Table.Cell>
                                                                    </Table.Row>
                                                                })}
                                                            </Table.Body>
                                                        </Table>
                                                    </Accordion.Content>
                                                    <Accordion.Title active={activeIndex === `${skillCategory.name}_position_groups`} index={`${skillCategory.name}_position_groups`} onClick={handleActiveIndexChange}>
                                                        <div style={{ display: 'flex', alignItems: 'baseline' }}><h2>Position Groups</h2><Icon name='dropdown' /></div>
                                                    </Accordion.Title>
                                                    <Accordion.Content active={activeIndex === `${skillCategory.name}_position_groups`}>
                                                        {skillCategory.positionGroups.map(positionGroup => {
                                                            return <Segment key={`${skillCategory.name}_positionGroup_${positionGroup.name}`}>
                                                                <Table basic='very' celled style={{}}>
                                                                    <Table.Header>
                                                                        <Table.Row>
                                                                            <Table.HeaderCell><h2>{titleCaseEveryWordInString(positionGroup.name)}</h2></Table.HeaderCell>
                                                                        </Table.Row>
                                                                    </Table.Header>
                                                                    <Table.Body>
                                                                        {positionGroup.positions.map(position => {
                                                                            return <Table.Row style={{ fontSize: '14px' }} key={`position${position.id}`}>
                                                                                <Table.Cell>{titleCaseEveryWordInString(position.name)}</Table.Cell>
                                                                            </Table.Row>
                                                                        })}
                                                                    </Table.Body>
                                                                </Table>
                                                            </Segment>
                                                        })}
                                                    </Accordion.Content>
                                                </Accordion>
                                            </Segment>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </div>
                    ))}
            </Div>
        </Container>
    );
}