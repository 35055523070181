import React, { Component, Fragment } from 'react';
import { TransitionablePortal, Message, Header as TextHeader } from 'semantic-ui-react';
import { FlexboxGrid as Div, Button } from 'baroo';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Api from '../services/api';
import ShortlistCardSummary from './ShortlistCardSummary';
import { Loading } from '../components/Styled';
import {
  Container,
  MessageStyled,
  Col,
  NoShortlist,
  SegmentStyled
} from '../assets/themes/styles/shortlists/ShortlistListPage';

class ShortlistListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shortlists: [],
      error: null,
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    Api.getShortlists()
      .then((data) => {
        if (data.response) {
          this.setState({
            loading: false,
            shortlists: data.response,
          });
          window.amplitude.getInstance().logEvent('Shortlists viewed', { shortlist_count: data.response.length });
        } else {
          this.setState({
            loading: false,
            error: 'No shortlists found',
          });
        }
      }).catch(() => {
        this.setState({
          loading: false,
          error: 'No shortlists found',
        });
      });
  }

  render() {
    const { shortlists, error, loading } = this.state;
    const { user } = this.props;
    const myShortlists = shortlists.filter((s) => s.creator.slug === user.slug);
    const notMyShortlists = shortlists.filter((s) => s.creator.slug !== user.slug);
    return (
      <Fragment>
        <Header user={this.props.user} />
        {loading && <Loading text="Loading shortlists..." />}

        <Container container>


          {!loading && !!shortlists && shortlists.length !== 0 &&
            <Fragment>
              <Div dFlexXs endXs betweenXs middleXs>
                <TextHeader as="h2" content="Your Shortlists" floated="left" style={{ margin: 0 }} />
                <TextHeader as="h2" floated="right">
                  <Link to="/shortlists/new"><Button>New shortlist</Button></Link>
                </TextHeader>
              </Div>
              <Div>
                <SegmentStyled>
                  These shortlists were created by you. If you have colleagues with access to Tonsser Discover, then they can view your shortlists, as well as add players to them.
              </SegmentStyled>
              </Div>
              <Div row>
                {myShortlists.map((shortlist) => (
                  <Col colXs={12} colSm={6} key={shortlist.id}>
                    <ShortlistCardSummary shortlist={shortlist} />
                  </Col>
                ))}
              </Div>
              <TextHeader as="h2" content="Colleagues Shortlists" />
              <Div>
                <SegmentStyled>These shortlists were created by a colleague. You can view their shortlists, as well as add players to them.</SegmentStyled>
              </Div>
              <Div row>
                {notMyShortlists.map((shortlist) => (
                  <Col colXs={12} colSm={6} key={shortlist.id}>
                    <ShortlistCardSummary shortlist={shortlist} />
                  </Col>
                ))}
              </Div>
            </Fragment>

          }
          {!loading && !!shortlists && shortlists.length === 0 &&
            <NoShortlist>
              <Div>No shortlist have been created</Div>
              <Link to="/shortlists/new"><Button>New shortlist</Button></Link>
            </NoShortlist>
          }
          {error &&
            <TransitionablePortal open={!!error}
              transition={{
                animation: 'fade',
                duration: 300,
              }}
            >
              <MessageStyled negative
                style={{
                  left: '40%',
                  position: 'fixed',
                  top: '50%',
                  zIndex: 1000,
                }}
              >
                <Message.Header>Acces denied</Message.Header>
              </MessageStyled>

            </TransitionablePortal>
          }
        </Container>
      </Fragment >
    );
  }
}

export default ShortlistListPage;
