import React, { Component, Fragment } from 'react';
import { createApolloFetch } from 'apollo-fetch';

import Header from '../components/Header';
import SkillCategories from './SkillCategories';


class SkillsListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: ''
        };
    }

    handleActiveIndexChange = (e, titleProps) => {
        const { activeIndex } = this.state;
        const { index } = titleProps
        const newIndex = activeIndex === index ? -1 : index
        this.setState({ activeIndex: newIndex });
    }

    render() {
        const { events, error, activeIndex } = this.state;
        return (
            <Fragment>
                <Header user={this.props.user} />
                <SkillCategories handleActiveIndexChange={this.handleActiveIndexChange} activeIndex={activeIndex} />
            </Fragment>
        );
    }
}

export default SkillsListPage;
