import React, { Component, Fragment } from 'react';
import { FlexboxGrid as Div } from 'baroo';
import { TransitionablePortal, Message, Breadcrumb, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Intercom from 'react-intercom';
import Header from '../components/Header';
import ShortlistCardSummary from './ShortlistCardSummary';
import PlayerTableContainer from '../components/PlayerTable/PlayerTableContainer';
import Api from '../services/api';
import { ButtonLight } from '../assets/themes/styles/components/Styled/Styled';
import { Loading } from '../components/Styled';
import {
  FullHeight,
  Container,
  Item,
  MessageStyled
} from '../assets/themes/styles/shortlists/ShortlistDetailPage';

class ShortlistDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shortlist: null,
      error: null,
      loading: false,
      hideDetail: true,
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    Api.getShortlist(this.props.match.params.id)
      .then((data) => {
        if (data.response) {
          const shortlist = data.response;
          this.setState({
            loading: false,
            shortlist,
          });
        } else {
          this.setState({
            loading: false,
            error: 'Shortlist not found',
          });
        }
      }).catch(() => {
        this.setState({
          loading: false,
          error: 'Shortlist not found',
        });
      });
  }

  toggleDetail = () => {
    this.setState({ hideDetail: !this.state.hideDetail })
  }

  render() {
    const { user } = this.props;
    const { loading, error, shortlist, hideDetail } = this.state;
    let intercomOptions = {};
    if (user) {
      intercomOptions = {
        appID: 'dklrpfzw',
        user_id: user.slug,
        email: user.email,
        name: `${user.firstname} ${user.lastname}`,
        alignment: 'left',
      };
    }
    return (
      <FullHeight>
        <Header user={user} />
        {!!shortlist &&
          <Container>
            <Item>
              <Breadcrumb>
                <Breadcrumb.Section><Link to="/shortlists">Shortlists</Link></Breadcrumb.Section>
                <Breadcrumb.Divider />
                <Breadcrumb.Section active>{shortlist.name}</Breadcrumb.Section>
              </Breadcrumb>
              <ButtonLight btnXs onClick={this.toggleDetail}>
                {hideDetail && <Fragment><Icon className="icon-caret-down" /><span>Show detail</span></Fragment>}
                {!hideDetail && <Fragment><Icon className="icon-caret-up" /><span>Hide detail</span></Fragment>}
              </ButtonLight>
            </Item>
            {!hideDetail &&
              <Item>
                <ShortlistCardSummary shortlist={shortlist} />
              </Item>
            }
          </Container>
        }
        {!loading && !!shortlist && <PlayerTableContainer persistId={`shortlist-${shortlist.id}`} forcedFilters={{ shortlist_ids: [{ id: shortlist.id }] }} hideInShortlists hideNotInShortlists />}

        {loading && <Loading text="Loading shortlist..." />}

        {error &&
          <TransitionablePortal open={!!error}
            transition={{
              animation: 'fade',
              duration: 300,
            }}
          >
            <MessageStyled negative
              style={{
                left: '40%',
                position: 'fixed',
                top: '50%',
                zIndex: 1000,
              }}
            >
              <Message.Header>The shortlist you are looking for doesn't exist</Message.Header>
            </MessageStyled>
          </TransitionablePortal>
        }
        {user && <Div dNoneXs dFlexSm><Intercom {...intercomOptions} /></Div>}
      </FullHeight>
    );
  }
}

export default ShortlistDetailPage;
