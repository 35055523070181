import React, { Component, Fragment } from 'react';
import { FlexboxGrid as Div, Button } from 'baroo';
import { TransitionablePortal, Message, Form, Confirm } from 'semantic-ui-react';
import Header from '../components/Header';
import Api from '../services/api';
import {
  Container,
  MessageStyled
} from '../assets/themes/styles/shortlists/ShortlistCreate';

class ShortlistCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shortlist: {},
      error: null,
      loading: false,
      confirmOpen: false
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ loading: true });
      Api.getShortlist(this.props.match.params.id)
        .then((data) => {
          if (data.response) {
            const shortlist = data.response;
            this.setState({
              loading: false,
              shortlist,
            });
          } else {
            this.setState({
              loading: false,
              error: 'Shortlist not found',
            });
          }
        }).catch(() => {
          this.setState({
            loading: false,
            error: 'Shortlist not found',
          });
        });
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const newShortlist = this.state.shortlist;
    newShortlist[name] = value
    this.setState({
      shortlist: newShortlist
    });
  }

  onSubmit = () => {
    const { shortlist } = this.state;
    this.setState({ loading: true })
    const apiMethod = shortlist.id ? Api.updateShortlist : Api.createShortlist
    apiMethod(shortlist)
      .then(() => {
        this.props.history.push('/shortlists')
      })
  }

  deleteShortlist = () => {
    const { shortlist } = this.state;
    this.setState({ loading: true })
    Api.deleteShortlist(shortlist)
      .then(() => {
        this.props.history.replace('/shortlists')
      })
  }

  openConfirm = () => {
    this.setState({ confirmOpen: true });
  }

  closeConfirm = () => {
    this.setState({ confirmOpen: false });
  }

  render() {
    const { user } = this.props;
    const { loading, error, shortlist, confirmOpen } = this.state;

    return (
      <Fragment>
        <Header user={user} />
        <Container container>
          {!!shortlist.id &&
            <Div row endXs>
              <Div>
                <Button btnReverse onClick={this.openConfirm}>Delete</Button>
              </Div>
            </Div>
          }
          <Form onSubmit={this.onSubmit}>
            <Form.Input fluid label="Name" name="name" value={shortlist.name} onChange={this.handleInputChange} required />
            <Form.TextArea label="Description" name="description" value={shortlist.description} onChange={this.handleInputChange} />
            <Form.Button disabled={loading}>Submit</Form.Button>
          </Form>
        </Container>

        {error &&
          <TransitionablePortal open={!!error}
            transition={{
              animation: 'fade',
              duration: 300,
            }}
          >
            <MessageStyled negative
              style={{
                left: '40%',
                position: 'fixed',
                top: '50%',
                zIndex: 1000,
              }}
            >
              <Message.Header>The shortlist you are looking for doesn't exist</Message.Header>
            </MessageStyled>
          </TransitionablePortal>
        }
        <Confirm
          open={confirmOpen}
          onConfirm={this.deleteShortlist}
          onCancel={this.closeConfirm}
          content="Are you sure you want to delete this shortlist?"
        />
      </Fragment>
    );
  }
}

export default ShortlistCreate;
