import styled, { css } from 'styled-components';
import { FlexboxGrid as Div, Themes } from 'baroo';
import { Message, Label, Icon, Input } from 'semantic-ui-react';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

const INVITE_STATUS = {
  DECLINED: 'Declined',
  INVITED: 'Invited',
  PERMISSIONS: 'Permissions',
  ATTENDING: 'Attending'
}

const DataTableItem = Div.extend`
  padding-top: 0;
  padding: 0;
  flex-grow: 1;
  height: 100vh;
  ${Themes.Media.sm`
    height: 75px;
  `}
  transition: height 0.3s, opacity 0.3s;
  transform: translateZ(0);
`;

const MessageStyled = styled(Message)`
  > .content {
    margin-top: 12px;
    text-align: center;
  }
`;


const InfoBadge = styled(Label)`
  &.ui.label {
    text-transform: uppercase;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.2);
    font-size: 13px;
    color: #000;
    padding: 5px;
    font-weight: normal;
    ${props => props.type === 'media' && `
      background-color: #00ffc8;
      `
  }
    ${props => props.type === 'olderAgegroup' && `
      background-color: #fff;
      border: solid 1px #242c39;
      `
  }
  }
`;

const DataLoading = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  flex-direction: column;
  background: rgba(0,0,0,.80);
  z-index: 20;
`;

const InviteStatus = styled.div`
  font-weight: bold;
  text-decoration: underline;
  color: #555;
  ${props => props.status === INVITE_STATUS.DECLINED && `
    color: #ff0e2c 
  `}
  ${props => props.status === INVITE_STATUS.ATTENDING && `
    color: #00d300 
  `}
  ${props => props.status === INVITE_STATUS.PERMISSIONS && `
    color: #0063ff 
  `}
`;


const Pagination = styled.div`
  margin-right: 2px;
`;
const PaginationContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
`;

const StyledIcon = styled(Icon)`
  ${props => props.disabled && `
    cursor: default;
  `}
  ${props => !props.disabled && `
    cursor: pointer;
  `}
`;
const PaginationArrowLeft = styled.span`
  color: black;
  float: left;
  text-decoration: none;
  transition: background-color .3s;
  font-size: 30px;
  margin-top: 10px;
`;
const PaginationArrowRight = styled.span`
  color: black;
  float: left;
  text-decoration: none;
  transition: background-color .3s;
  font-size: 30px;
  margin-top: 10px;
`;
const PaginationPageNumber = styled.span`
  position: relative;
  color: black;
  float: left;
  padding: 12px 16px;
  text-decoration: none;
  transition: background-color .3s;
  ${props => props.active && css`
    background-color: #0099FF;
    color: white;
    border: 1px solid #0099FF;
  `}
`;

const StyledInput = styled(Input)`
  input {
    min-width: 40px;
    ${props => props.adjustedWidth && css`
        width: ${props.adjustedWidth}px;   
    `}
  }
`;

export {
  DataTableItem,
  MessageStyled,
  InfoBadge,
  DataLoading,
  InviteStatus,
  Pagination,
  PaginationContainer,
  StyledIcon,
  PaginationArrowLeft,
  PaginationArrowRight,
  PaginationPageNumber,
  StyledInput
};
