import React, { Component } from 'react';
import moment from 'moment';
import { FlexboxGrid as Div } from 'baroo';
import 'video-react/dist/video-react.css';
import { Icon } from 'semantic-ui-react';
import "../../src/assets/themes/react-tabs.css";
import { titleCaseEveryWordInString } from '../services/utils';
import {
  Category,
  CategoryName,
  Label,
  Content,
  Media,
  Image,
  Description,
  SectionButton,
  SectionButtons,
  MediaType,
  MatchDetails,
  MatchDetailsTitle,
  MatchDetailsType,
  MatchDetailsTime,
  OverviewHomeTeam,
  OverviewAwayTeam,
  OverviewScore
} from '../assets/themes/styles/playerProfile/MediaStep';

import {
  StyledMessage
} from '../assets/themes/styles/playerProfile/PlayerProfile';


class MediaStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSection: 'all',
    }
  }
  componentDidMount() {
    this.setActiveSection('all');
  }
  // shouldComponentUpdate(nextProps) {
  //   return new Set([...nextProps.media.map((m) => m.id), ...this.props.media.map((m) => m.id)]).size !== this.props.media.length;
  // }
  setActiveSection = (section) => {
    this.setState({ activeSection: section }, () => {
      if (this.props.onMediaTypeChange) {
        this.props.onMediaTypeChange(section)
      }
      this.forceUpdate();
    });
  }
  buildSectionButtons = () => {
    const { activeSection } = this.state;
    const sections = [
      {
        key: 'all',
        title: 'All'
      },
      {
        key: 'match',
        title: 'Match'
      },
      {
        key: 'user',
        title: 'User'
      }
    ];
    return sections.map((section) => {
      const isActive = activeSection === section.key ? true : false;
      return (<SectionButton key={section.key} active={isActive} onClick={() => this.setActiveSection(section.key)}>{section.title}</SectionButton>)
    });
  }
  showSectionContent = () => {
    const { media, userMedia, matchMedia, onMediaClick } = this.props;
    const { activeSection } = this.state;
    let currentMedia = media;
    if (activeSection === 'all') {
      currentMedia = media;
    } else if (activeSection === 'user') {
      currentMedia = userMedia;
    } else if (activeSection === 'match') {
      currentMedia = matchMedia;
    }
    return currentMedia.map((m, index) => {
      let matchDtails = {};
      let type = null;
      if (m.type === 'video' && m.author.type === 'match') {
        matchDtails = {
          isHome: m.match.user_is_home,
          homeTeamName: m.match.home_team_name,
          score: m.match.result,
          awayTeamName: m.match.away_team_name,
          type: `${titleCaseEveryWordInString(m.match.match_type)}`,
          date: moment.utc(m.match.kickoff_at).format('DD MMM YYYY HH:MM'),
        }
        type = 'Match';
      } else if (m.author.type === 'user') {
        type = 'User';
      }

      return <Media colXs={6} colSm={3} onClick={() => onMediaClick(m, activeSection)} key={m.id}>
        <Image type={m.type} src={m.thumbnail_url || m.url}>
          <MediaType>{type}</MediaType>
          <Icon className={m.type === 'video' ? 'icon-play-circle' : 'icon-enlarge'} />
        </Image>
        <Description>
          {type === 'Match' &&
            <MatchDetails>
              <MatchDetailsTitle>
                <OverviewHomeTeam isHome={matchDtails.isHome}>{matchDtails.homeTeamName}</OverviewHomeTeam>
                <OverviewScore>
                  {matchDtails.score ? matchDtails.score : ` - `}
                </OverviewScore>
                <OverviewAwayTeam isAway={!matchDtails.isHome}>{matchDtails.awayTeamName}</OverviewAwayTeam>
              </MatchDetailsTitle>
              <MatchDetailsType>{matchDtails.type}</MatchDetailsType>
              <MatchDetailsTime>{matchDtails.date}</MatchDetailsTime>
            </MatchDetails>
          }
          {type !== 'Match' && <Label>{m.description}</Label>}
          {type !== 'Match' && <Content>{moment(m.publish_at).fromNow()}{m.like.count ? ` • ${m.like.count} likes` : ''}{m.views_count ? ` • ${m.views_count} views` : ''}</Content>}
        </Description>
      </Media>
    });
  }

  render() {
    const { activeSection } = this.state;
    const { media, userMedia, matchMedia } = this.props;
    let currentMedia = media;
    if (activeSection === 'all') {
      currentMedia = media;
    } else if (activeSection === 'user') {
      currentMedia = userMedia;
    } else if (activeSection === 'match') {
      currentMedia = matchMedia;
    }
    const hasMedia = currentMedia && currentMedia.length > 0;
    return (
      <Category>
        <CategoryName>Media</CategoryName>
        <SectionButtons row>
          {activeSection && this.buildSectionButtons()}
        </SectionButtons>
        {hasMedia && <Div row key={activeSection}>
          {this.showSectionContent()}
        </Div>}
        {!hasMedia && <StyledMessage>
          <p>
            No media
          </p>
        </StyledMessage>}
      </Category>);
  }
}
export default MediaStep;
