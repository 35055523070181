export default {
  env: 'production',
  apiHost: 'https://api.tonsser.com/44',
  graphqlHost: 'https://graphql.tonsser.com/graphql',
  graphqlApiHost: 'https://graphql.tonsser.com/graphql',
  graphqlAPIKey: 'fPgDQE7JnjQii+5HfZ+ydZtncZlNoEG7847WKmgYPZM=',
  AWS: {
    region: 'eu-west-1',
    IdentityPoolId: 'eu-west-1:eb9728ce-ec07-4a1c-ad9a-23c6384ca45c',
    inputBucket: 'tonsser-prod-input',
  },
};
