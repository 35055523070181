import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import { Button, FlexboxGrid as Div } from 'baroo';
import { VerticalDivider } from '../../assets/themes/styles/components/Styled/Styled';
import {
  CloseIcon,
  ModalStyled,
  FailButton,
  CancelButton,
} from '../../assets/themes/styles/components/Modal/Modal';

class TonsserModal extends Component {
  onSuccess = () => {
    const { handleClose, onSuccess } = this.props;
    if (!onSuccess) {
      return handleClose()
    }
    return onSuccess()
  }

  onFail = () => {
    const { handleClose, onFail } = this.props;
    if (!onFail) {
      return handleClose()
    }
    return onFail()
  }

  render() {
    const { modalOpen, handleClose, onFail, onFailText, hideCancel, onSuccessText, title, children, dark, hideactions, ...etc } = this.props;
    return (
      <ModalStyled
        centered={false}
        open={modalOpen}
        onClose={handleClose}
        closeOnDimmerClick
        style={{ 'display': 'flex !important' }}
        dark={dark.toString()}
        hideactions={hideactions !== undefined ? hideactions.toString() : undefined}
        {...etc}
      >
        {title && <Modal.Header>
          {title}
          <CloseIcon className="icon-close" onClick={handleClose} dark={dark.toString()} />
        </Modal.Header>
        }
        <Modal.Content>
          {children}
        </Modal.Content>
        {!hideactions &&
          <Modal.Actions>
            <Div dFlexXs dNoneSm>
              {onFail && <Fragment>
                <FailButton btnOutlineReverse btnSm onClick={onFail} dark={dark.toString()}>
                  {onFailText}
                </FailButton>
              </Fragment>
              }
              {!hideCancel &&
                <CancelButton btnOutlineReverse btnSm onClick={handleClose} dark={dark.toString()}>
                  Cancel
            </CancelButton>
              }
              <Button btnSm onClick={this.onSuccess}>
                {onSuccessText}
              </Button>
            </Div>
            <Div dNoneXs dFlexSm>
              {onFail && <Fragment>
                <FailButton btnOutlineReverse btnSm onClick={onFail} dark={dark.toString()}>
                  {onFailText}
                </FailButton>
                <VerticalDivider />
              </Fragment>
              }
              {!hideCancel &&
                <CancelButton btnOutlineReverse onClick={handleClose} dark={dark.toString()}>
                  Cancel
            </CancelButton>
              }
              <Button onClick={this.onSuccess}>
                {onSuccessText}
              </Button>
            </Div>
          </Modal.Actions>
        }
      </ModalStyled>
    );
  }
}

TonsserModal.propTypes = {
  modalOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
  onFailText: PropTypes.string,
  onSuccessText: PropTypes.string,
  hideCancel: PropTypes.bool,
};

TonsserModal.defaultProps = {
  modalOpen: false,
  onSuccess: null,
  onFail: null,
  onFailText: 'Clear',
  onSuccessText: 'Apply',
  hideCancel: false,
};



export default TonsserModal;
