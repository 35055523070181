import React from 'react';
import { FlexboxGrid as Div } from 'baroo';
import Spinner from '../Spinner';
import {
  DataLoading,
  Logo,
  LoadingDiv
} from '../../assets/themes/styles/components/Styled/Styled';

export const Loading = ({ text, customMarginTop = 0 }) => (
  <DataLoading style={{ marginTop: customMarginTop }}>
    <Spinner />
    <Div>{text}</Div>
  </DataLoading>
);

export const LogoLoading = ({ logo, altText, height }) => (
  <LoadingDiv >
    <Logo alt={altText} src={logo} height={height} />
  </LoadingDiv>
);
