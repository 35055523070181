import styled, { css } from 'styled-components';
import { Step } from 'semantic-ui-react';
import { Themes } from 'baroo';

const StyledSteps = styled(Step.Group)`
 &.ui.steps {
  ${(props) => props.standalone && css`
    padding-left: 10px;
    padding-right: 10px;
  `}
   border: none;
   border-radius: 0;
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   flex-grow: 1;
   padding-bottom: 0;
   height: 56px;
   align-items: center;
   &.attached {
     width: auto !important;
     margin: 0;
   }
   .step{
     border-radius: 0 !important;
     border: none;
     padding: 0px 16px 0px 0 !important;
     width: 50% !important;
     justify-content: flex-start;
     align-items: flex-start;
     flex-grow: 0;
     ${Themes.Media.sm`
       padding: 0px 40px 0px 0  !important;
       width: auto !important;
     `}
     &.active {
       background: transparent;
       .title {
          font-weight: bold;
          &:after{
          content: " ";
          width: 100%;
          height: 2px;
          background: black;
          position: absolute;
          bottom: -20px;
          left: 0;
          ${Themes.Media.sm`
            height: 3px;
          `}
          }
       }
       
    
     }
     &:after {
       display: none;
     }
     .title {
       color: #000;
       font-size: 16px;
       font-weight: bold;
       text-transform: uppercase;
       position: relative;
       display: flex;
       align-items: center;
       > .label {
         margin-left: 8px;
       }
     }
     &:hover {
       background: transparent !important;
       opacity: 0.6;
     }
   }
   .disabled.step .title {
     color: #c1c1c1;
   }
 }
 ${(props) => props.dark && css`
    &.ui.steps {
      background: #171918;
      .step {
        background: #171918;
        &.active {
          .title {
            font-weight: normal;
            &:after {
              background: #00ffc8;
            }
          }
            
        }
        .title {
          color: white;
          font-weight: normal;
          text-transform: none;
        }        
      }
      .disabled.step .title {
        color: #fff;
        opacity: 0.5;
      }
    }
 `}
`;

export { StyledSteps };