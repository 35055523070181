import styled from 'styled-components';
import { Message } from 'semantic-ui-react';

const MessageStyled = styled(Message)`
  > .content {
    margin-top: 12px;
    text-align: center;
  }
`;

export {
  MessageStyled
};
