import styled from 'styled-components';
import { Label, Icon } from 'semantic-ui-react';

const IconStyled = styled(Icon)`
&.icon {
  cursor: pointer;
    margin-right: 0 !important;
    margin-left: .5em !important;
    font-size: .92857143em;
    opacity: .5;
    transition: background .1s ease;
}
`;

const LabelStyled = styled(Label)`
  &.ui.label {
    margin-bottom: 8px;
    > i {
      opacity: 0 !important;
    }
    &:hover {
      cursor: pointer;
      > i {
        opacity: 0.5 !important; 
      }
    }
  }
`;

export {
  IconStyled,
  LabelStyled
};
