import { Message, Segment } from 'semantic-ui-react';
import { FlexboxGrid as Div } from 'baroo';
import styled from 'styled-components';

const Container = Div.extend`
  padding-top: 136px;
`;

const MessageStyled = styled(Message)`
  > .content {
    margin-top: 12px;
    text-align: center;
  }
`;

const Col = Div.extend`
  margin-bottom: 40px;

`;

const NoShortlist = styled.div`
  padding: 86px 0;
  text-align:center;
  font-size: 24px;
  font-weight: bold;
  color: #000;
  border: 2px #000 dashed;
  border-radius: 8px;
`;

const SegmentStyled = styled(Segment)`
&.ui.segment{
  margin-top: 16px;
  margin-bottom: 16px;
} 
`;

export {
  Container,
  MessageStyled,
  Col,
  NoShortlist,
  SegmentStyled
}