import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FlexboxGrid as Div } from 'baroo';
import { Icon } from 'semantic-ui-react';
import _ from 'lodash';
import withSizes from 'react-sizes'
import Api from '../../services/api';
import { getSeasonsFromApi } from '../../services/utils';
import Modal from '../Modal';
import PlayerTable from './PlayerTable';
import PlayerProfileContainer from '../../playerProfile/PlayerProfileContainer';
import FilterModal from './FilterModal';
import LayoutModal from './LayoutModal';
import FiltersRecap from './FiltersRecap';
import { VerticalDivider, ButtonLight } from '../../assets/themes/styles/components/Styled/Styled';
import {
  Container,
  FilterItem,
} from '../../assets/themes/styles/components/PlayerTable/PlayerTableContainer';

const INITIAL_FILTERS = { seasons: [4040, 4039] }

class PlayerTableContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentSeasons: [],
      error: null,
      filters: { ...INITIAL_FILTERS },
      filterModalOpen: false,
      layoutModalOpen: false,
      scrollDirection: 'up',
      rightPanelOpen: false,
      columns: null,
      currentLayout: null
    };
    this.rightPanelRef = React.createRef()

  }

  componentWillMount() {
    try {
      let savedFilters = window.localStorage.getItem(`filters-${this.props.persistId}`)
      if (savedFilters) {
        savedFilters = JSON.parse(savedFilters)
        this.setState({ filters: { ...savedFilters, ...this.props.forcedFilters } })
      } else {
        this.setState({ filters: { ...INITIAL_FILTERS, ...this.props.forcedFilters } })
      }
      this.initComponent();
    } catch (e) {

    }
  }

  componentDidMount() {
    // In case no filters are saved, we need to tell player table to use and save the default fitlers initialized here    
    let savedFilters = window.localStorage.getItem(`filters-${this.props.persistId}`)
    if (!savedFilters && this.playerTable) {
      this.playerTable.setFilters(this.state.filters)
    }
    // Open modal by default
    if (this.props.playerId) {
      this.onPlayerClick({ slug: this.props.playerId });
    }
  }
  initComponent = async () => {
    const currentSeasons = await getSeasonsFromApi();
    this.setState({
      currentSeasons
    });
  }
  openFilterModal = () => {
    window.amplitude.getInstance().logEvent('Open filters');
    this.setState({ filterModalOpen: true })
  }
  closeFilterModal = () => {
    this.setState({ filterModalOpen: false })
  }
  openLayoutModal = () => {
    window.amplitude.getInstance().logEvent('Open columns');
    this.setState({ layoutModalOpen: true, columns: this.playerTable.getColumns(), currentLayout: this.playerTable.getLayout() })
  }
  closeLayoutModal = () => {
    this.setState({ layoutModalOpen: false })
  }

  applyFilters = (filters) => {
    if (this.playerTable) {
      this.playerTable.setFilters(filters)
      this.setState({ filters })
      window.amplitude.getInstance().logEvent('Apply filters', {
        ...filters,
        countries: filters.countries.map(c => c.name),
        agegroups: filters.agegroups.map(c => c.name),
        leagues: filters.leagues.map(c => c.name),
      });
    }
    this.closeFilterModal();
  }

  resetFilters = () => {
    if (this.playerTable) {
      this.playerTable.setFilters({ ...INITIAL_FILTERS, ...this.props.forcedFilters }, true)
      this.setState({ filters: { ...INITIAL_FILTERS, ...this.props.forcedFilters } })
    }
  }

  applyLayout = ({ layout }) => {
    window.amplitude.getInstance().logEvent('Apply columns', { columns: layout.map(c => c.key) });

    if (this.playerTable) {
      this.playerTable.setLayout(layout)
    }
    this.closeLayoutModal();
  }

  resetLayout = () => {
    if (this.playerTable) {
      this.playerTable.setLayout()
    }
  }

  onCountryClick = (country) => {
    if (!country) { return }
    window.amplitude.getInstance().logEvent('Remove filter');
    this.setState(prevState => {
      const newFilters = { ...prevState.filters };
      newFilters.countries = newFilters.countries.filter(c => c.id !== country.id);
      const removedAgegroupIds = [];
      const newAgegroups = [];
      if (newFilters.agegroups) {
        newFilters.agegroups.forEach(a => {
          if (a.country_id === country.id) {
            removedAgegroupIds.push(a.id)
          } else {
            newAgegroups.push(a)
          }
        });
        newFilters.agegroups = newAgegroups;
      }
      newFilters.leagues = newFilters.leagues.filter(l => !removedAgegroupIds.includes(l.agegroup_id));

      if (this.playerTable) {
        this.playerTable.setFilters(newFilters)
      }
      return { filters: newFilters }
    })
  }

  onAgegroupClick = (agegroup) => {
    if (!agegroup) { return }
    window.amplitude.getInstance().logEvent('Remove filter');
    this.setState(prevState => {
      const newFilters = { ...prevState.filters };
      newFilters.agegroups = newFilters.agegroups.filter(a => a.id !== agegroup.id);
      if (newFilters.leagues)
        newFilters.leagues = newFilters.leagues.filter(l => l.agegroup_id !== agegroup.id)
      if (this.playerTable) {
        this.playerTable.setFilters(newFilters)
      }
      return { filters: newFilters }
    })
  }

  onLeagueClick = (league) => {
    if (!league) { return }
    window.amplitude.getInstance().logEvent('Remove filter');
    this.setState(prevState => {
      const newFilters = { ...prevState.filters };
      if (newFilters.leagues) {
        newFilters.leagues = newFilters.leagues.filter(l => l.id !== league.id)
      }
      if (this.playerTable) {
        this.playerTable.setFilters(newFilters)
      }

      return { filters: newFilters }
    })
  }

  onFiltersRemove = (keys) => {
    const newFilters = { ...this.state.filters }
    keys.forEach(key => {
      newFilters[key] = null
    })
    this.setState({ filters: newFilters })

    if (this.playerTable) {
      this.playerTable.setFilters(newFilters)
    }
  }

  onTableScoll = _.throttle((direction) => {
    if (direction !== this.state.scrollDirection) {
      this.setState({ scrollDirection: direction })
    }
  }, 300)

  onPlayerClick = (player) => {
    if (!this.state.rightPanelOpen) {
      window.amplitude.getInstance().logEvent('Player profile opened', { slug: player.slug });
    }
    if (this.rightPanelRef) {
      //this.rightPanelRef.current.scrollTop = 0;
    }
    this.setState({ rightPanelOpen: true, seletectedPlayer: player, activeStep: 'profile' }, () => {
      window.history.pushState(null, document.title, `/player/${player.slug}`);
    })
  }

  onPlayerInfoItemClick = (e, player, step) => {
    e.stopPropagation();
    if (!this.state.rightPanelOpen) {
      window.amplitude.getInstance().logEvent('Player profile opened on step', { step, slug: player.slug });
    }
    if (this.rightPanelRef) {
      //this.rightPanelRef.current.scrollTop = 0;
    }
    this.setState({ rightPanelOpen: true, seletectedPlayer: player, activeStep: step })
  }

  closeRightPanel = () => {
    if (this.state.rightPanelOpen) {
      this.setState({ rightPanelOpen: false }, () => {
        window.history.pushState(null, document.title, `/`);
      })
      window.amplitude.getInstance().logEvent('Player profile closed');
    }
  }

  render() {
    const { isMobile, forcedFilters, hideInShortlists, hideNotInShortlists } = this.props;
    const { filterModalOpen, layoutModalOpen, filters, scrollDirection, seletectedPlayer, activeStep, rightPanelOpen, columns, currentLayout, currentSeasons } = this.state;

    // We should not be able to remove forced filters
    const filtersRecap = { ...filters }
    Object.keys(forcedFilters).map(key => delete filtersRecap[key])
    return (
      <Fragment>
        <Container>
          <FilterItem row noGutters middleXs endXs scrollDirection={scrollDirection}>
            <FiltersRecap {...filtersRecap} onFiltersRemove={this.onFiltersRemove} onCountryClick={this.onCountryClick} onAgegroupClick={this.onAgegroupClick} onLeagueClick={this.onLeagueClick} currentSeasons={currentSeasons} />
            <VerticalDivider />
            <Div><ButtonLight onClick={this.openFilterModal}><Icon className="icon-filter" size='large' /><span>Filters </span></ButtonLight></Div>
            <VerticalDivider />
            <Div><ButtonLight onClick={this.openLayoutModal}><Icon className="icon-table" size='large' /><span>Columns </span></ButtonLight></Div>
          </FilterItem>
          <PlayerTable ref={el => this.playerTable = el}
            paging
            onScroll={this.onTableScoll}
            onRowClick={this.onPlayerClick}
            onRowInfoItemClick={this.onPlayerInfoItemClick}
            fetchMethod={Api.playerData}
            persistId={this.props.persistId}
            isMobile={isMobile}
          />
        </Container>
        <FilterModal
          data={{ filters }}
          modalOpen={filterModalOpen}
          handleClose={this.closeFilterModal}
          onSuccess={this.applyFilters}
          onFail={() => { this.resetFilters(); this.closeFilterModal(); }}
          dark
          hideShortlisted
          hideInShortlists={hideInShortlists}
          hideNotInShortlists={hideNotInShortlists}
          size='large'
          currentSeasons={currentSeasons}
        />
        <LayoutModal
          data={{ columns: columns, currentLayout: currentLayout }}
          modalOpen={layoutModalOpen}
          handleClose={this.closeLayoutModal}
          onSuccess={this.applyLayout}
          onFail={() => { this.resetLayout(); this.closeLayoutModal(); }}
          dark
          size='large'
        />
        <Modal
          modalOpen={rightPanelOpen}
          handleClose={this.closeRightPanel}
          size='large'
          hideactions
          dark
        >
          <PlayerProfileContainer player={seletectedPlayer} activeStep={activeStep} dark inModal onPlayerClick={this.onPlayerClick} zIndex={10000} />
        </Modal>

      </Fragment>
    );
  }

}

const mapSizesToProps = ({ width }) => ({
  isMobile: width <= 704,
})

PlayerTableContainer.propTypes = {
  forcedFilters: PropTypes.object,
  persistId: PropTypes.string.isRequired,
  hideInShortlists: PropTypes.bool,
  hideNotInShortlists: PropTypes.bool,
};

PlayerTableContainer.defaultProps = {
  forcedFilters: {},
  hideInShortlists: false,
  hideNotInShortlists: false,
};

export default withSizes(mapSizesToProps)(PlayerTableContainer);
