import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const InfoIcon = styled(Icon)`
  &.icon {
    font-size: 22px;
    vertical-align: bottom;
    margin-right: 0;
    margin-left: -10px;
    background: white;
    border-radius: 11px;
    position: relative;
    transition: all 0.1s ease-in;
    &:first-of-type {
      margin-left: 0;
    }
    &:hover {
      transform: scale(1.5);
      z-index: 9 !important;
    }
  }  
`;

export {
  InfoIcon
};