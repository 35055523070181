import styled from 'styled-components';
import 'react-input-range/lib/css/index.css';
import { Input } from 'semantic-ui-react';
import { FlexboxGrid as Div } from 'baroo';

const StyledInputRange = styled.div`
  padding: 0px 18px 12px 8px;
  .input-range__track--active {
    background: #242c39;
  }
  .input-range__slider {
    background: #fff;
    width: 25px;
    height: 25px;
    border: solid 1px rgba(0,0,0,0.2);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12);
    margin-top: -14px;
    transition: none;
  }
  .input-range__track {
    transition: none;
  }
  .input-range__track--background {
    background: transparent;
    border: none;
    height: 6px;
    &:before{
      content: " ";
    background: #fff;
    height: 6px;
    right: -18px;
    left: -6px;
    border: solid 1px #c9c9c9;
    position: absolute;
    border-radius: 3px;
    top: -1px
    }
  }
  .input-range__label-container {
    left: 0;
  }
  .input-range__label {
    display: none;
  }
  .input-range__slider-container {
    transition: none;
  }
`;

const ValueLabel = Div.extend`
  font-size: 14px;
  color: #000;  
  margin-bottom: 20px;
`;

const StyledTextInput = styled(Input)`
  &.ui.input>input {
    max-width: 48px;
    text-align: center;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 0;
    border-color: rgba(0,0,0,0.2)
  }
`;

export {
  StyledInputRange,
  ValueLabel,
  StyledTextInput,
}
