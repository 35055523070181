/* eslint-disable no-undef */
import qs from 'qs';
import config from '../config/';
import mockData from './mockData';
const API_URL = `${config.apiHost}`;
const isMock = !!config.mock;
const useServer = !!config.useServer;

function fake(fakeResponse) {
  const delay = Math.random();
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(fakeResponse);
    }, delay * 1000);
  });
}
function getToken() {
  return window.localStorage.getItem('token');
}
function buildParamsForServerRequest(params = null) {
  if (params != null) {
    return `discover_id_token=${getToken()}&discover_api_url=${API_URL}&${qs.stringify(params, {
      encodeValuesOnly: true,
      arrayFormat: 'brackets',
    })}`;
  }
  return `discover_id_token=${getToken()}&discover_api_url=${API_URL}`;
}

// GETS
async function getTokenGoogle(id_token, body) {
  const result = await serverPost(`/get_token_google?discover_id_token=${id_token}&discover_api_url=${API_URL}`, body);
  if (result && result.response) {
    return result;
  }
  return null;
}
async function me() {
  if (isMock) {
    return fake(mockData.me);
  }
  if (useServer) {
    const result = await serverGet(`/me?${buildParamsForServerRequest()}`);
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return get('me');
}
async function getCountries() {
  if (isMock) {
    return fake(mockData.countries);
  }
  if (useServer) {
    const result = await serverGet(`/discover/countries?${buildParamsForServerRequest()}`);
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return get('discover/countries');
}
async function getRegions(countries) {
  if (isMock) {
    return fake(mockData.regions);
  }
  if (useServer) {
    const result = await serverGet(`/discover/regions?${buildParamsForServerRequest({ country_ids: countries })}`);
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return get('discover/regions', { country_ids: countries });
}
async function getAgegroups(countries) {
  if (isMock) {
    return fake(mockData.agegroups);
  }
  if (useServer) {
    const result = await serverGet(`/discover/agegroups?${buildParamsForServerRequest({ country_ids: countries })}`);
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return get('discover/agegroups', { country_ids: countries });
}
async function getLeagues(regions = [], agegroups = []) {
  if (isMock) {
    return fake({ response: mockData.leagues.response });
  }
  if (useServer) {
    const result = await serverGet(`/discover/leagues?${buildParamsForServerRequest({ agegroup_ids: agegroups })}`);
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return get('discover/leagues', { agegroup_ids: agegroups });
}
async function getSeasons() {
  if (useServer) {
    const result = await serverGet(`/discover/seasons?${buildParamsForServerRequest()}`);
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return get('/discover/seasons');
}
async function playerData(filters, options) {
  if (isMock) {
    return fake(mockData.playerData);
  }
  if (useServer) {
    const result = await serverGet(`/discover/player_stats?${buildParamsForServerRequest(filters)}`);
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return get('discover/player_stats', filters, options);
}
async function playerProfile(playerId, options) {
  if (isMock) {
    return fake(mockData.playerProfile);
  }
  if (useServer) {
    const result = await serverGet(`/discover/players/${playerId}?${buildParamsForServerRequest()}`);
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return get(`discover/players/${playerId}`, options);
}
async function playerDetailedProfile(playerId, options) {
  if (isMock) {
    return fake(mockData.playerProfile);
  }
  if (useServer) {
    const result = await serverGet(`/discover/detailed_profile/${playerId}?${buildParamsForServerRequest()}`);
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return get(`discover/detailed_profile/${playerId}`, options);
}
async function getMedia(playerSlug, params, options) {
  if (isMock) {
    return fake(mockData.medias);
  }
  if (useServer) {
    const result = await serverGet(`/discover/users/${playerSlug}/media?${buildParamsForServerRequest(params)}`);
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return get(`discover/users/${playerSlug}/media`, params, options);
}
async function getMatches(matchSlug, params, options) {
  if (isMock) {
    return fake(mockData.medias);
  }
  if (useServer) {
    const result = await serverGet(`/discover/matches/${matchSlug}?${buildParamsForServerRequest(params)}`);
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return get(`discover/matches/${matchSlug}`, params, options);
}
async function getEvents(options) {
  if (isMock) {
    return fake(mockData.events);
  }
  if (useServer) {
    const result = await serverGet(`/discover/events?${buildParamsForServerRequest()}`);
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return get('discover/events', options);
}
async function getEvent(eventId, options) {
  if (isMock) {
    return fake(mockData.event);
  }
  if (useServer) {
    const result = await serverGet(`/discover/events/${eventId}?${buildParamsForServerRequest()}`);
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return get(`discover/events/${eventId}`, options);
}
async function getApplicants(eventId, filters, options) {
  if (isMock) {
    return fake(mockData.applicants);
  }
  if (useServer) {
    const result = await serverGet(`/discover/events/${eventId}/applicants?${buildParamsForServerRequest(filters)}`);
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return get(`discover/events/${eventId}/applicants`, filters, options);
}
async function getSelected(eventId, filters, options) {
  if (isMock) {
    return fake(mockData.selected());
  }
  if (useServer) {
    const result = await serverGet(`/discover/events/${eventId}/applicants/selected?${buildParamsForServerRequest(filters)}`);
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return get(`discover/events/${eventId}/applicants/selected`, options);
}
async function getCareerOverview(playerSlug, params, options) {
  if (isMock) {
    return fake(mockData.careerOverview);
  }
  if (useServer) {
    const result = await serverGet(`/discover/players/${playerSlug}/career?${buildParamsForServerRequest(params)}`);
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return get(`discover/players/${playerSlug}/career`, params, options);
}
async function getPlayerNotes(playerSlug, params, options) {
  if (isMock) {
    return fake(mockData.playerNotes);
  }
  if (useServer) {
    const result = await serverGet(`/discover/players/${playerSlug}/notes?${buildParamsForServerRequest(params)}`);
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return get(`discover/players/${playerSlug}/notes`, params, options);
}
async function searchPlayer(query, options) {
  if (isMock) {
    return fake(mockData.searchPlayer);
  }
  if (useServer) {
    const result = await serverGet(`/discover/search?${buildParamsForServerRequest({ query })}`);
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return get('discover/search', { query }, options);
}
async function getMatchHistory(playerSlug, params, options) {
  if (isMock) {
    return fake(mockData.matchHistory());
  }
  if (useServer) {
    const result = await serverGet(`/discover/players/${playerSlug}/previous_matches?${buildParamsForServerRequest(params)}`);
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return get(`discover/players/${playerSlug}/previous_matches`, params, options);
}
async function getNextMatches(playerSlug, params, options) {
  if (isMock) {
    return fake(mockData.matchHistory());
  }
  if (useServer) {
    const result = await serverGet(`/discover/players/${playerSlug}/next_matches?${buildParamsForServerRequest(params)}`);
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return get(`discover/players/${playerSlug}/next_matches`, params, options);
}
async function getShortlists(params, options) {
  if (isMock) {
    return fake(mockData.shortlists);
  }
  if (useServer) {
    const result = await serverGet(`/discover/shortlists?${buildParamsForServerRequest(params)}`);
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return get('discover/shortlists', params, options);
}
async function getShortlist(shortlistId, params, options) {
  if (isMock) {
    return fake(mockData.shortlist);
  }
  if (useServer) {
    const result = await serverGet(`/discover/shortlists/${shortlistId}?${buildParamsForServerRequest(params)}`);
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return get(`discover/shortlists/${shortlistId}`, params, options);
}

// POSTS
// TODO: TEST
async function selectPlayers(eventId, players = [], options) {
  if (isMock) {
    return fake({});
  }
  if (useServer) {
    const result = await serverPost(`/discover/events/${eventId}/applicants/selected?${buildParamsForServerRequest()}`, { user_slugs: players });
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return post(`discover/events/${eventId}/applicants/selected`, { user_slugs: players }, options);
}
// TODO: TEST
async function selectAll(eventId, options) {
  if (isMock) {
    return fake({});
  }
  if (useServer) {
    const result = await serverPost(`/discover/events/${eventId}/applicants/selected?${buildParamsForServerRequest()}`, { select_all: true });
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return post(`discover/events/${eventId}/applicants/selected`, { select_all: true }, options);
}
// TODO: TEST
async function setPermission(eventId, player = {}, permission, options) {
  if (isMock) {
    return fake({});
  }
  if (useServer) {
    const result = await serverPost(`/discover/events/${eventId}/applicants/${player.slug}/permissions?${buildParamsForServerRequest()}`, { result: permission });
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return post(`discover/events/${eventId}/applicants/${player.slug}/permissions`, { result: permission }, options);
}
async function createPlayerNote(playerSlug, note, options) {
  if (isMock) {
    const res = { response: { ...mockData.playerNotes.response[0] } };
    res.response.id = Math.floor(Math.random() * 1000);
    res.response.description += ' created';
    return fake(res);
  }
  if (useServer) {
    const result = await serverPost(`/discover/players/${playerSlug}/notes?${buildParamsForServerRequest()}`, note);
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return post(`discover/players/${playerSlug}/notes`, note, 'POST', options);
}
async function updatePlayerNote(playerSlug, note, options) {
  if (isMock) {
    const res = { response: { ...mockData.playerNotes.response[0] } };
    res.response.description += ' updated';
    res.response.id = note.id;

    return fake(res);
  }
  if (useServer) {
    const result = await serverPost(`/discover/players/${playerSlug}/notes/${note.id}?${buildParamsForServerRequest()}`, note);
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return post(`discover/players/${playerSlug}/notes/${note.id}`, note, 'PATCH', options);
}
async function deletePlayerNote(playerSlug, note, options) {
  if (isMock) {
    return fake({});
  }
  if (useServer) {
    const result = await serverPost(`/discover/players/${playerSlug}/notes/${note.id}?${buildParamsForServerRequest()}`, {}, "DELETE");
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return del(`discover/players/${playerSlug}/notes/${note.id}`, null, options);
}
async function createShortlist(shortlist, options) {
  if (isMock) {
    return fake(mockData.shortlist);
  }
  if (useServer) {
    const result = await serverPost(`/discover/shortlists?${buildParamsForServerRequest()}`, { shortlist });
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return post('discover/shortlists', { shortlist }, 'POST', options);
}
async function updateShortlist(shortlist, options) {
  if (isMock) {
    return fake(mockData.shortlist);
  }
  if (useServer) {
    const result = await serverPost(`/discover/shortlists/${shortlist.id}?${buildParamsForServerRequest()}`, { shortlist });
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return post(`discover/shortlists/${shortlist.id}`, { shortlist }, 'PATCH', options);
}
async function deleteShortlist(shortlist, options) {
  if (isMock) {
    return fake({});
  }
  if (useServer) {
    const result = await serverPost(`/discover/shortlists/${shortlist.id}?${buildParamsForServerRequest()}`, {}, "DELETE");
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return del(`discover/shortlists/${shortlist.id}`, options);
}
// TODO: Server Call
async function addPlayerToShortlist(shortlistId, playerId, options) {
  if (isMock) {
    return fake({});
  }
  if (useServer) {
    const result = await serverPost(`/discover/shortlists/${shortlistId}/add_user?${buildParamsForServerRequest()}`, { user_id: playerId });
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return post(`discover/shortlists/${shortlistId}/add_user`, { user_id: playerId }, 'POST', options);
}
// TODO: Server Call
async function removePlayerFromShortlist(shortlistId, playerId, options) {
  if (isMock) {
    return fake({});
  }
  if (useServer) {
    const result = await serverPost(`/discover/shortlists/${shortlistId}/remove_user?${buildParamsForServerRequest()}`, { user_id: playerId });
    if (result && result.response) {
      return result;
    }
    return null;
  }
  return post(`discover/shortlists/${shortlistId}/remove_user`, { user_id: playerId }, 'POST', options);
}

function get(endpoint, params = {}, options = {}) {
  return fetch(
    `${API_URL}/${endpoint}?${qs.stringify(params, {
      encodeValuesOnly: true,
      arrayFormat: 'brackets',
    })}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      ...options,
    }
  )
    .then(checkStatus)
    .then(parseJSON);
}

function post(endpoint, body, method = 'POST', options = {}) {
  return fetch(`${API_URL}/${endpoint}`, {
    method,
    headers: {
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Content-type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(body),
    ...options,
  })
    .then(checkStatus)
    .then(parseJSON);
}

function put(endpoint, body, options) {
  return post(endpoint, body, 'PATCH', options);
}

function del(endpoint, body, options) {
  return post(endpoint, body, 'DELETE', options);
}

async function serverGet(url) {
  return await fetch(url)
    .then(resonse => resonse.json())
    .then(response => {
      return JSON.parse(JSON.stringify(response));
    })
    .catch(error => console.error('Error:', error));
}

async function serverPost(url, body = {}, method = "POST") {
  let options = {
    headers: {
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Content-type': 'application/json',
    },
    method,
    body: JSON.stringify(body),
  };
  return await fetch(url, options)
    .then(resonse => resonse.json())
    .then(response => {
      return JSON.parse(JSON.stringify(response));
    })
    .catch(error => console.error('Error:', error));
}


function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(`HTTP Error ${response.statusText}`);
  error.status = response.statusText;
  error.response = response;
  console.log(error);
  throw error;
}

function parseJSON(response) {
  if (response.status === 204) return;
  return response.json();
}

const Api = {
  getTokenGoogle,
  me,
  playerData,
  playerProfile,
  playerDetailedProfile,
  getCountries,
  getRegions,
  getAgegroups,
  getLeagues,
  getSeasons,
  getMedia,
  getMatches,
  getEvents,
  getEvent,
  getApplicants,
  getSelected,
  selectPlayers,
  selectAll,
  setPermission,
  getCareerOverview,
  getPlayerNotes,
  createPlayerNote,
  updatePlayerNote,
  deletePlayerNote,
  searchPlayer,
  getMatchHistory,
  getNextMatches,
  getShortlists,
  getShortlist,
  createShortlist,
  updateShortlist,
  deleteShortlist,
  addPlayerToShortlist,
  removePlayerFromShortlist,
  post,
  get,
  put,
  del,
};
export default Api;
