import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import _ from 'lodash';
import { FlexboxGrid as Div } from 'baroo';

import {
  StyledInputRange,
  ValueLabel,
} from '../../assets/themes/styles/components/Range/Range';

class Range extends Component {
  onMaxInputChange = ({ target }) => {
    const { value, onChange, maxValue, minValue } = this.props;
    const number = _.toNumber(target.value)
    if (!_.isNaN(number) && number <= maxValue && number >= minValue) {
      onChange({ min: value.min, max: number })
    }
  }
  onMinInputChange = ({ target }) => {
    const { value, onChange, maxValue, minValue } = this.props;
    const number = _.toNumber(target.value)
    if (!_.isNaN(number) && number <= maxValue && number >= minValue) {
      onChange({ min: number, max: value.max })
    }
  }

  render() {
    const { minValue, maxValue, value, onChange, formatLabel, step } = this.props;
    return (
      <div>
        <ValueLabel betweenXs dFlexXs>
          <Div>{formatLabel(value.min)}</Div>
          <Div>{formatLabel(value.max)}</Div>
        </ValueLabel>
        <StyledInputRange>
          <InputRange
            allowSameValues
            step={step}
            maxValue={maxValue}
            minValue={minValue}
            value={value}
            onChange={onChange} />
        </StyledInputRange>
      </div>
    );
  }
}

Range.propTypes = {
  step: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  formatLabel: PropTypes.func
};

Range.defaultProps = {
  min: 0,
  formatLabel: (value) => value
};
export default Range;
