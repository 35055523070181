import React from 'react';
import { FlexboxGrid as Div } from 'baroo';
import Intercom from 'react-intercom';
import Header from '../components/Header';
import PlayerTableContainer from '../components/PlayerTable/PlayerTableContainer';
import {
  FullHeight
} from '../assets/themes/styles/playerSearch/PlayerSearchPage';

const PlayerSearchPage = ({ user, playerId }) => {
  let intercomOptions = {};
  if (user) {
    intercomOptions = {
      appID: 'dklrpfzw',
      user_id: user.slug,
      email: user.email,
      name: `${user.firstname} ${user.lastname}`,
      alignment: 'left',
    };
  }
  return (
    <FullHeight>
      <Header user={user} />
      <PlayerTableContainer persistId="player-search" playerId={playerId} />
      {user && <Div dNoneXs dFlexSm><Intercom {...intercomOptions} /></Div>}
    </FullHeight>
  );
};

export default PlayerSearchPage;
