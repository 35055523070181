import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import 'video-react/dist/video-react.css';
import { Themes } from 'baroo';

const ModalStyled = styled(Modal)`
  max-height: 100%;
  max-width: 100%;
  margin-top: 0 !important;
  width: auto !important;
  > .content {
    max-width: 1024px;
  }
`;

const MediaContainer = styled.div`
  background-color: #111111;
  width: 100vw;
  position: absolute;
  margin-left: -50vw;
  height: 105vh;
  left: 50%;
  top: 0
  z-index: 999;
`;

const Arrow = styled.div`
  background: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 10px;
    position: absolute;
    top: 50%;
    user-select: none;
    height: 120px;
    margin-top: -60px;
    width: 40px;
    z-index: 1002;
    ${Themes.Media.sm`
      width: 70px;
    `}
`;

const ArrowLeft = Arrow.extend`
  left: 10px;
`;

const ArrowRight = Arrow.extend`  
  right: 10px;
`;

const Footer = styled.div`
  max-width: ${(props) => props.maxWidth ? `${props.maxWidth}px` : '100%'};
  padding: 6px 0;
`;

const Content = styled.div`
  color: #eee;
`;
const Label = styled.div`
  color: #fff;
`;

const Media = styled.div`
  text-align: center;
  position: relative;
  width: fit-content;
  > img {
    max-height: calc(100vh - 120px);
    max-width: 100%;
  }
  > .video-react {
    position: absolute;
    height: 100% !important;
    top: 0;
    font-family: 'Open Sans';
  }
`;
const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 30px;
  cursor: pointer;
  color: #ffffff;
  z-index: 9999;
`;

const Header = styled.div`
  max-width: ${(props) => props.maxWidth ? `${props.maxWidth}px` : '100%'};
  padding: 6px 0;
`;

export {
  ModalStyled,
  Arrow,
  ArrowLeft,
  ArrowRight,
  Footer,
  Content,
  Label,
  Media,
  MediaContainer,
  Close,
  Header
};
