import styled from 'styled-components';
import { Image } from 'semantic-ui-react';
import { FlexboxGrid as Div } from 'baroo';

const Avatar = styled(Image)`
  &.ui.avatar.image {
    margin-right: 10px;
    width: 28px  !important;
    height: 28px !important;
    background: #b1b1b1;
    overflow: visible !important;
    border-radius: 500rem !important;
  }
`;

const Container = Div.extend`
  display: flex;
  align-items: center;
  line-height: 1.2;
  > div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
 
`;

export {
  Avatar,
  Container
};
