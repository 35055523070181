import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FlexboxGrid as Div, Button } from 'baroo';
import { TransitionablePortal, Message, Checkbox } from 'semantic-ui-react';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import DataTable from '../DataTable';
import Spinner from '../Spinner';
import _ from 'lodash';
import dayjs from 'dayjs';
import Api from '../../services/api';
import UserAvatar from '../UserAvatar';
import { POSITIONS } from '../../services/utils';
import UserInfo from './UserInfo';
import { TextCenter, TextRight, } from '../../assets/themes/styles/components/Styled/Styled';
import {
  DataTableItem,
  MessageStyled,
  DataLoading,
  InviteStatus,
  Pagination,
  PaginationContainer,
  StyledIcon,
  PaginationArrowLeft,
  PaginationArrowRight,
  PaginationPageNumber,
  StyledInput
} from '../../assets/themes/styles/components/PlayerTable/PlayerTable';

import "../../../src/assets/themes/Pagination.css";

const RESET_LAYOUT_DATE = "21-01-2019"

const INVITE_STATUS = {
  DECLINED: 'Declined',
  INVITED: 'Invited',
  PERMISSIONS: 'Permissions',
  ATTENDING: 'Attending'
}

const getInviteStatus = (status) => {
  switch (status) {
    case 'player_declined':
    case 'club_declined':
    case 'parent_declined':
      return INVITE_STATUS.DECLINED
    case 'attending':
    case 'approved':
    case 'attended':
      return INVITE_STATUS.ATTENDING
    case 'invite_accepted':
      return INVITE_STATUS.PERMISSIONS
    default:
      return INVITE_STATUS.INVITED
  }
}

class PlayerTable extends Component {
  COLUMNS = {
    //RANK: {accessor: (d) => <ShortlistIcon shortlisted={d.shortlisted} onClick={(e) => {e.stopPropagation(); this.shortlist(d)}} />, width: 98, header: 'Shortlist', key: 'rank', alwaysShow: true, categories: ['core'], order: 0},
    USER: { accessor: (d) => <UserAvatar user={d} />, width: 262, header: 'Player', key: 'name', alwaysShow: true, categories: ['core'], order: 1 },
    //CURRENT_CLUB: {accessor: 'current_club', width: 162, header: 'Current Club', key: 'currentClub', alwaysShow: true, categories: ['core'], order: 2},
    LEAGUES_LEVEL: { accessor: (d) => d.leagues_level && 'Level ' + (_.isArray(d.leagues_level) ? d.leagues_level.join(', ') : d.leagues_level), width: 120, header: 'League Level', key: 'leaguesLevel', alwaysShow: true, categories: ['core'], order: 3 },
    INFO: { accessor: (d) => <UserInfo onRowInfoItemClick={this.props.onRowInfoItemClick} user={d} />, width: 114, header: 'Info', key: 'info', alwaysShow: true, categories: ['core'], order: 4 },    //114
    CLUBS: { accessor: (d) => _.isArray(d.clubs) ? d.clubs.join(', ') : d.clubs, width: 162, header: 'Clubs', key: 'club', categories: ['team'], order: 5 },
    BIRTHDAY: { accessor: (d) => d.birthday && `${d.birthday} (${dayjs().diff(dayjs(d.birthday), 'years')})`, width: 146, header: 'Birthday', key: 'birthday', categories: ['bio'], order: 6 },
    COUNTRY: { accessor: (d) => _.isArray(d.countries) ? d.countries.join(', ') : d.countries, width: 94, header: 'Based in', key: 'country', categories: ['team'], order: 7 },
    AGE_GROUP: { accessor: (d) => _.isArray(d.agegroups) ? d.agegroups.join(', ') : d.agegroups, width: 102, header: 'Age Group', key: 'agegroup', categories: ['team'], order: 8 },
    LEAGUES: { accessor: (d) => _.isArray(d.leagues) ? d.leagues.join(', ') : d.leagues, width: 240, header: 'Leagues', key: 'leagues', categories: ['team'], order: 9 },
    POSITION: { accessor: (d) => d.primary_position && POSITIONS.find(p => p.id === d.primary_position).abbreviation, width: 102, header: 'Position', key: 'position', categories: ['performance'], order: 10 },
    FOOT: { accessor: 'primary_foot', width: 88, header: 'Foot', key: 'foot', categories: ['bio'], order: 11 },
    MATCHES: { accessor: (d) => `${d.matches_played} (${d.matches_played_as_substitute})`, width: 122, header: 'Matches (Sub)', key: 'matches_played', categories: ['performance'], sortable: true, order: 12 },
    PLAYER_PERF: { accessor: 'player_performance', width: 120, header: 'Player Perf.', key: 'player_performance', categories: ['performance'], sortable: false, order: 13 },
    RATING: { accessor: (d) => d.average_rating && d.average_rating.toFixed(2), width: 130, header: 'Match rating', key: 'average_rating', categories: ['performance'], sortable: true, order: 14 },
    GOALS: { accessor: 'goals', width: 76, header: 'Goals', key: 'goals', categories: ['performance'], sortable: true, order: 15 },
    ASSISTS: { accessor: 'assists', width: 82, header: 'Assists', key: 'assists', categories: ['performance'], sortable: true, order: 16 },
    CLEAN_SHEETS: { accessor: 'clean_sheets', width: 120, header: 'Clean sheets', key: 'clean_sheets', categories: ['performance'], sortable: true, order: 17 },
    VOTES: { accessor: 'motm_votes', width: 74, header: 'Votes', key: 'motm_votes', categories: ['performance'], sortable: true, order: 18 },
    VOTES_PERCENTAGE: { accessor: (d) => d.team_motm_votes_ratio && `${(d.team_motm_votes_ratio * 100).toFixed(0)}%`, width: 84, header: 'Votes %', key: 'votesErcentage', categories: ['performance'], order: 19 },
    HEIGHT: { accessor: 'height', width: 78, header: 'Height', key: 'height', categories: ['bio'], order: 20 },
    WEIGHT: { accessor: 'weight', width: 80, header: 'Weight', key: 'weight', categories: ['bio'], order: 21 },
    PLAYER_TYPE: { accessor: 'top_3_skills', width: 324, header: 'Top 3 skills', key: 'playerType', categories: ['performance'], order: 22 },
    AGENT: { accessor: (d) => d.agent ? 'Yes' : 'No', width: 78, header: 'Agent', key: 'agent', categories: ['bio'], order: 23 },
    CONTRACT: { accessor: (d) => d.contract ? 'Yes' : 'No', width: 96, header: 'Contract', key: 'contract', categories: ['bio'], order: 24 },
    SHIRT_NUMBER: { accessor: 'shirt_number', width: 120, header: 'Shirt number', key: 'shirtNumber', categories: ['bio'], order: 25 },
    BOOT: { accessor: 'boot', width: 122, header: 'Football Boot', key: 'boot', categories: ['bio'], order: 26 },
    FAV_CLUB: { accessor: 'fandom_club', width: 122, header: 'Favorite Club', key: 'favClub', categories: ['bio'], order: 27 },
    FAV_BRAND: { accessor: 'fandom_brand', width: 132, header: 'Favorite Brand', key: 'favBrand', categories: ['bio'], order: 28 },
    TEAM_PERF: { accessor: 'team_performance', width: 120, header: 'Team Perf.', key: 'team_performance', categories: ['team'], sortable: false, order: 29 },
    RESULTS: { accessor: (d) => `${d.wins}/${d.ties}/${d.losses}`, width: 116, header: 'Win/tie/loss', key: 'results', categories: ['team'], sortable: true, order: 30 },
    TEAM_GOALS: { accessor: 'team_goals', width: 108, header: 'Team goals', key: 'teamGoalsAgainst', categories: ['team'], order: 31 },
    GOALS_AGAINST: { accessor: 'team_goals_against', width: 160, header: 'Team goals against', key: 'goalsAgainst', categories: ['team'], order: 32 },
    NEXT_OPPONENT: { accessor: 'next_match_opponent', width: 132, header: 'Next Opponent', key: 'nextOpponent', categories: ['team'], order: 33 },
    NEXT_MATCH_DATE: { accessor: (d) => d.next_match_date && dayjs(d.next_match_date).format('YYYY-MM-DD HH:mm'), width: 142, header: 'Next Match Date', key: 'nextMatchDate', categories: ['team'], order: 34 },

    APPLICANT_STATUS: { accessor: (d) => <TextRight onClick={(e) => { e.stopPropagation(); }}><InviteStatus status={getInviteStatus(d.applicant_state)} onClick={(e) => { e.stopPropagation(); this.props.openInvitesModal() }}>{getInviteStatus(d.applicant_state)}</InviteStatus></TextRight>, width: 142, header: <TextRight>Invite status</TextRight>, key: 'applicantStatus', categories: ['core'] },
    SELECT: { accessor: (d) => <TextCenter onClick={(e) => { e.stopPropagation(); }}><Checkbox defaultChecked={d.selected} disabled={d.selected || this.props.selectionDisabled} onChange={(e, { checked }) => { console.log('onChange'); checked ? this.props.onRowSelect(d) : this.props.onRowDeselect(d) }} /></TextCenter>, header: <TextCenter>Select</TextCenter>, width: 100, key: 'select', categories: ['core'] },
  }
  CORE_COLS = [this.COLUMNS.RANK, this.COLUMNS.USER, this.COLUMNS.INFO, this.COLUMNS.COUNTRY, this.COLUMNS.CLUBS, this.COLUMNS.AGE_GROUP, this.COLUMNS.LEAGUES, this.COLUMNS.BIRTHDAY, this.COLUMNS.POSITION, this.COLUMNS.RATING]
  BIO_COLS = [this.COLUMNS.HEIGHT, this.COLUMNS.WEIGHT, this.COLUMNS.FOOT, this.COLUMNS.AGENT, this.COLUMNS.CONTRACT, this.COLUMNS.SHIRT_NUMBER, this.COLUMNS.FAV_CLUB, this.COLUMNS.FAV_BRAND];
  PERFORMANCE_COLS = [this.COLUMNS.MATCHES, this.COLUMNS.VOTES, this.COLUMNS.VOTES_PERCENTAGE, this.COLUMNS.GOALS, this.COLUMNS.ASSISTS, this.COLUMNS.CLEAN_SHEETS, this.COLUMNS.PLAYER_TYPE];
  TEAM_COLS = [this.COLUMNS.RESULTS, this.COLUMNS.TEAM_GOALS, this.COLUMNS.GOALS_AGAINST, this.COLUMNS.NEXT_OPPONENT, this.COLUMNS.NEXT_MATCH_DATE];

  DEFAULT_LAYOUT = [this.COLUMNS.USER, this.COLUMNS.LEAGUES_LEVEL, this.COLUMNS.INFO, this.COLUMNS.BIRTHDAY, this.COLUMNS.AGE_GROUP, this.COLUMNS.POSITION, this.COLUMNS.MATCHES, this.COLUMNS.PLAYER_PERF, this.COLUMNS.RATING, this.COLUMNS.GOALS, this.COLUMNS.ASSISTS, this.COLUMNS.CLEAN_SHEETS, this.COLUMNS.VOTES, this.COLUMNS.VOTES_PERCENTAGE, this.COLUMNS.TEAM_PERF, this.COLUMNS.NEXT_OPPONENT, this.COLUMNS.NEXT_MATCH_DATE];
  BIO_LAYOUT = [...this.CORE_COLS, ...this.BIO_COLS];
  PERFORMANCE_LAYOUT = [...this.CORE_COLS, ...this.PERFORMANCE_COLS];
  TEAM_LAYOUT = [...this.CORE_COLS, ...this.TEAM_COLS];

  LAYOUTS = [
    { name: 'All', columns: this.DEFAULT_LAYOUT },
    { name: 'Biography', columns: this.BIO_LAYOUT },
    { name: 'Performance', columns: this.PERFORMANCE_LAYOUT },
    { name: 'Team', columns: this.TEAM_LAYOUT },
  ]
  PAGE_SIZE = 50
  defaultSort = { sort_by: this.COLUMNS.VOTES.key, sort_direction: 'desc' }
  constructor(props) {
    super(props);
    this.state = {
      layout: [...this.DEFAULT_LAYOUT],
      data: [],
      filters: {},
      sort: this.defaultSort,
      error: null,
      hasNextPage: true,
      isNextPageLoading: false,
      noOfPages: 0,
      currentPage: 1,
      previousePage: 1,
      modalOpen: false,
      typingTimeout: 0,
      isTyping: false
    };
  }

  componentDidMount() {
    const { persistId, paging } = this.props;
    let filters = this.state.filters
    if (persistId) {
      try {
        window.amplitude.getInstance().logEvent('Data table page shown')
        const savedFilters = window.localStorage.getItem(`filters-${persistId}`)
        if (savedFilters) {
          filters = JSON.parse(savedFilters)
          this.setState({ filters, hasNextPage: paging })
        }
        const savedLayoutKeys = window.localStorage.getItem(`layout-${persistId}`);
        const lastResetLayoutDate = window.localStorage.getItem(`lastResetLayoutDate-${persistId}`)
        if (!lastResetLayoutDate || moment(lastResetLayoutDate).isBefore(moment(RESET_LAYOUT_DATE))) {
          this.setLayout()
          try {
            window.localStorage.setItem(`lastResetLayoutDate-${persistId}`, moment(RESET_LAYOUT_DATE).format())
          } catch (e) { }
        } else if (savedLayoutKeys) {
          this.setState({ layout: JSON.parse(savedLayoutKeys).map(key => _.find(this.COLUMNS, { key })).filter(col => !!col), hasNextPage: paging })
        }
      } catch (e) { }
    }
    this.fetchData(filters)
  }

  componentWillUnmount() {
    if (this.request) {
      this.request.abort()
    }
  }

  filtersToIdsArray = (filters) => {
    const { countries, agegroups, leagues, shortlist_ids, not_in_shortlist_ids, ...etc } = filters;
    const convertedFilters = { ...etc }
    if (countries && countries.length) {
      convertedFilters.country_ids = countries.map(c => c.id);
    }
    if (agegroups && agegroups.length) {
      convertedFilters.agegroup_ids = agegroups.map(a => a.id);
    }
    if (leagues && leagues.length) {
      convertedFilters.league_ids = leagues.map(l => l.id);
    }

    if (shortlist_ids && shortlist_ids.length && shortlist_ids[0].id) {
      convertedFilters.shortlist_ids = shortlist_ids.map(s => s.id);
    }
    if (not_in_shortlist_ids && not_in_shortlist_ids.length && not_in_shortlist_ids[0].id) {
      convertedFilters.not_in_shortlist_ids = not_in_shortlist_ids.map(s => s.id);
    }
    return convertedFilters
  }

  fetchData = async (filters = {}, sort = this.state.sort) => {
    const { paging } = this.props;
    if (this.request) {
      this.request.abort()
    }
    this.request = new window.AbortController();
    this.setState({ isNextPageLoading: true, error: null, })
    await this.props.fetchMethod({ ...sort, page: this.state.currentPage, page_size: this.PAGE_SIZE, ...this.filtersToIdsArray(filters) }, { signal: this.request.signal })
      .then(data => {
        this.request = null;
        const nextPage = data.response.users;
        if (nextPage.length) {
          this.setState({ data: [...nextPage], isNextPageLoading: false, isTyping: false, hasNextPage: paging && nextPage.length === this.PAGE_SIZE, noOfPages: Math.ceil(data.response.total / this.PAGE_SIZE) });
          // this.dataTable.forceSync();
        } else {

          this.setState({ isNextPageLoading: false, isTyping: false, hasNextPage: false, noOfPages: Math.ceil(data.response.total / this.PAGE_SIZE) });
        }
      })
      .catch(e => {
        this.request = null;
        if (e && e.name === 'AbortError') {
          return
        }
        this.setState({ isNextPageLoading: false, isTyping: false, hasNextPage: false, error: 'Cannot fetch data, please try again later' });
      })
  }

  loadNextPage = () => {
    const page = 1 + Math.round(this.state.data.length / this.PAGE_SIZE);
    window.amplitude.getInstance().logEvent('Load next page', { page });
    return this.fetchData({ ...this.state.filters, page })
  }


  shortlist = (user) => {
    if (user.shortlisted) {
      window.amplitude.getInstance().logEvent('Shortlist removed', { slug: user.slug });
      Api.del('users/' + user.slug + '/unfollow')
        .catch(e => {
          console.log('fail del shortlist', e);
        })
    } else {
      window.amplitude.getInstance().logEvent('Shortlist added', { slug: user.slug });
      Api.post('users/' + user.slug + '/follow')
        .catch(e => {
          console.log('fail put shortlist', e);
        })
    }
    user.shortlisted = !user.shortlisted;
    if (this.dataTable) {
      this.dataTable.refreshTable();
    }
  }

  persistLayout = (layout) => {
    const { persistId } = this.props;
    if (!persistId) { return }
    try {
      window.localStorage.setItem(`layout-${persistId}`, JSON.stringify(layout.map(col => col.key)))
    } catch (e) { }
  }

  persistFilters = (filters) => {
    const { persistId } = this.props;
    if (!persistId) { return }
    try {
      window.localStorage.setItem(`filters-${persistId}`, JSON.stringify(filters))
    } catch (e) { }
  }

  onSortClick = (col) => {
    const clickedKey = col.key;
    const newSort = { ...this.state.sort };
    const previousKey = this.state.sort.sort_by;
    const previousDirection = this.state.sort.sort_direction;

    if (clickedKey === 'results') {
      newSort.sort_direction = 'desc';
      newSort.sort_by = previousKey === 'wins' ? 'losses' : 'wins';
    } else if (clickedKey === previousKey) {
      newSort.sort_direction = previousDirection === 'desc' ? 'asc' : 'desc';
    } else {
      newSort.sort_by = clickedKey;
      newSort.sort_direction = 'desc';
    }

    window.amplitude.getInstance().logEvent('Column sort clicked', { columnName: clickedKey, direction: newSort.sort_direction });

    this.setState({ sort: newSort, data: [] })
    this.fetchData(this.state.filters, newSort)
    // TODO persist sort
    //this.persistFilters(newFilters)
  }

  // public method
  setFilters = (filters = {}, reset = false) => {
    this.setState({ currentPage: 1 }, () => {
      this.setState(prevState => {
        const newFilters = reset ? filters : { ...prevState.filters, ...filters }
        this.fetchData(newFilters);
        this.persistFilters(newFilters);
        return { filters: newFilters, data: [] }
      })
    });

  }

  setLayout = (layout = this.DEFAULT_LAYOUT) => {
    console.log(layout.indexOf('applicantStatus'))
    this.setState({ layout })
    this.persistLayout(layout)
  }

  getFilters = () => {
    return { ...this.state.filters }
  }

  getColumns = () => {
    return { ...this.COLUMNS }
  }

  getLayout = () => {
    return [...this.state.layout]
  }

  getData = () => {
    return [...this.state.data]
  }

  handlePageDown = () => {
    const { currentPage, isNextPageLoading } = this.state;
    if (!isNextPageLoading && currentPage !== "" && currentPage > 1) {
      this.setState({ isNextPageLoading: true, currentPage: parseInt(currentPage) - 1 }, () => {
        window.amplitude.getInstance().logEvent('Load previous page', { currentPage });
        this.fetchData({ ...this.state.filters })
      });
    }
  }

  handlePageUp = () => {
    const { noOfPages, currentPage, isNextPageLoading } = this.state;
    if (!isNextPageLoading && currentPage !== "" && noOfPages > currentPage) {
      this.setState({ isNextPageLoading: true, currentPage: parseInt(currentPage) + 1 }, () => {
        window.amplitude.getInstance().logEvent('Load next page', { currentPage });
        this.fetchData({ ...this.state.filters })
      });
    }
  }

  handleSelectChange = (event, data) => {
    const { noOfPages, typingTimeout, isTyping } = this.state;
    this.setState({ isTyping: true });
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    if (!isTyping) {
      this.setState({ previousePage: this.state.currentPage });
    }

    // Only fetch new data if there is a value entered
    if (!data.value || isNaN(data.value)) {
      this.setState({ currentPage: "" });
    } else {
      let currentPageValue = data.value;
      // If the value is greater than the total pages set it to the total pages
      // If the value is less than 1 set it to 1
      if (noOfPages < data.value) {
        currentPageValue = noOfPages;
      } else if (data.value < 1) {
        currentPageValue = 1;
      }

      this.setState({
        currentPage: parseInt(currentPageValue),
        typingTimeout: setTimeout(() => {
          window.amplitude.getInstance().logEvent('Load selected page', { currentPageValue });
          this.fetchData({ ...this.state.filters })
        }, 800)
      });
    }
  }
  handleSelectEnter = (event) => {
    const { currentPage } = this.state;
    if (event.keyCode === 13) {
      window.amplitude.getInstance().logEvent('Load selected page', { currentPage });
      this.fetchData({ ...this.state.filters })
    }
  }
  handleOnBlur = () => {
    const { currentPage, previousePage } = this.state;
    if (currentPage === "") {
      this.setState({ currentPage: previousePage }, () => {
        // window.amplitude.getInstance().logEvent('Load selected page', { currentPage });
        // this.fetchData({ ...this.state.filters })
      });
    }
  }

  buildPages = () => {
    const { currentPage, isNextPageLoading } = this.state;
    const defaultWidth = 40;
    const adjustedWidth = defaultWidth + currentPage.toString().length * 5.5;
    return <StyledInput
      adjustedWidth={currentPage.toString().length === 1 ? defaultWidth : adjustedWidth}
      disabled={isNextPageLoading}
      value={!currentPage ? "" : currentPage}
      onBlur={() => this.handleOnBlur()}
      // onKeyDown={this.handleSelectEnter}
      onChange={this.handleSelectChange}
    />
  }

  buildPagination() {
    const { noOfPages, currentPage, isNextPageLoading, isTyping } = this.state;
    return <PaginationContainer>
      < Pagination >
        <PaginationArrowLeft onClick={this.handlePageDown}><StyledIcon name="arrow alternate circle left outline" disabled={(isTyping || isNextPageLoading) || currentPage === 1 || currentPage === ""} /></PaginationArrowLeft>
        <PaginationPageNumber>
          {this.buildPages()} of {noOfPages}
        </PaginationPageNumber>
        <PaginationArrowRight onClick={this.handlePageUp}><StyledIcon name="arrow alternate circle right outline" disabled={(isTyping || isNextPageLoading) || currentPage === noOfPages || currentPage === ""} /></PaginationArrowRight>
      </Pagination >
    </PaginationContainer >;
  }
  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.currentPage !== this.state.currentPage) {
  //     if (this.state.currentPage === "") {
  //       this.setState({ currentPage: prevState.currentPage });
  //     }
  //   }
  // }
  render() {
    const { isMobile, showSelectCol, showStatusCol, filters, onScroll, onRowClick, onRowInfoItemClick, ...etc } = this.props;
    const { layout, data, hasNextPage, isNextPageLoading, sort, error } = this.state;

    let sortKey = sort.sort_by;
    let sortDirection = sort.sort_direction;
    if (sortKey === 'wins' || sortKey === 'losses') {
      sortDirection = sortKey === 'wins' ? 'desc' : 'asc';
      sortKey = 'results';
    }
    let fixedRightColumnCount = 0;
    let myLayout = [...layout]
    if (showSelectCol) {
      fixedRightColumnCount += 1
      myLayout.push(this.COLUMNS.SELECT)
    }
    if (showStatusCol) {
      fixedRightColumnCount += 1
      myLayout.push(this.COLUMNS.APPLICANT_STATUS)
    }

    return (
      <Fragment>
        <Fragment>
          <DataTableItem {...etc}>
            {isNextPageLoading &&
              <DataLoading>
                <Spinner />
                <Div>Loading players...</Div>
              </DataLoading>
            }
            {!isNextPageLoading && <DataTable
              ref={el => this.dataTable = el}
              data={data}
              columns={myLayout}
              fixedColumnCount={isMobile ? 0 : 3}
              fixedRightColumnCount={isMobile ? 0 : fixedRightColumnCount}
              loadNextPage={this.loadNextPage}
              hasNextPage={hasNextPage}
              isNextPageLoading={isNextPageLoading}
              onSort={this.onSortClick}
              sortKey={sortKey}
              sortDirection={sortDirection}
              onScroll={(e) => { e.stopPropagation(); }}
              onRowClick={onRowClick}
              rowheight={40}

            />}

          </DataTableItem>
          <TransitionablePortal open={!!error} transition={{ animation: 'fade', duration: 300 }}>
            <MessageStyled negative style={{ left: '40%', position: 'fixed', top: '50%', zIndex: 1000 }}>
              <Message.Header>Sorry, we can't fetch data</Message.Header>
              <Message.Content><Button btnXs onClick={() => { this.setState({ data: [] }); this.fetchData(filters) }}>Please try again</Button></Message.Content>
            </MessageStyled>

          </TransitionablePortal>

        </Fragment>
        <Fragment>
          {data.length !== 0 && this.buildPagination()}
        </Fragment>
      </Fragment >
    );
  }
}

PlayerTable.propTypes = {
  persistId: PropTypes.string,
  onPersistLoaded: PropTypes.func,
  fetchMethod: PropTypes.func.isRequired,
  paging: PropTypes.bool,
  onScroll: PropTypes.func,
  onRowClick: PropTypes.func,
  onRowInfoItemClick: PropTypes.func,
  showSelectCol: PropTypes.bool,
  showStatusCol: PropTypes.bool,
  onRowSelect: PropTypes.func,
  onRowDeselect: PropTypes.func,
  selectionDisabled: PropTypes.bool,
  isMobile: PropTypes.bool
};

PlayerTable.defaultProps = {
  persistId: null,
  paging: true,
  onScroll: () => { },
  onPersistLoaded: () => { },
  showSelectCol: false,
  showStatusCol: false,
  onRowClick: null,
  onRowInfoItemClick: null,
  onRowSelect: () => { },
  onRowDeselect: () => { },
  selectionDisabled: false,
};




export default PlayerTable;
