import styled from 'styled-components';
import { Button, FlexboxGrid as Div, Themes } from 'baroo';

const Container = Div.extend`
  display: flex;
  align-items: center;
  max-width: calc(100% - 58px);
  overflow: auto;
  -webkit-overflow-scrolling:touch;
  padding: 12px 0;
  ${Themes.Media.sm`
    max-width: calc(100% - 310px);
  `}
`;

const FilterLabel = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  color: #242c39;
  margin-right: 16px;
  white-space: nowrap;
`;

const FilterButton = styled(Button)`
  margin-right: 16px;
  text-transform: inherit;
  font-weight: normal;
  font-size: 14px;
  > i.icon {
    font-size: 10px !important;
    margin: 0 0 0 8px !important;
  }
`;


export {
  Container,
  FilterLabel,
  FilterButton
};
