import styled from 'styled-components';
import { FlexboxGrid as Div, Themes } from 'baroo';

const FullHeight = styled.div`
  height: 100%;
`;

const Container = FullHeight.extend`
  padding-top: 80px;
  display: flex;
  flex-direction: column;
`;

const Item = Div.extend`
  padding: 0 16px;
  ${Themes.Media.sm`
    padding: 0 32px;
  `}
  height: 75px;
  transition: height 0.3s, opacity 0.3s;
  transform: translateZ(0);
  ${props => props.scrollDirection === 'down' && `
    height: 0;
    opacity: 0;

  `}
`;

const FilterItem = Item.extend``;

export {
  FullHeight,
  Container,
  Item,
  FilterItem,
};
