import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Panel from '../../../../components/Panel';

const PanelStyled = Panel.withComponent(Link).extend`
  height: 100%;
  display: block;
  color: unset;
  &:hover {
    color: unset;
    text-decoration: none;
  }
`;
const CategoryCol = styled.div`
  margin-bottom: 32px;
`;
const Label = styled.div`
  font-weight: bold;
  color: #242c39;
`;
const Description = styled.div`
  color: #7f7f7f;
`;

export {
  PanelStyled,
  CategoryCol,
  Label,
  Description,
};
