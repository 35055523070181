import React, { Component } from 'react';
import moment from 'moment';
import { FlexboxGrid as Div } from 'baroo';
import Panel from '../components/Panel';
import {
  CategoryCol,
  Label,
  Description,
  ApplicantCount,
  HeaderStyled,
} from '../assets/themes/styles/events/EventCardDetail';

const DATE_FORMAT = null;
const DATE_DISPLAY = 'MMM Do, H:mm';
const MAX_AGEGROUP_DISPLAYED = 10;

class EventCardDetail extends Component {
  shouldComponentUpdate(nextProps) {
    return !this.props.event || nextProps.event.id !== this.props.event.id;
  }
  render() {
    const { event, ...etc } = this.props;
    return (
      <Panel {...etc}>
        <Panel.Header>
          <Div row betweenXs middleXs noGutters>
            <HeaderStyled as="h4" content={event.name} />
            <Div dFlexXs>
              <ApplicantCount>{event.applied_count} Applied</ApplicantCount>
              <ApplicantCount>{event.invited_count} Invited</ApplicantCount>
              <ApplicantCount>{event.accepted_count} Accepted</ApplicantCount>
              <ApplicantCount>{event.declined_count} Declined</ApplicantCount>
            </Div>
          </Div>
        </Panel.Header>
        <Panel.Body>
          <Div row>
            <Div colXs={12} colMd={6}>
              <Label>Description</Label>
              <Description>{event.description}</Description>
            </Div>
            <Div colXs={12} colMd={4}>
              <Div row betweenXs noGutters>
                <CategoryCol colXs={12} colSm={6}>
                  <Label>Publication date</Label>
                  <Description>{moment(event.publish_date, DATE_FORMAT).format(DATE_DISPLAY)}</Description>
                </CategoryCol>
                <CategoryCol colXs={12} colSm={6}>
                  <Label>Event date</Label>
                  <Description>{moment(event.event_date, DATE_FORMAT).format(DATE_DISPLAY)}</Description>
                </CategoryCol>
                <CategoryCol colXs={12} colSm={6}>
                  <Label>Application deadline</Label>
                  <Description>{moment(event.application_deadline, DATE_FORMAT).format(DATE_DISPLAY)}</Description>
                </CategoryCol>
                <CategoryCol colXs={12} colSm={6}>
                  <Label>Selection deadline</Label>
                  <Description>{moment(event.selection_deadline, DATE_FORMAT).format(DATE_DISPLAY)}</Description>
                </CategoryCol>
                <CategoryCol colXs={12} colSm={6}>
                  <Label>Location</Label>
                  <Description>{event.location}</Description>
                </CategoryCol>
              </Div>

            </Div>
            <Div colXs={12} colMd={2}>
              <Div row noGutters betweenXs>
                <CategoryCol colXs={12} >
                  <Label>Countries</Label>
                  <Description>{event.audience.countries.map((e) => e.name).join(', ')}</Description>
                </CategoryCol>
                <CategoryCol colXs={12}>
                  <Label>Age groups</Label>
                  <Description>{event.audience.agegroups.filter((e, i) => i < MAX_AGEGROUP_DISPLAYED).map((e) => e.name).join(', ')}{event.audience.agegroups.length > MAX_AGEGROUP_DISPLAYED && '...'}</Description>
                </CategoryCol>
              </Div>
            </Div>
          </Div>
        </Panel.Body>
      </Panel>
    );
  }
}

export default EventCardDetail;
