import { css } from 'styled-components';
import { FlexboxGrid as Div } from 'baroo';

const Table = Div.withComponent('table').extend`
  width: 100%;
  text-align: center;
`;

const Row = Div.withComponent('tr').extend`
  >th,
  >td {
    &:nth-of-type(1),
    &:nth-of-type(2) 
    {
      white-space: nowrap;
      text-align: left;
    }
    &:nth-of-type(3),
    &:nth-of-type(4) {
      text-align: left;
    }
  }
  > td,
  > th {
    padding: 12px 16px;
  }
  > td:nth-of-type(3) {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const RowHeader = Row.extend`
  text-transform: uppercase;  
  height: 44px;
`;

const RowTotal = Row.extend`
  font-weight: bold;

  > td {
    padding-top: 24px;  
    padding-bottom: 8px;
  }
`;

const RowSeasonTotal = Row.extend`
  border-bottom: solid 1px #cdcdcd;
  border-top: solid 1px #cdcdcd;
  > td {
    padding: 16px;  
  }
`;

const RowLeague = Row.extend`
  font-size: 10px;
  > td:nth-of-type(4) {
    text-align: center;
  }
  ${(props) => props.even && css`
      > td {
        background-color: rgb(250, 250, 250);
      }
    
  `}
`;

export {
  Table,
  Row,
  RowHeader,
  RowTotal,
  RowSeasonTotal,
  RowLeague
};
