import React, { PureComponent } from 'react';
import { Step } from 'semantic-ui-react';
import { StyledSteps } from '../../assets/themes/styles/components/Steps/Steps';

class Steps extends PureComponent {

  render() {
    const { steps, activeStep, onStepClick, dark, standalone, ...etc } = this.props;
    return (
      <StyledSteps attached="top" unstackable {...etc} dark={dark.toString()} standalone={standalone}>
        {steps.map((step) =>
          (<Step key={step.id} active={step.id === activeStep} disabled={step.disabled} onClick={() => onStepClick(step.id)}>
            <Step.Content>
              <Step.Title>{step.name}</Step.Title>
            </Step.Content>
          </Step>)
        )}
      </StyledSteps>
    );
  }

}

export default Steps;
