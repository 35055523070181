import React, { Component } from 'react';
import logo from '../../assets/images/noimage-placeholder.png';
import { FlexboxGrid as Div } from 'baroo';

import {
  PlayerRow,
  PlayerRowItem,
  LinkStyled,
  TeamSubstitutionsHeader,
  TeamHeader,
  TeamHeaderLogo,
  TeamHeaderName,
  TeamContainer,
  Team,
  PlayerStyled
} from '../../assets/themes/styles/components/SlideshowMatch/SlideshowMatch';

const playerPopup = true;
class TeamTableWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onPlayerClick = (player) => {
    this.props.onPlayerClick(player)
  }

  buildPlayerLink = (player, key = 0) => {
    if (player !== null) {
      if (player.onTonsser && player.hasPlayerStats) {
        return (
          <LinkStyled key={player.slug} to={`/player/${player.slug}`} target="_blank" onClick={() => this.props.onPlayerClick()}>
            <PlayerStyled clickable={true}>
              <PlayerRowItem flex={'0 0 35px'} textAlign={'left'} fontWeight={'600'}>{player.position}</PlayerRowItem>
              <PlayerRowItem flex={'0 0 20px'} textAlign={'right'} paddingRight={'5px'} >{player.jerseyNumber}</PlayerRowItem>
              <PlayerRowItem flex={'0 0 150px'} textAlign={'left'} >{player.name}</PlayerRowItem>
            </PlayerStyled>
          </LinkStyled>
        )
      }
      return (
        <PlayerRow key={player.slug}>
          <PlayerStyled clickable={false}>
            <PlayerRowItem flex={'0 0 35px'} textAlign={'left'} fontWeight={'600'}>{player.position}</PlayerRowItem>
            <PlayerRowItem flex={'0 0 20px'} textAlign={'right'} paddingRight={'5px'} >{player.jerseyNumber}</PlayerRowItem>
            <PlayerRowItem flex={'0 0 150px'} textAlign={'left'} >{player.name}</PlayerRowItem>
          </PlayerStyled>
        </PlayerRow>
      )
    } else {
      return <PlayerRow key={`no-player-${key}`}>
        <PlayerStyled clickable={false}>
          <PlayerRowItem flex={'0 0 35px'} textAlign={'left'} fontWeight={'600'}></PlayerRowItem>
          <PlayerRowItem flex={'0 0 20px'} textAlign={'right'} paddingRight={'5px'}></PlayerRowItem>
          <PlayerRowItem flex={'0 0 150px'} textAlign={'left'} >-</PlayerRowItem>
        </PlayerStyled>
      </PlayerRow>;
    }

  }
  placeHolders = (count = 0) => {
    const items = [];
    for (let i = 0; i < count; i++) {
      items.push(this.buildPlayerLink(null, i));
    }
    return items;
  }
  render() {
    const { type, data, numberOfPlayers, numberOfSubPlayers } = this.props;
    if (!data) {
      return null;
    }
    const playersCount = numberOfPlayers - data.players.length;
    const subPlayersCount = numberOfSubPlayers - data.subPlayers.length;
    if (type === 'subs') {
      return <TeamContainer>
        <TeamSubstitutionsHeader>Substitutions</TeamSubstitutionsHeader>
        <Div style={{ minHeight: '60px' }}>
          {data.subPlayers.length > 0 && <Team>
            {data.subPlayers.map((player, index) => {
              return (
                this.buildPlayerLink(player)
              )
            })}
          </Team>}
          {this.placeHolders(subPlayersCount)}
        </Div>
      </TeamContainer>
    }
    return (
      <TeamContainer>
        <TeamHeader>
          <TeamHeaderLogo src={data.logo ? data.logo : logo} alt={'team-logo'}></TeamHeaderLogo>
          <TeamHeaderName>{data.name}</TeamHeaderName>
        </TeamHeader>
        <Div style={{ minHeight: '60px' }}>
          {data.players.length > 0 && <Team>
            {data.players.map((player, index) => {
              return (
                this.buildPlayerLink(player)
              )
            })}
          </Team>}
          {this.placeHolders(playersCount)}
        </Div>
      </TeamContainer>
    );
  }
}

export default TeamTableWrapper;
