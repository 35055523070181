import React, { Component } from 'react';
import TeamTableWrapper from './TeamTableWrapper';
import { Grid } from 'semantic-ui-react';

import {
  FlexDiv,
  LineupHeading,
} from '../../assets/themes/styles/components/SlideshowMatch/SlideshowMatch';

class LineUp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onPlayerClick = (player) => {
    this.props.onPlayerClick(player)
  }

  render() {
    const { data } = this.props;
    if (!data) {
      return null;
    }
    const hasHomeTeamPlayers = data.homeTeam.playersCount > 0 || data.homeTeam.subPlayersCount > 0;
    const hasAwayTeamPlayers = data.awayTeam.playersCount > 0 || data.awayTeam.subPlayersCount > 0;

    let customPlayersHeight = 0;
    let customSubPlayersHeight = 0;
    let numberOfPlayers = 0;
    let numberOfSubPlayers = 0;

    if (data.homeTeam.playersCount >= data.awayTeam.playersCount) {
      customPlayersHeight = 18 * data.homeTeam.playersCount;
      numberOfPlayers = data.homeTeam.playersCount;
    } else {
      customPlayersHeight = 18 * data.awayTeam.playersCount;
      numberOfPlayers = data.awayTeam.playersCount;
    }

    if (data.homeTeam.subPlayersCount >= data.awayTeam.subPlayersCount) {
      customSubPlayersHeight = 18 * data.homeTeam.subPlayersCount;
      numberOfSubPlayers = data.homeTeam.subPlayersCount;
    } else {
      customSubPlayersHeight = 18 * data.awayTeam.subPlayersCount;
      numberOfSubPlayers = data.awayTeam.subPlayersCount;
    }
    return <Grid stackable>
      <Grid.Row columns={1}>
        <Grid.Column width={16}>
          <LineupHeading>Lineup</LineupHeading>
        </Grid.Column>
      </Grid.Row>
      {numberOfSubPlayers === 0 && numberOfPlayers === 0 && <Grid.Row columns={1}>
        <Grid.Column width={16}>
          <div style={{ color: '#ffffff', textAlign: 'left' }}>No players added to the lineup.</div>
        </Grid.Column>
      </Grid.Row>}
      {numberOfPlayers > 0 && <Grid.Row columns={2}>
        {data.hasData && hasHomeTeamPlayers &&
          <Grid.Column width={8}>
            <TeamTableWrapper
              type={'players'}
              data={data.homeTeam}
              onPlayerClick={this.onPlayerClick}
              numberOfPlayers={numberOfPlayers}
            />
          </Grid.Column>
        }
        {data.hasData && hasAwayTeamPlayers > 0 &&
          <Grid.Column width={8}>
            <TeamTableWrapper
              type={'players'}
              data={data.awayTeam}
              onPlayerClick={this.onPlayerClick}
              numberOfPlayers={numberOfPlayers}
            />
          </Grid.Column>
        }
      </Grid.Row>}
      {numberOfSubPlayers > 0 && <Grid.Row columns={2}>
        {data.hasData && hasHomeTeamPlayers &&
          <Grid.Column width={8}>
            <TeamTableWrapper
              type={'subs'}
              data={data.homeTeam}
              onPlayerClick={this.onPlayerClick}
              numberOfSubPlayers={numberOfSubPlayers}
            />
          </Grid.Column>
        }
        {data.hasData && hasAwayTeamPlayers > 0 &&
          <Grid.Column width={8}>
            <TeamTableWrapper
              type={'subs'}
              data={data.awayTeam}
              onPlayerClick={this.onPlayerClick}
              numberOfSubPlayers={numberOfSubPlayers}
            />
          </Grid.Column>
        }
      </Grid.Row>}
    </Grid>;
  }
}

export default LineUp;
