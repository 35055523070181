import styled from 'styled-components';
import { FlexboxGrid as Div, Themes, List } from 'baroo';
import { Image, Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const HeaderContainer = Div.extend`
  position: absolute;
  right: 0;
  left: 0;
  top:0;
  z-index: 1000;
  padding: 24px 16px;
  ${Themes.Media.sm`
    padding: 24px 32px;
  `}
  background-color: #000;
  color: white;
  height: 80px;
  a {
    color: black;
    &:hover {
      text-decoration: none;
    }
  }
  > div {
    > div {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
`;
const Logo = styled.img`
  height: 30px;
  ${Themes.Media.sm`
    height: 32px;
  `}
`;

const ListStyled = List.extend`
  background: #000;
`;

const ListItemStyled = List.Item.extend`
  padding: 0;
  &:hover {
    opacity: 0.7;
  }
`;
const ListHeaderStyled = List.Header.extend`
  color: white;
  font-size: 12px;
  font-weight: normal;
  line-height: 17px;
  height: 17px;
`;

const ListDescriptionStyled = ListHeaderStyled.extend`
  color: white;
  &:hover {
    color: white;
  }
`;

const ImageStyled = styled(Image)`
  &.ui.avatar.image {
    width: 28px;
    height: 28px;
    margin-right: .75em;
  }
`;
const SubheaderPopup = styled(Popup)`
  &.ui.popup{
    background: black;
    border: none;
    left: 0 !important;
    right: 0 !important;
    height: 116px;
    margin: 0;
    border-radius: 0;
    border-top: solid 1px #161817;
    display: flex !important;
    align-items: center;
    padding: 0 32px;
    &::before {
      display: none;
    }
  }
`;

const Subheader = Div.extend`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

Subheader.Item = styled(Link)`
  color: #fff;
  flex-grow: 1;
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: none;
    color: #fff;
    opacity: 0.7;
  }
`;
Subheader.Icon = Div.extend`
  > img {width: 50px;}
  margin-right: 32px;
`;
Subheader.Content = Div.extend``;
Subheader.Header = Div.extend`
  font-size: 20px;
  font-weight: bold;
`;
Subheader.Description = Div.extend`
  font-size: 14px;
  margin-top: 10px;
`;
Subheader.Divider = Div.extend`
  height: 108px;
  width: 1px;
  background: #171918;
  margin: 0 32px;
`;


const MenuLink = styled.div`
  margin-right: 32px;
  &:first-of-type {
    margin-left: 34px;
  }
  a {
    color: #fff;
    cursor: pointer;
    font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
    &:hover {
      opacity: 0.7;
    }
  }
  div {
    cursor: pointer;
  }
`;

const DivGrow = Div.extend`
  flex-grow: 1;
`;

const Players = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export {
  HeaderContainer,
  Logo,
  ListStyled,
  ListItemStyled,
  ListHeaderStyled,
  ListDescriptionStyled,
  ImageStyled,
  SubheaderPopup,
  Subheader,
  MenuLink,
  DivGrow,
  Players,
};