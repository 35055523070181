import { css } from 'styled-components';
import { FlexboxGrid as Div } from 'baroo';

const Category = Div.extend`
`;

const CategoryName = Div.extend`
  color: black;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
`;

const Label = Div.extend`
  color: black;
  font-weight: bold;
`;

const Content = Div.extend`
  color: #555555;
`;

const DataBox = Div.extend`
  margin-bottom: 24px
`;

const BoxDiv = Div.extend`
  ${(props) => props.standalone && css`
    margin-bottom: 15px
  `}
`;

const ExpectedIcon = Div.extend`
  display: inline-block;
  margin-right: -5px;
`;
const ExpectedLabel = Div.extend`
  display: inline-block;
`;

export {
  Category,
  CategoryName,
  Label,
  Content,
  DataBox,
  BoxDiv,
  ExpectedIcon,
  ExpectedLabel
};